import { Component, Inject } from '@angular/core';
import {
  ClientConfigWithTemplate,
  ExtendedModelData,
  TemplateConfig,
  UpdateClientForm,
  UpdateClientRequest,
  UpdateClientResponse,
} from '@types';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientConfigService } from '@shared/client-config.service';
import { AiService } from '@shared';

@Component({
  selector: 'app-update-client-dialog',
  templateUrl: './update-client-dialog.component.html',
  styleUrls: ['./update-client-dialog.component.scss'],
})
export class UpdateClientDialogComponent {
  form: FormGroup<UpdateClientForm>;
  isLoading: boolean = false;
  models: ExtendedModelData[] = [];
  templates: TemplateConfig[] = [];

  constructor(
    private dialogRef: MatDialogRef<UpdateClientDialogComponent, UpdateClientResponse | null>,
    private snackBar: MatSnackBar,
    private clientConfigService: ClientConfigService,
    private aiService: AiService,
    @Inject(MAT_DIALOG_DATA) public data: { clientConfig: ClientConfigWithTemplate; templates: TemplateConfig[] }
  ) {
    this.form = new FormGroup<UpdateClientForm>({
      openAiModelId: new FormControl(data.clientConfig.openAiModelId, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      name: new FormControl(data.clientConfig.name, { nonNullable: true, validators: [Validators.required] }),
      description: new FormControl(data.clientConfig.description, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      template: new FormControl(data.clientConfig.template),
    });
    this.aiService.getOpenAiModels({}).subscribe((models) => (this.models = models));
    this.templates = data.templates;
  }

  submitForm() {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
    const updateClientRequest: UpdateClientRequest = {
      PK: this.data.clientConfig.PK,
      SK: this.data.clientConfig.SK,
      apiKeyId: this.data.clientConfig.apiKeyId,
      openAiModelId: this.form.controls.openAiModelId.value,
      name: this.form.controls.name.value,
      description: this.form.controls.description.value,
    };
    if (this.form.controls.template.value?.SK) {
      updateClientRequest.templateSK = this.form.controls.template.value?.SK;
    }
    this.clientConfigService
      .updateClientApiConfig(updateClientRequest)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((result) => this.handleUpdateClientResponse(result));
  }

  private handleUpdateClientResponse(response: UpdateClientResponse) {
    this.snackBar.open('The API key configuration has been successfully update.', 'Close', { duration: 3000 });
    this.dialogRef.close(response);
  }

  closeDialog() {
    this.dialogRef.close(null);
  }

  compareFn(c1: string, c2: string): boolean {
    return c1 && c2 ? c1 === c2 : false;
  }

  compareTemplateFn(c1: TemplateConfig, c2: TemplateConfig): boolean {
    return c1 && c2 ? c1.SK === c2.SK : c1 === c2;
  }
}
