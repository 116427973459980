<span *ngIf="templates?.length === 0"> No template has been created yet </span>
<mat-list class="templates">
  <ng-container *ngFor="let template of templates">
    <div class="template">
      <div matListItemTitle>{{ template.name }} {{ template.isDefault ? '(default)' : '' }}</div>
      <div class="template-actions">
        <button mat-raised-button color="primary" type="button" (click)="updateTemplate(template)">Edit</button>
        <button mat-raised-button color="warn" type="button" (click)="deleteTemplate(template)">Delete</button>
      </div>
    </div>
  </ng-container>
</mat-list>
