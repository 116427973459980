import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import {
  CancelFineTuneRequest,
  CancelFineTuneResponse,
  CreateFineTunedModelWithDatasetRequest,
  CreateFineTunedModelWithDatasetResponse,
  CreateFineTunedModelWithFileRequest,
  CreateFineTunedModelWithFileResponse,
  DeleteFineTuneRequest,
  DeleteFineTuneResponse,
  GetFineTuneDetailsRequest,
  GetFineTuneListRequest,
  GetFineTuneListResponse,
} from '@types';
import { BaseProjectService } from '@shared/base-project.service';
import { ProjectService } from '@shared/project.service';

@Injectable({
  providedIn: 'root',
})
export class FineTuneService extends BaseProjectService {
  constructor(protected http: HttpClient, protected override projectService: ProjectService) {
    super(projectService);
  }

  getFineTuneList(request: GetFineTuneListRequest): Observable<GetFineTuneListResponse> {
    return this.http.post<GetFineTuneListResponse>(`${environment.serverUrl}/fine-tune/list`, {
      project: this.project,
      ...request,
    });
  }

  deleteFineTunedModel(request: DeleteFineTuneRequest): Observable<DeleteFineTuneResponse> {
    return this.http.post<DeleteFineTuneResponse>(`${environment.serverUrl}/models/delete`, {
      project: this.project,
      ...request,
    });
  }

  cancelFineTuneModel(request: CancelFineTuneRequest): Observable<CancelFineTuneResponse> {
    return this.http.post<CancelFineTuneResponse>(`${environment.serverUrl}/fine-tune/cancel`, {
      project: this.project,
      ...request,
    });
  }

  createFineTunedModelWithFile(
    request: CreateFineTunedModelWithFileRequest
  ): Observable<CreateFineTunedModelWithFileResponse> {
    return this.http.post<CreateFineTunedModelWithFileResponse>(`${environment.serverUrl}/models/create-with-file`, {
      project: this.project,
      ...request,
    });
  }

  createFineTunedModelWithDataset(
    request: CreateFineTunedModelWithDatasetRequest
  ): Observable<CreateFineTunedModelWithDatasetResponse> {
    return this.http.post<CreateFineTunedModelWithDatasetResponse>(
      `${environment.serverUrl}/models/create-with-dataset`,
      {
        project: this.project,
        ...request,
      }
    );
  }

  /**
   * TODO Add missing type. The typescript types of the openAI api should be obtained from somewhere
   */
  getFineTuneDetails(request: GetFineTuneDetailsRequest): Observable<any> {
    return this.http.post<any>(`${environment.serverUrl}/fine-tune/get`, {
      project: this.project,
      ...request,
    });
  }
}
