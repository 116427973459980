import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ExtendedModelData, Model, ModelFormGroup } from '@types';
import { LocalStorageService } from '@shared/storage.service';
import { defaultModel } from '@const';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-model-selector',
  templateUrl: './model-selector.component.html',
  styleUrls: ['./model-selector.component.scss'],
})
export class ModelSelectorComponent implements OnInit {
  @Input() formGroup?: FormGroup<ModelFormGroup>;
  @Input() models: ExtendedModelData[] = [];
  @Input() name: string = '';
  isSettingOpen: boolean = false;
  constructor(private localstorageService: LocalStorageService<Partial<Model>>, private _snackBar: MatSnackBar) {}

  ngOnInit(): void {
    if (this.formGroup?.controls.model) {
      this.loadParams(this.formGroup?.controls.model.value);
    }
    this.formGroup?.controls.model.valueChanges.subscribe((value) => this.loadParams(value));
  }

  saveModel() {
    if (this.formGroup?.value) {
      this.localstorageService.setItem(this.name + '_' + this.formGroup?.value.model?.id, this.formGroup.value);
      this._snackBar.open('Settings saved successfully!', 'Close', { duration: 3000 });
    }
  }

  resetModel() {
    this.setParams(defaultModel);
  }

  compareFn(c1: ExtendedModelData, c2: ExtendedModelData): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  private loadParams(value: Partial<ExtendedModelData>) {
    if (this.formGroup) {
      const model = this.localstorageService.getItem(this.name + '_' + value.id);
      this.setParams(model || defaultModel);
    }
  }

  private setParams(model: Partial<Model>) {
    if (this.formGroup) {
      this.formGroup.controls.n.patchValue(model?.n || defaultModel.n, { emitEvent: false });
      this.formGroup.controls.top_p.patchValue(model?.top_p || defaultModel.top_p, { emitEvent: false });
      this.formGroup.controls.temperature.patchValue(model?.temperature || defaultModel.temperature, {
        emitEvent: false,
      });
      this.formGroup.controls.max_tokens.patchValue(model?.max_tokens || defaultModel.max_tokens, { emitEvent: false });
    }
  }
}
