import { Component, OnInit, ViewChild } from '@angular/core';
import { FineTune, FineTuneTableItem, ModelGroup } from '@types';
import { MatDrawer } from '@angular/material/sidenav';
import { BreakpointService } from '@shared';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FineTuneService } from '@shared/fine-tune.service';

@Component({
  selector: 'app-fine-tune',
  templateUrl: './fine-tune.component.html',
  styleUrls: ['./fine-tune.component.scss', '../../theme/sidenav.scss'],
})
export class FineTuneComponent implements OnInit {
  @ViewChild('drawerRight') public rightSidenav: MatDrawer | undefined;
  isSmallScreen: boolean = false;
  protected readonly ModelGroup = ModelGroup;
  selectedRow?: FineTune;
  selectedRowId: string = '';
  constructor(
    private breakpointService: BreakpointService,
    private fineTuneService: FineTuneService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.breakpointService.breakpoint$.subscribe((breakpoint) => {
      this.isSmallScreen = breakpoint.Small || breakpoint.XSmall;
    });
  }

  closeRightSidenav() {
    this.selectedRowId = '';
    this.rightSidenav?.close();
  }

  handleTableRowSelect(item: FineTune) {
    this.selectedRowId = item.id;
    this.fineTuneService
      .getFineTuneDetails({
        fineTuneId: item.id,
      })
      .subscribe((result) => {
        this.selectedRow = result;
      });
    this.rightSidenav?.open();
  }
}
