<h2 mat-dialog-title>Start a new fine-tune</h2>
<form [formGroup]="form" class="dataset-form" (ngSubmit)="submitForm()">
  <mat-dialog-content class="mat-typography">
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>Select dataset</mat-label>
      <mat-select [formControl]="form.controls.DatasetPK">
        <mat-option *ngFor="let dataset of datasetService.$items | async; trackBy: identify" [value]="dataset.SK">
          {{ dataset.name }} ({{ dataset.model }})
        </mat-option>
      </mat-select>
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
      <mat-error *ngIf="form.controls.DatasetPK.hasError('required')">Required!</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>Select model to fine-tune</mat-label>
      <mat-select [formControl]="form.controls.model">
        <mat-option [value]="'davinci'">Davinci</mat-option>
        <mat-option [value]="'curie'">Curie</mat-option>
        <mat-option [value]="'ada'">Ada</mat-option>
        <mat-option [value]="'babbage'">Babbage</mat-option>
        <mat-option *ngFor="let model of fineTunableModels" [value]="model.id">
          {{ model?.id }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
      <mat-error *ngIf="form.controls.model.hasError('required')">Required!</mat-error>
    </mat-form-field>
    <mat-form-field
      appearance="fill"
      class="mat-input-and-select"
      hintLabel="A string of up to 40 characters that will be added to your fine-tuned model name."
    >
      <mat-label>Suffix</mat-label>
      <input matInput #suffixInput [formControl]="form.controls.suffix" />
      <mat-hint align="end">{{ suffixInput.value.length }}/40</mat-hint>
      <mat-error *ngIf="form.controls.suffix.hasError('maxlength')"
        >The suffix can only be 40 characters long!
      </mat-error>
    </mat-form-field>
    <mat-checkbox [color]="'primary'" [formControl]="form.controls.isHyperparametersEnabled">
      Enable Hyperparameters
    </mat-checkbox>
    <app-parameter-slider name="Number of epochs" [control]="form.controls.n_epochs" [min]="1" [max]="16" [step]="1" />
    <app-parameter-slider name="Batch size" [control]="form.controls.batch_size" [min]="1" [max]="16" [step]="1" />
    <app-parameter-slider
      name="Learning rate multiplier"
      [control]="form.controls.learning_rate_multiplier"
      [min]="0.02"
      [max]="0.2"
      [step]="0.01"
    />
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-raised-button type="button" (click)="closeDialog()" [disabled]="isCreateFineTuneInProgress">
      Cancel
    </button>
    <button mat-button mat-raised-button type="submit" [disabled]="isCreateFineTuneInProgress">
      Start
      <mat-icon *ngIf="isCreateFineTuneInProgress" class="spin" iconPositionEnd>sync</mat-icon>
    </button>
  </mat-dialog-actions>
</form>
