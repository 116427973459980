<mat-drawer-container autosize [hasBackdrop]="isSmallScreen">
  <mat-drawer
    #drawerLeft
    [opened]="!isSmallScreen"
    [ngClass]="{ sidenav: true, smallScreen: isSmallScreen, 'dataset-sidenav': true }"
    [mode]="isSmallScreen ? 'over' : 'side'"
  >
    <div class="left-sidenav dark-theme">
      <div class="left-sidenav-title-container mat-typography">
        <button class="left-sidenav-title" mat-button type="button" (click)="openNewDatasetDialog()">
          <mat-icon class="icon">add</mat-icon>
          New dataset
        </button>
      </div>
      <mat-divider class="divider"></mat-divider>
      <app-dataset-sidebar-list
        (selectEvent)="handleDatasetItemSelect($event)"
        [selectedItem]="selectedDataset"
        [isSmallScreen]="isSmallScreen"
      />
    </div>
  </mat-drawer>
  <mat-drawer
    #drawerRight
    [ngClass]="{ sidenav: true, smallScreen: isSmallScreen, 'dataset-item-sidenav': true }"
    [mode]="'over'"
    position="end"
  >
    <div class="right-sidenav-title-container">
      <button mat-icon-button (click)="closeRightSidenav()" type="button" class="right-side-toggle-btn" color="accent">
        <mat-icon class="material-symbols-outlined" fontSet="material-icons-outlined"> arrow_circle_right</mat-icon>
      </button>
      <h3 class="right-sidenav-title">Request / Response Information</h3>
    </div>
    <div class="right-sidenav-body-container">
      <app-text-to-sql-request-info *ngIf="selectedRow?.model === ModelGroup.TEXT_TO_SQL" [data]="selectedRow?.item" />
      <app-sql-to-text-request-info *ngIf="selectedRow?.model === ModelGroup.SQL_TO_TEXT" [data]="selectedRow?.item" />
    </div>
  </mat-drawer>
  <div class="container" [ngClass]="{ 'small-container': isSmallScreen }">
    <div *ngIf="isSmallScreen" class="left-side-toggle-btn">
      <mat-icon class="icon" (click)="openLeftSidenav()">list</mat-icon>
    </div>
    <app-dataset-list
      [selectedDataset]="selectedDataset"
      [selectedRow]="selectedRow"
      (rowSelectEvent)="handleTableRowSelect($event)"
    ></app-dataset-list>
  </div>
</mat-drawer-container>
