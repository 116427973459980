import { Component, OnInit, ViewChild } from '@angular/core';
import { HistoryItem, ModelGroup, ReviewStatus, UpdateHistoryStatusRequest } from '@types';
import { MatDrawer } from '@angular/material/sidenav';
import { BreakpointService } from '@shared';
import { HistoryService } from '@shared/history.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReviewListComponent } from '@app/review/review-list/review-list.component';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss', '../../theme/sidenav.scss'],
})
export class ReviewComponent implements OnInit {
  @ViewChild('drawerRight') public rightSidenav: MatDrawer | undefined;
  @ViewChild('reviewList') public reviewList: ReviewListComponent | undefined;
  isSmallScreen: boolean = false;
  protected readonly ModelGroup = ModelGroup;
  selectedRow?: HistoryItem;

  constructor(
    private breakpointService: BreakpointService,
    private historyService: HistoryService,
    private _snackBar: MatSnackBar
  ) {
    this.textToSQLReviewStatus = new FormControl<ReviewStatus>(ReviewStatus.PENDING, { nonNullable: true });
    this.answerReviewStatus = new FormControl<ReviewStatus>(ReviewStatus.PENDING, { nonNullable: true });
  }

  ngOnInit() {
    this.breakpointService.breakpoint$.subscribe((breakpoint) => {
      this.isSmallScreen = breakpoint.Small || breakpoint.XSmall;
    });
    this.textToSQLReviewStatus.valueChanges.subscribe((status) => {
      this.changeStatusOfGeneratedSql(status);
    });
    this.answerReviewStatus.valueChanges.subscribe((status) => {
      this.changeStatusOfGeneratedAnswer(status);
    });
    this.historyService.$items.subscribe((items) => {
      if (this.selectedRow) {
        this.selectedRow = items.find((item) => item.SK === this.selectedRow?.SK);
      }
    });
  }

  closeRightSidenav() {
    this.rightSidenav?.close();
    this.selectedRow = undefined;
  }

  handleTableRowSelect(item: HistoryItem) {
    this.textToSQLReviewStatus.setValue(this.getReviewStatus(item.textToSqlStatus), { emitEvent: false });
    this.answerReviewStatus.setValue(this.getReviewStatus(item.sqlToTextStatus), { emitEvent: false });
    this.selectedRow = item;
    this.rightSidenav?.open();
  }

  protected readonly ReviewStatus = ReviewStatus;
  textToSQLReviewStatus: FormControl<ReviewStatus>;
  answerReviewStatus: FormControl<ReviewStatus>;

  /**
   * Used to map empty string to PENDING status
   * @param status
   * @private
   */
  private getReviewStatus(status: ReviewStatus | undefined): ReviewStatus {
    if (status) return _.isEmpty(status) ? ReviewStatus.PENDING : status;
    else return ReviewStatus.PENDING;
  }

  changeStatusOfGeneratedSql(newStatus: ReviewStatus) {
    if (this.selectedRow) {
      const request: UpdateHistoryStatusRequest = {
        SK: this.selectedRow.SK,
        PK: this.selectedRow.PK,
        textToSqlStatus: this.getReviewStatus(newStatus),
        sqlToTextStatus: this.selectedRow.sqlToTextStatus,
      };
      this.historyService.updateItemStatus(request).subscribe(() => {
        this._snackBar.open('The status of the answer has been successfully approved!', 'Close', { duration: 3000 });
        this.reviewList?.updateRowStatus(request);
      });
    }
  }

  changeStatusOfGeneratedAnswer(newStatus: ReviewStatus) {
    if (this.selectedRow) {
      const request: UpdateHistoryStatusRequest = {
        SK: this.selectedRow.SK,
        PK: this.selectedRow.PK,
        textToSqlStatus: this.selectedRow.textToSqlStatus,
        sqlToTextStatus: this.getReviewStatus(newStatus),
      };
      this.historyService.updateItemStatus(request).subscribe(() => {
        this._snackBar.open('The status of the answer has been successfully approved!', 'Close', { duration: 3000 });
        this.reviewList?.updateRowStatus(request);
      });
    }
  }
}
