import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import {
  CreateHistoryItemRequest,
  CreateHistoryItemResponse,
  DeleteHistoryItemsRequest,
  DeleteHistoryItemsResponse,
  HistoryItem,
  UpdateHistoryItemResponseRequest,
  UpdateHistoryItemResponseResponse,
  UpdateHistoryStatusRequest,
  UpdateHistoryStatusResponse,
} from '@types';
import { ProjectPaginatorService } from '@shared/project-paginator.service';
import { ProjectService } from '@shared/project.service';

@Injectable({
  providedIn: 'root',
})
export class HistoryService extends ProjectPaginatorService<HistoryItem> {
  constructor(protected override http: HttpClient, protected override projectService: ProjectService) {
    super(http, projectService, 19, 'PROJECTHISTORY#${projectName}');
  }

  updateItemStatus(requestBody: UpdateHistoryStatusRequest): Observable<UpdateHistoryStatusResponse> {
    return this.http.post<UpdateHistoryStatusResponse>(`${environment.serverUrl}/history/update-status`, {
      PK: this.PK,
      project: this.project,
      ...requestBody,
    });
  }

  createItem(requestBody: CreateHistoryItemRequest): Observable<CreateHistoryItemResponse> {
    return this.http.post<CreateHistoryItemResponse>(`${environment.serverUrl}/history/create`, {
      project: this.project,
      ...requestBody,
    });
  }

  delete(requestBody: DeleteHistoryItemsRequest): Observable<DeleteHistoryItemsResponse> {
    return this.http.post<DeleteHistoryItemsResponse>(`${environment.serverUrl}/history/delete`, {
      project: this.project,
      ...requestBody,
    });
  }

  updateHistoryItemResponse(
    requestBody: UpdateHistoryItemResponseRequest
  ): Observable<UpdateHistoryItemResponseResponse> {
    return this.http.post<UpdateHistoryItemResponseResponse>(`${environment.serverUrl}/history/update-response`, {
      project: this.project,
      ...requestBody,
    });
  }
}
