import { Component, Input } from '@angular/core';
import { Model } from '@types';

@Component({
  selector: 'app-prompt-execution-info-table',
  templateUrl: './prompt-execution-info-table.component.html',
  styleUrls: ['./prompt-execution-info-table.component.scss'],
})
export class PromptExecutionInfoTableComponent {
  @Input() model?: Partial<Model> | undefined;
  @Input() modelExecutionDate?: string;

  constructor() {}
}
