import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientConfigWithTemplate } from '@types';
import { environment } from '@env/environment';

@Component({
  selector: ' app-client-config',
  templateUrl: './client-app-config.component.html',
  styleUrls: ['./client-app-config.component.scss'],
})
export class ClientAppConfigComponent {
  @Input() clients?: ClientConfigWithTemplate[];
  @Output() deleteApiConfigEvent: EventEmitter<ClientConfigWithTemplate> = new EventEmitter<ClientConfigWithTemplate>();
  @Output() updateApiConfigEvent: EventEmitter<ClientConfigWithTemplate> = new EventEmitter<ClientConfigWithTemplate>();
  clientApiUrl: string = environment.clientApiUrl;

  constructor() {}

  deleteClient(clientConfig: ClientConfigWithTemplate) {
    this.deleteApiConfigEvent.emit(clientConfig);
  }

  updateClient(clientConfig: ClientConfigWithTemplate) {
    this.updateApiConfigEvent.emit(clientConfig);
  }
}
