import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExecutedQueryDialogData } from '@types';

@Component({
  selector: 'app-executed-query-dialog',
  templateUrl: './executed-query-dialog.component.html',
  styleUrls: ['./executed-query-dialog.component.scss'],
})
export class ExecutedQueryDialogComponent {
  data: ExecutedQueryDialogData;
  constructor(@Inject(MAT_DIALOG_DATA) data: ExecutedQueryDialogData) {
    this.data = data;
  }
}
