import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, mergeMap, Observable } from 'rxjs';
import { Auth } from 'aws-amplify';
import { Logger } from '../logger.service';

const log = new Logger('AuthInterceptor');

/**
 * Add Authorization header to requests.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.addToken(request)).pipe(mergeMap((tokenizedRequest) => next.handle(tokenizedRequest)));
  }

  private async addToken(request: HttpRequest<any>): Promise<HttpRequest<any>> {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();

      return request.clone({
        setHeaders: {
          Authorization: `${token}`,
        },
      });
    } catch (error) {
      log.error('Error adding access token to request:', error);
      return request;
    }
  }
}
