import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import {
  CreateHistoryItemResponse,
  DeleteItem,
  HistoryItem,
  HistoryItemType,
  ModelGroup,
  ReviewFilter,
  ReviewFilterForm,
  ReviewStatus,
  ReviewTableItem,
  UpdateHistoryStatusRequest,
} from '@types';
import { Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HistoryService } from '@shared/history.service';
import { BreakpointService } from '@shared';
import { Breakpoint } from '../../../classes/breakpoint.class';
import { FormControl, FormGroup } from '@angular/forms';
import { endOfDay, isAfter, isBefore, isWithinInterval, startOfDay } from 'date-fns';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateReviewItemDialogComponent } from '../create-review-item-dialog/create-review-item-dialog.component';

@Component({
  selector: 'app-review-list',
  templateUrl: './review-list.component.html',
  styleUrls: ['./review-list.component.scss', '../../../theme/chip.scss', '../../../theme/table.scss'],
})
export class ReviewListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @Input() activeRow: HistoryItem | undefined;
  @Output() rowSelectEvent: EventEmitter<HistoryItem> = new EventEmitter<HistoryItem>();
  rows: MatTableDataSource<ReviewTableItem> = new MatTableDataSource<ReviewTableItem>([]);
  isSmallScreen: boolean = false;
  smallScreenColumns: Array<keyof ReviewTableItem> = [
    'select',
    'request',
    'generatedSqlStatus',
    'generatedAnswerStatus',
  ];
  mediumScreenColumns: Array<keyof ReviewTableItem> = [
    'select',
    'requestDateTime',
    'generatedSqlStatus',
    'generatedAnswerStatus',
    'request',
    'response',
  ];
  bigScreenColumns: Array<keyof ReviewTableItem> = [
    'select',
    'requestDateTime',
    'generatedSqlStatus',
    'generatedAnswerStatus',
    'request',
    'response',
  ];
  columns: Array<keyof ReviewTableItem> = this.bigScreenColumns;
  selection = new SelectionModel<ReviewTableItem>(true, []);

  filterFormGroup = new FormGroup<ReviewFilterForm>({
    text: new FormControl<string | null>(null),
    generatedSqlStatus: new FormControl(),
    generatedAnswerStatus: new FormControl(null),
    promptCreatedDateRange: new FormGroup({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null),
    }),
    type: new FormControl<HistoryItemType | null>(null),
    executableSql: new FormControl<boolean | null>(null),
  });

  protected readonly ModelGroup = ModelGroup;
  protected readonly ReviewStatus = ReviewStatus;
  isDeletionInProgress: boolean = false;
  dialogRef?: MatDialogRef<CreateReviewItemDialogComponent>;

  constructor(
    private dialog: MatDialog,
    private historyService: HistoryService,
    private breakpointService: BreakpointService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getTableData();
    this.breakpointService.breakpoint$.subscribe((breakpoint) => this.handleBreakpointChange(breakpoint));
    this.filterFormGroup.valueChanges.subscribe((filterValues) => {
      this.rows.filter = JSON.stringify(filterValues);
    });
    this.rows.filterPredicate = (record: ReviewTableItem, filter: string) => this.filterRows(filter, record);
    this.historyService.$items.subscribe((response: HistoryItem[]) => this.handleGetPageResponse(response));
  }

  getTableData() {
    this.historyService
      .getPage({
        pageSize: 10000,
        PK: this.historyService.PK,
      })
      .subscribe();
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.rows.paginator = this.paginator;
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.rows.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.rows.filteredData);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: ReviewTableItem): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.requestDateTime + 1}`;
  }

  sortData(sort: Sort): void {
    const data: ReviewTableItem[] = this.rows.data.slice();
    if (!sort.active || sort.direction === '') {
      this.rows.data = data;
      return;
    }

    this.rows.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(a[sort.active], b[sort.active], isAsc);
    });
  }

  compare(a: number | string | boolean, b: number | string | boolean, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  handleRowClick(row: ReviewTableItem) {
    this.rowSelectEvent.emit(row.data);
  }

  deleteItems(items: ReviewTableItem[]) {
    this.isDeletionInProgress = true;
    this.historyService
      .delete({
        items: items.map((item) => {
          return {
            PK: item.data.PK,
            SK: item.data.SK,
          };
        }),
      })
      .pipe(finalize(() => (this.isDeletionInProgress = false)))
      .subscribe((result) => this.handleDeleteItemsResponse(result.deletedItems));
  }

  updateRowStatus(updateRequest: UpdateHistoryStatusRequest): void {
    const index: number = this.rows.data.findIndex((row) => row.data.SK === updateRequest.SK);
    if (this.rows.data[index]?.data && updateRequest.textToSqlStatus) {
      this.rows.data[index].data.textToSqlStatus = updateRequest.textToSqlStatus;
      this.rows.data[index].generatedSqlStatus = updateRequest.textToSqlStatus;
    }
    if (this.rows.data[index]?.data && updateRequest.sqlToTextStatus) {
      this.rows.data[index].data.sqlToTextStatus = updateRequest.sqlToTextStatus;
      this.rows.data[index].generatedAnswerStatus = updateRequest.sqlToTextStatus;
    }
  }

  private handleDeleteItemsResponse(items: DeleteItem[]) {
    const deleteKeys: string[] = items.map((item) => item.SK);
    this.rows.data = this.rows.data.filter((row) => !deleteKeys.includes(row.data.SK));
    this._snackBar.open('Items deleted successfully!', 'Close', { duration: 3000 });
    this.selection.clear();
  }

  private filterRows(filter: string, record: ReviewTableItem): boolean {
    const filters: ReviewFilter = JSON.parse(filter);
    const filterResults: boolean[] = [];
    if (filters.text?.length) {
      filterResults.push(JSON.stringify(record.data).search(filters.text) !== -1);
    }
    if (filters.generatedAnswerStatus?.length) {
      filterResults.push(record.generatedAnswerStatus === filters.generatedAnswerStatus);
    }
    if (filters.generatedSqlStatus?.length) {
      filterResults.push(record.generatedSqlStatus === filters.generatedSqlStatus);
    }
    if (filters.type?.length && filters.type === HistoryItemType.MANUAL) {
      filterResults.push(record.data.type === filters.type);
    }
    if (filters.type?.length && filters.type === HistoryItemType.PROMPT) {
      filterResults.push(record.data.type !== HistoryItemType.MANUAL);
    }
    if (filters.promptCreatedDateRange?.start && filters.promptCreatedDateRange?.end) {
      try {
        filterResults.push(
          isWithinInterval(new Date(record.requestDateTime), {
            start: startOfDay(new Date(filters.promptCreatedDateRange.start)),
            end: endOfDay(new Date(filters.promptCreatedDateRange.end)),
          })
        );
      } catch (error) {
        filterResults.push(false);
      }
    }
    if (filters.promptCreatedDateRange?.start && !filters.promptCreatedDateRange?.end) {
      try {
        filterResults.push(isAfter(new Date(record.requestDateTime), new Date(filters.promptCreatedDateRange?.start)));
      } catch (error) {
        filterResults.push(false);
      }
    }
    if (filters.promptCreatedDateRange?.end && !filters.promptCreatedDateRange?.start) {
      try {
        filterResults.push(isBefore(new Date(record.requestDateTime), new Date(filters.promptCreatedDateRange?.end)));
      } catch (error) {
        filterResults.push(false);
      }
    }
    if (filters.executableSql === true) {
      filterResults.push(record.executableSql);
    }

    if (filters.executableSql === false) {
      filterResults.push(!record.executableSql);
    }

    if (filterResults.length) {
      return filterResults.every((result) => result);
    }
    return true;
  }

  private handleGetPageResponse(items: HistoryItem[]): void {
    this.rows.data = items.map((item) => this.createTableItem(item));
    this.selection = new SelectionModel<ReviewTableItem>(
      true,
      this.rows.data.filter((item) => item.select)
    );
  }

  private createTableItem(item: HistoryItem): ReviewTableItem {
    return {
      select: false,
      requestDateTime: item.dateTime,
      request: this.getRequest(item),
      response: this.getResponse(item),
      generatedSqlStatus: item.textToSqlStatus,
      generatedAnswerStatus: item.sqlToTextStatus,
      comment: item.comment,
      executableSql: item.response.query_result?.length !== 0,
      data: item,
    };
  }

  private getResponse(item: HistoryItem) {
    if (item.type === HistoryItemType.MANUAL) {
      if (item?.response?.executed_query && item?.response?.answer) {
        return item?.response?.answer;
      } else {
        return item?.response.executed_query;
      }
    } else {
      return item?.response?.answer;
    }
  }

  private getRequest(item: HistoryItem) {
    if (item.type === HistoryItemType.MANUAL) {
      if (item?.request?.prompt && item?.response.executed_query) {
        return item?.request?.prompt;
      } else {
        return item?.response.executed_query;
      }
    } else {
      return item?.request?.prompt;
    }
  }

  private handleBreakpointChange(breakpoint: Breakpoint): void {
    this.isSmallScreen = breakpoint.Small || breakpoint.XSmall;
    if (breakpoint.Small || breakpoint.XSmall) {
      this.columns = this.smallScreenColumns;
    } else if (breakpoint.Medium) {
      this.columns = this.mediumScreenColumns;
    } else {
      this.columns = this.bigScreenColumns;
    }
  }

  openNewReviewItemDialog(): void {
    this.dialogRef = this.dialog.open(CreateReviewItemDialogComponent, {
      disableClose: true,
      width: this.isSmallScreen ? '90vw' : '40vw',
    });

    this.dialogRef.afterClosed().subscribe((result: CreateHistoryItemResponse) => {
      this.handleDatasetDialogCloseEvent(result);
    });
  }

  private handleDatasetDialogCloseEvent(result: CreateHistoryItemResponse) {
    if (result.createdItem !== null) {
      this.rows.data = [this.createTableItem(result.createdItem), ...this.rows.data];
      this._snackBar.open('Item successfully added!', 'Close', { duration: 3000 });
    }
  }

  protected readonly HistoryItemType = HistoryItemType;
}
