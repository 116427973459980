import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormatOptions, prettyPrintJson } from 'pretty-print-json';

@Component({
  selector: 'app-json-viewer',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['./json-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class JsonViewerComponent implements OnChanges, AfterViewInit {
  @ViewChild('jsonContainer', { static: false }) jsonContainer: ElementRef | undefined;

  @Input() data: any = {};

  ngOnChanges(changes: SimpleChanges): void {
    if (JSON.stringify(changes['data']?.previousValue) !== JSON.stringify(changes['data']?.currentValue)) {
      this.updateJsonContainer(changes['data']?.currentValue);
    }
  }

  private updateJsonContainer(data: any) {
    if (this.jsonContainer) {
      const options: FormatOptions = { linkUrls: true };
      this.jsonContainer.nativeElement.innerHTML = prettyPrintJson.toHtml(data, options);
    }
  }

  ngAfterViewInit(): void {
    this.updateJsonContainer(this.data);
  }
}
