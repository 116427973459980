import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { QueryResultRow, QueryResultRows } from '@types';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';

@Component({
  selector: 'app-sql-result-table',
  templateUrl: './sql-result-table.component.html',
  styleUrls: ['./sql-result-table.component.scss'],
})
export class SqlResultTableComponent implements OnChanges {
  rows: MatTableDataSource<QueryResultRow> = new MatTableDataSource<QueryResultRow>([]);
  columns: string[] = [];
  @Input() data?: QueryResultRows | undefined;
  @Input() sk?: string | undefined;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sk'] && changes['sk'].previousValue !== changes['sk'].currentValue) {
      this.rows = new MatTableDataSource<QueryResultRow>(this.convertToQueryRows(this.data));
      this.columns = this.rows.data[0] ? Object.keys(this.rows.data[0]) : [];
    }
    if (changes['data'] && changes['data'].previousValue !== changes['data'].currentValue) {
      this.rows = new MatTableDataSource<QueryResultRow>(this.convertToQueryRows(changes['data'].currentValue));
      this.columns = this.rows.data[0] ? Object.keys(this.rows.data[0]) : [];
    }
  }

  sortData(sort: Sort) {
    const data = this.rows.data.slice();
    if (!sort.active || sort.direction === '') {
      this.rows.data = data;
      return;
    }

    this.rows.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(a[sort.active], b[sort.active], isAsc);
    });
  }

  compare(a: number | string | boolean, b: number | string | boolean, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private convertToQueryRows(data: QueryResultRows | undefined): QueryResultRows {
    if (_.isString(data)) {
      return JSON.parse(data) as QueryResultRows;
    } else if (data) {
      return data;
    } else {
      return [];
    }
  }
}
