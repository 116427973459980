<h2 mat-dialog-title>Update API Configuration</h2>

<form [formGroup]="form" class="update-client-form" (ngSubmit)="submitForm()">
  <mat-dialog-content class="mat-typography">
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>Name</mat-label>
      <input matInput type="text" [formControl]="form.controls.name" autocomplete="off" />
      <mat-error *ngIf="form.controls.name.hasError('required')">Required!</mat-error>
    </mat-form-field>
    <mat-form-field class="mat-input-and-select">
      <mat-label>OpenAi model</mat-label>
      <mat-select [compareWith]="compareFn" [formControl]="form.controls.openAiModelId">
        <mat-option *ngFor="let model of models" [value]="model.id">
          {{ model.id }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>Description</mat-label>
      <input matInput type="text" [formControl]="form.controls.description" autocomplete="off" />
      <mat-error *ngIf="form.controls.description.hasError('required')">Required!</mat-error>
    </mat-form-field>
    <mat-form-field class="mat-input-and-select">
      <mat-label>Template selector</mat-label>
      <mat-select [compareWith]="compareTemplateFn" [formControl]="form.controls.template">
        <mat-option [value]="null">Clear</mat-option>
        <mat-option *ngFor="let template of templates" [value]="template">
          {{ template.name }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
    </mat-form-field>
    <mat-form-field
      *ngIf="form.controls.template.value?.template"
      appearance="fill"
      class="mat-input-and-select height-auto"
    >
      <mat-label>Template</mat-label>
      <textarea
        matInput
        type="text"
        cdkTextareaAutosize
        readonly
        [value]="form.controls.template.value?.template!"
      ></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="closeDialog()">Cancel</button>
    <button mat-button type="submit" [disabled]="isLoading">
      Update
      <mat-icon *ngIf="isLoading" class="spin" iconPositionEnd>sync</mat-icon>
    </button>
  </mat-dialog-actions>
</form>
