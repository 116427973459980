import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '@app/material.module';
import { DatasetsRoutingModule } from './datasets-routing.module';
import { DatasetsComponent } from './datasets.component';
import { CreateDatasetDialogComponent } from './create-dataset-dialog/create-dataset-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatasetSidebarListComponent } from '@app/datasets/dataset-sidebar/dataset-sidebar-list.component';
import { DatasetSidebarItemComponent } from '@app/datasets/dataset-sidebar/dataset-sidebar-item/dataset-sidebar-item.component';
import { DatasetListComponent } from '@app/datasets/dataset-list/dataset-list.component';
import { SharedModule } from '@shared';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    DatasetsRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
  ],
  declarations: [
    DatasetsComponent,
    DatasetListComponent,
    DatasetSidebarListComponent,
    DatasetSidebarItemComponent,
    CreateDatasetDialogComponent,
  ],
})
export class DatasetsModule {}
