<h2 mat-dialog-title>Delete {{ data.name }}</h2>
<form [formGroup]="form" class="delete-project-form" (ngSubmit)="submitForm()">
  <mat-dialog-content class="mat-typography">
    <div class="warning">
      Deleting the project permanently removes all datasets, prompts, and all files related to fine-tuning. Fine-tuned
      models will still remain accessible through the Open AI API.<br /><br />
      To confirm deletion, type <b>{{ data.name }}</b> in the field.
    </div>
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>Name of the project</mat-label>
      <input matInput type="text" [formControl]="form.controls.projectName" autocomplete="off" />
      <mat-error *ngIf="form.controls.projectName.hasError('required')">Required!</mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="closeDialog()">Cancel</button>
    <button mat-button type="submit" [disabled]="isLoading">
      Delete
      <mat-icon *ngIf="isLoading" class="spin" iconPositionEnd>sync</mat-icon>
    </button>
  </mat-dialog-actions>
</form>
