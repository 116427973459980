import { AfterViewInit, Component, Input, NgZone, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { HistoryItem, HistoryItemType, Model, ReviewStatus, UpdateExecutedQueryForm } from '@types';
import { getSegments, Segment } from 'sql-highlight';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { AiService, HistoryService } from '@shared';

@Component({
  selector: 'app-text-to-sql-request-info',
  templateUrl: './text-to-sql-request-info.component.html',
  styleUrls: ['./text-to-sql-request-info.component.scss'],
})
export class TextToSqlRequestInfoComponent implements OnChanges, AfterViewInit {
  @ViewChild('autosize') autosize?: CdkTextareaAutosize;
  @Input() data?: HistoryItem | undefined;

  promptExecutionModel?: Partial<Model>;
  request: string = '';
  response: Segment[] = [];
  disableAnimation: boolean = true;
  isUpdateExecutedQuery: boolean = false;
  updateExecutedQueryForm: FormGroup<UpdateExecutedQueryForm>;
  isUpdateExecutedQueryInProgress: boolean = false;
  protected readonly HistoryItemType = HistoryItemType;
  protected readonly ReviewStatus = ReviewStatus;
  isQueryResultGenerating: boolean = false;

  constructor(private _ngZone: NgZone, private historyService: HistoryService, private aiService: AiService) {
    this.updateExecutedQueryForm = new FormGroup<UpdateExecutedQueryForm>({
      query: new FormControl<string>(this.data?.response.executed_query || '', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']?.previousValue?.SK !== changes['data']?.currentValue?.SK) {
      if (this.data?.request?.prompt && this.data.type === HistoryItemType.MANUAL) {
        this.request = this.data.request.prompt;
        this.triggerRequestTextareaResize();
      } else if (this.data?.request?.prompt && this.data?.request?.textToSqlTemplate) {
        this.request = this.data.request.textToSqlTemplate.replace('{question}', this.data.request.prompt);
        this.triggerRequestTextareaResize();
      }
      if (this.data?.response.executed_query) {
        this.updateExecutedQueryForm.controls.query.setValue(this.data?.response.executed_query);
        this.response = getSegments(this.data.response.executed_query);
      }
      if (this.data?.request?.textToSqlModel) {
        this.promptExecutionModel = this.data.request.textToSqlModel;
      }
    }
  }

  // TODO This is fixed? Until then, here's a workaround: @dcrouch79 https://github.com/angular/components/issues/13870

  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => (this.disableAnimation = false));
  }

  triggerRequestTextareaResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => {
      if (this.autosize) {
        this.autosize.resizeToFitContent(true);
      }
    });
  }

  switchToUpdateExecutedQuery() {
    this.isUpdateExecutedQuery = true;
  }

  cancelUpdatingExecutedQuery() {
    this.updateExecutedQueryForm.controls.query.setValue(this.data?.response.executed_query || '');
    this.isUpdateExecutedQuery = false;
  }

  saveUpdatedExecutedQuery() {
    if (this.updateExecutedQueryForm.invalid) {
      return;
    }
    this.isUpdateExecutedQueryInProgress = true;
    this.historyService
      .updateHistoryItemResponse({
        PK: this.data?.PK,
        SK: this.data?.SK,
        executed_query: this.updateExecutedQueryForm.controls.query.value,
      })
      .pipe(
        finalize(() => {
          this.isUpdateExecutedQueryInProgress = false;
          this.isUpdateExecutedQuery = false;
        })
      )
      .subscribe((result) => {
        const itemToUpdateIndex = this.historyService.$items.value.findIndex((item) => item.SK === this.data?.SK);
        if (itemToUpdateIndex > -1) {
          this.historyService.$items.value[itemToUpdateIndex].response.executed_query = result.response;
          this.historyService.$items.next(this.historyService.$items.value);
        }
        this.response = getSegments(result.response);
      });
  }

  executeQuery() {
    this.isQueryResultGenerating = true;
    if (this.data) {
      this.aiService
        .executeQuery({
          query: this.data.response.executed_query,
          request: {
            ...this.data.request,
            PK: this.data.PK,
            SK: this.data.SK,
          },
        })
        .pipe(finalize(() => (this.isQueryResultGenerating = false)))
        .subscribe((result) => {
          const itemToUpdateIndex = this.historyService.$items.value.findIndex((item) => item.SK === this.data?.SK);
          if (itemToUpdateIndex > -1) {
            this.historyService.$items.value[itemToUpdateIndex].response.answer = result.answer;
            this.historyService.$items.value[itemToUpdateIndex].response.query_result = result.query_result;
            this.historyService.$items.value[itemToUpdateIndex] = {
              ...this.historyService.$items.value[itemToUpdateIndex],
            };
            this.historyService.$items.next(this.historyService.$items.value);
          }
        });
    }
  }
}
