<h2 mat-dialog-title>Add review item</h2>
<form [formGroup]="form" class="dataset-form" (ngSubmit)="submitForm()">
  <mat-dialog-content class="mat-typography">
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>Select a model</mat-label>
      <mat-select [formControl]="form.controls.model">
        <mat-option [value]="ModelGroup.TEXT_TO_SQL">Text to SQL</mat-option>
        <mat-option [value]="ModelGroup.SQL_TO_TEXT">SQL result to text</mat-option>
      </mat-select>
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
      <mat-error *ngIf="form.controls.model.hasError('required')">Required!</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>Request</mat-label>
      <textarea matInput cdkTextareaAutosize [formControl]="form.controls.request"></textarea>
      <mat-error *ngIf="form.controls.request.hasError('required')">Required!</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>Query Result (JSON Array)</mat-label>
      <textarea
        [placeholder]="'example: [{&quot;COUNT(*)&quot;: &quot;10&quot;}]'"
        matInput
        cdkTextareaAutosize
        [formControl]="form.controls.queryResult"
      ></textarea>
      <mat-error *ngIf="form.controls.request.hasError('required')">Required!</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>Response</mat-label>
      <textarea matInput cdkTextareaAutosize [formControl]="form.controls.response"></textarea>
      <mat-error *ngIf="form.controls.request.hasError('required')">Required!</mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="closeDialog()">Cancel</button>
    <button mat-button type="submit">Create</button>
  </mat-dialog-actions>
</form>
