import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { LastEvaluatedKey, PageableRequest, PageableResponse, PK } from '@types';
import { environment } from '@env/environment';
import * as _ from 'lodash';
import { BaseProjectService } from '@shared/base-project.service';
import { ProjectService } from '@shared/project.service';

export class ProjectPaginatorService<T extends PK> extends BaseProjectService {
  public pageSize: number;
  public $items: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  public lastEvaluatedKey?: LastEvaluatedKey;
  protected _pkTemplate: T['PK'];

  constructor(
    protected http: HttpClient,
    protected override projectService: ProjectService,
    pageSize: number,
    pkTemplate: T['PK']
  ) {
    super(projectService);
    this.pageSize = pageSize;
    this._pkTemplate = pkTemplate;
    this.subscribeToProjectChange();
  }

  subscribeToProjectChange() {
    this.projectService.selectedProject.subscribe((project) => {
      this.$items.next([]);
    });
  }

  get PK(): T['PK'] {
    return this._pkTemplate.replace('${projectName}', this.project || '');
  }

  /**
   * Get page of items
   * @param request PageableRequest
   * @returns Observable<PageableResponse<T>> Page of items
   */
  getPage(request?: PageableRequest<T>): Observable<PageableResponse<T>> {
    return this.http
      .post<PageableResponse<T>>(`${environment.serverUrl}/page`, {
        pageSize: this.pageSize,
        PK: this.PK,
        ...request,
      })
      .pipe(
        tap((page: PageableResponse<T>) => {
          const items: T[] = _.unionBy<T>(this.$items.getValue(), page.items, 'SK');
          this.$items.next(_.orderBy(items, 'SK', 'desc'));
          this.lastEvaluatedKey = page.lastEvaluatedKey;
        })
      );
  }

  /**
   * Get next page of items
   * @returns void
   */
  loadNextPage(): void {
    if (this.lastEvaluatedKey) {
      this.getPage({
        pageSize: this.pageSize,
        PK: this.PK,
        lastEvaluatedKey: this.lastEvaluatedKey,
      }).subscribe(() => {});
    }
  }
}
