import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { defaultModelMaxToken } from '@const';

@Component({
  selector: 'app-parameter-slider',
  templateUrl: './parameter-slider.component.html',
  styleUrls: ['./parameter-slider.component.scss'],
})
export class ParameterSliderComponent {
  @Input() control: FormControl<number> = new FormControl<number>(0, { nonNullable: true });
  @Input() name: string = '';
  @Input() min: number = 0;
  @Input() max: number = defaultModelMaxToken;
  @Input() step: number = 0;

  constructor() {}
}
