import { Component, Inject } from '@angular/core';
import { ClientConfigWithTemplate, DeleteClientForm, DeleteClientResponse } from '@types';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientConfigService } from '@shared/client-config.service';

@Component({
  selector: 'app-delete-client-dialog',
  templateUrl: './delete-client-dialog.component.html',
  styleUrls: ['./delete-client-dialog.component.scss'],
})
export class DeleteClientDialogComponent {
  form: FormGroup<DeleteClientForm>;
  isLoading: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<DeleteClientDialogComponent, DeleteClientResponse | null>,
    private snackBar: MatSnackBar,
    private clientConfigService: ClientConfigService,
    @Inject(MAT_DIALOG_DATA) public data: ClientConfigWithTemplate
  ) {
    this.form = new FormGroup<DeleteClientForm>({
      apiKeyId: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, Validators.pattern(new RegExp('^' + data.apiKeyId + '$'))],
      }),
    });
  }

  submitForm() {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
    this.clientConfigService
      .deleteClientApiConfig({
        PK: this.data.PK,
        SK: this.data.SK,
        apiKeyId: this.form.controls.apiKeyId.value,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response) => this.handleDeleteClientResponse(response));
  }

  closeDialog() {
    this.dialogRef.close(null);
  }

  private handleDeleteClientResponse(response: DeleteClientResponse) {
    this.snackBar.open('The API configuration has been successfully deleted.', 'Close', { duration: 3000 });
    this.dialogRef.close(response);
  }
}
