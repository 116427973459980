import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { PromptComponent } from './prompt.component';
import { Shell } from '@app/shell/shell.service';
import { AuthGuard } from '@shared/guard/auth.guard';
import { ProjectGuard } from '@shared/guard/project.guard';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: ':project/prompt',
      component: PromptComponent,
      canActivate: [AuthGuard, ProjectGuard],
      data: { title: marker('Prompt') },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class PromptRoutingModule {}
