import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReviewRoutingModule } from './review-routing.module';
import { ReviewComponent } from './review.component';
import { SharedModule } from '@shared';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReviewListComponent } from '@app/review/review-list/review-list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CreateReviewItemDialogComponent } from '@app/review/create-review-item-dialog/create-review-item-dialog.component';

@NgModule({
  imports: [CommonModule, TranslateModule, MaterialModule, ReviewRoutingModule, SharedModule, ReactiveFormsModule],
  declarations: [ReviewComponent, ReviewListComponent, CreateReviewItemDialogComponent],
})
export class ReviewModule {}
