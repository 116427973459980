import { Component, Inject } from '@angular/core';
import { GetProjectResponse, UpdateProjectForm, UpdateProjectRequest, UpdateProjectResponse } from '@types';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectService } from '@shared/project.service';
import { finalize } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-update-project-dialog',
  templateUrl: './update-project-dialog.component.html',
  styleUrls: ['./update-project-dialog.component.scss'],
})
export class UpdateProjectDialogComponent {
  form: FormGroup<UpdateProjectForm>;
  isLoading: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<UpdateProjectDialogComponent, UpdateProjectResponse | null>,
    private snackBar: MatSnackBar,
    private projectService: ProjectService,
    @Inject(MAT_DIALOG_DATA) public data: GetProjectResponse
  ) {
    this.form = new FormGroup<UpdateProjectForm>({
      dbPassword: new FormControl(data.configuration.dbPassword, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      dbUrl: new FormControl(data.configuration.dbUrl, { nonNullable: true, validators: [Validators.required] }),
      dbName: new FormControl(data.configuration.dbName, { nonNullable: true, validators: [Validators.required] }),
      dbUsername: new FormControl(data.configuration.dbUsername, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      openaiApiKey: new FormControl(data.configuration.openaiApiKey, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      projectName: new FormControl(data.project.name, { nonNullable: true, validators: [Validators.required] }),
    });
  }

  submitForm() {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
    const updateProjectRequest: UpdateProjectRequest = {
      dbPassword: this.form.controls.dbPassword.value,
      dbUrl: this.form.controls.dbUrl.value,
      dbName: this.form.controls.dbName.value,
      dbUsername: this.form.controls.dbUsername.value,
      openaiApiKey: this.form.controls.openaiApiKey.value,
      projectName: this.form.controls.projectName.value,
    };
    this.projectService
      .updateProject(updateProjectRequest)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response) => this.handleUpdateProjectResponse(response));
  }

  closeDialog() {
    this.dialogRef.close(null);
  }

  private handleUpdateProjectResponse(response: UpdateProjectResponse) {
    this.snackBar.open('The project has been successfully updated.', 'Close', { duration: 3000 });
    this.dialogRef.close(response);
  }
}
