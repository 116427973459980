<ng-container [formGroup]="formGroup" *ngIf="formGroup">
  <div class="model-wrapper">
    <div class="model-select-wrapper">
      <mat-form-field class="model-select mat-input-and-select">
        <mat-label>{{ name | translate }}</mat-label>
        <mat-select [compareWith]="compareFn" [formControl]="formGroup.controls.model">
          <mat-option *ngFor="let model of models" [value]="model">
            {{ model.id }}
          </mat-option>
        </mat-select>
        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
      </mat-form-field>
      <button mat-icon-button style="margin-top: 5px" (click)="isSettingOpen = !isSettingOpen">
        <mat-icon color="primary">tune</mat-icon>
      </button>
    </div>
    <mat-card class="sliders-card" *ngIf="isSettingOpen">
      <mat-card-header>
        <mat-card-title class="sliders-title">{{ formGroup.controls.model.value.id }} parameters</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <app-parameter-slider
          name="max_tokens"
          [control]="formGroup.controls.max_tokens"
          [max]="formGroup.controls.model.value.maxToken!"
          [min]="1"
          [step]="1"
        />
        <app-parameter-slider
          name="temperature"
          [control]="formGroup.controls.temperature"
          [min]="0"
          [max]="2"
          [step]="0.1"
        />
        <app-parameter-slider name="top_p" [control]="formGroup.controls.top_p" [min]="0" [max]="2" [step]="0.1" />
        <app-parameter-slider name="n" [control]="formGroup.controls.n" [min]="1" [max]="10" [step]="1" />
        <div class="model-actions">
          <button mat-raised-button class="model-action-button" type="button" (click)="resetModel()">
            <mat-icon>sync</mat-icon>
            Reset
          </button>
          <button mat-raised-button color="primary" class="model-action-button" type="button" (click)="saveModel()">
            <mat-icon fontSet="material-icons-outlined" class="material-symbols-outlined send-icon"> send</mat-icon>
            Save
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>
