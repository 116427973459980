<h2 mat-dialog-title class="title">Executed query</h2>
<mat-dialog-content class="mat-typography dialog-content">
  <app-sql-highlight class="sql-highlight" *ngIf="data.result.executed_query" [segments]="data.segments" />
  <app-sql-result-table
    [sk]="data.result.SK"
    [data]="data.result.query_result"
    *ngIf="data.result.query_result.length"
  />
  <p *ngIf="!data.result.query_result.length && !data.result.executed_query">There is no content to display</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]="null">
    <mat-icon>close</mat-icon>
    Cancel
  </button>
</mat-dialog-actions>
