<h2 mat-dialog-title>Update {{ data.project.name }} configurations</h2>
<form [formGroup]="form" class="update-project-form" (ngSubmit)="submitForm()">
  <mat-dialog-content class="mat-typography">
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>Openai API key</mat-label>
      <input matInput type="text" [formControl]="form.controls.openaiApiKey" autocomplete="off" />
      <mat-error *ngIf="form.controls.openaiApiKey.hasError('required')">Required!</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>Database host name or IP</mat-label>
      <input matInput type="text" [formControl]="form.controls.dbUrl" autocomplete="off" />
      <mat-error *ngIf="form.controls.dbUrl.hasError('required')">Required!</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>Database name</mat-label>
      <input matInput type="text" [formControl]="form.controls.dbName" autocomplete="off" />
      <mat-error *ngIf="form.controls.dbName.hasError('required')">Required!</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>Database username</mat-label>
      <input matInput type="text" [formControl]="form.controls.dbUsername" autocomplete="off" />
      <mat-error *ngIf="form.controls.dbUsername.hasError('required')">Required!</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>Database password</mat-label>
      <input matInput type="password" [formControl]="form.controls.dbPassword" autocomplete="off" />
      <mat-error *ngIf="form.controls.dbPassword.hasError('required')">Required!</mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="closeDialog()">Cancel</button>
    <button mat-button type="submit" [disabled]="isLoading">
      Update
      <mat-icon *ngIf="isLoading" class="spin" iconPositionEnd>sync</mat-icon>
    </button>
  </mat-dialog-actions>
</form>
