<ng-container *ngIf="datasetService.$items | async; else noItems">
  <mat-action-list class="items" [ngClass]="{ 'small-history-items': isSmallScreen }">
    <app-dataset-sidebar-item
      *ngFor="let item of datasetService.$items | async; trackBy: identify"
      [item]="item"
      [active]="item.SK === selectedItem?.SK"
      (click)="selectedItem = item; selectEvent.emit(item)"
    ></app-dataset-sidebar-item>
  </mat-action-list>
  <mat-divider class="divider"></mat-divider>
  <div class="actions">
    <button class="action-button button-with-icon" mat-button (click)="getNextPage()">
      <mat-icon class="icon">arrow_forward</mat-icon>
      <span>Load more</span>
    </button>
  </div>
</ng-container>
<ng-template #noItems>
  <p class="no-items-text">Currently there are no available datasets.</p>
</ng-template>
