<div class="dark-theme">
  <div *ngIf="!isDetailsVisible">
    <span>{{ 'Something went wrong! Check out the ' }}</span>
    <button mat-button color="warn" (click)="showDetails()">Details</button>
    or
    <button mat-button color="primary" (click)="dismiss()">Close</button>
    me.
  </div>
  <div *ngIf="isDetailsVisible" class="details">
    <div class="json">
      <app-json-viewer [data]="data"></app-json-viewer>
    </div>
    <div class="details-buttons">
      <button mat-button color="primary" (click)="hideDetails()">Hide</button>
      <button mat-button color="primary" (click)="dismiss()">Close</button>
    </div>
  </div>
</div>
