<div>
  <div [@.disabled]="disableAnimation" *ngIf="data?.type !== HistoryItemType.MANUAL">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Model Parameters</mat-panel-title>
      </mat-expansion-panel-header>
      <app-prompt-execution-info-table [modelExecutionDate]="data?.dateTime" [model]="promptExecutionModel" />
    </mat-expansion-panel>
  </div>
  <div *ngIf="request">
    <h2>Request</h2>
    <textarea
      #autosize="cdkTextareaAutosize"
      class="request-textarea custom-textarea"
      [cdkAutosizeMinRows]="20"
      matInput
      cdkTextareaAutosize
      disabled
      [value]="request"
    ></textarea>
  </div>
  <div>
    <h2>Response</h2>
    <p>{{ response }}</p>
  </div>
</div>
