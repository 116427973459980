import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TemplateConfig } from '@types';

@Component({
  selector: 'app-template-config',
  templateUrl: './template-config.component.html',
  styleUrls: ['./template-config.component.scss'],
})
export class TemplateConfigComponent {
  @Input() templates: TemplateConfig[] = [];
  @Output() deleteTemplateEvent: EventEmitter<TemplateConfig> = new EventEmitter<TemplateConfig>();
  @Output() updateTemplateEvent: EventEmitter<TemplateConfig> = new EventEmitter<TemplateConfig>();

  constructor() {}

  deleteTemplate(templateConfig: TemplateConfig) {
    this.deleteTemplateEvent.emit(templateConfig);
  }

  updateTemplate(templateConfig: TemplateConfig) {
    this.updateTemplateEvent.emit(templateConfig);
  }
}
