import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateDatasetDialogComponent } from '@app/datasets/create-dataset-dialog/create-dataset-dialog.component';
import { BreakpointService } from '@shared';
import { DatasetService } from '@shared/dataset.service';
import { CreateDatasetDialogCloseEvent, Dataset, DatasetItem, ModelGroup, PageableResponse } from '@types';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-datasets',
  templateUrl: './datasets.component.html',
  styleUrls: ['./datasets.component.scss', '../../theme/sidenav.scss'],
})
export class DatasetsComponent implements OnInit {
  @ViewChild('drawerRight') public rightSidenav: MatDrawer | undefined;
  @ViewChild('drawerLeft') public leftSidenav: MatDrawer | undefined;
  isSmallScreen: boolean = false;
  datasets: Dataset[] = [];
  dataset?: Dataset;
  selectedDataset?: Dataset;

  dialogRef?: MatDialogRef<CreateDatasetDialogComponent>;
  selectedRow?: DatasetItem;
  ModelGroup = ModelGroup;

  constructor(
    private dialog: MatDialog,
    private breakpointService: BreakpointService,
    private datasetService: DatasetService
  ) {}

  ngOnInit() {
    this.breakpointService.breakpoint$.subscribe((breakpoint) => {
      this.isSmallScreen = breakpoint.Small || breakpoint.XSmall;
      if (this.dialogRef) {
        this.dialogRef.updateSize(this.isSmallScreen ? '90vw' : '40vw');
      }
    });
    this.getDatasets();
  }

  openNewDatasetDialog(): void {
    this.dialogRef = this.dialog.open(CreateDatasetDialogComponent, {
      disableClose: true,
      width: this.isSmallScreen ? '90vw' : '40vw',
    });

    this.dialogRef.afterClosed().subscribe((result: CreateDatasetDialogCloseEvent) => {
      this.handleDatasetDialogCloseEvent(result);
    });
  }

  handleDatasetItemSelect(item: Dataset) {
    this.selectedDataset = item;
    this.rightSidenav?.close();
  }

  handleTableRowSelect(item: DatasetItem) {
    this.selectedRow = item;
    this.rightSidenav?.open();
  }

  closeRightSidenav() {
    this.selectedRow = undefined;
    this.rightSidenav?.close();
  }

  openLeftSidenav() {
    this.leftSidenav?.open();
  }

  private getDatasets() {
    this.datasetService
      .getPage({
        pageSize: 10000,
        PK: this.datasetService.PK,
      })
      .subscribe((response: PageableResponse<Dataset>) => {
        this.handleGetDatasetNamesResult(response.items);
      });
  }

  private handleGetDatasetNamesResult(datasets: Dataset[]) {
    this.datasets = datasets;
    if (datasets[0]) {
      this.selectedDataset = datasets[0];
    }
  }

  private handleDatasetDialogCloseEvent(result: CreateDatasetDialogCloseEvent | null) {
    if (result?.datasetName) {
      this.getDatasets();
    }
  }
}
