<div class="project">
  <form [formGroup]="projectForm">
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>Project</mat-label>
      <mat-select hideSingleSelectionIndicator [formControl]="projectForm.controls.project" [compareWith]="compareFn">
        <mat-option *ngFor="let project of projectService.$items | async" [value]="project">
          {{ project.name }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
    </mat-form-field>
    <div class="form-actions">
      <div class="buttons" [ngClass]="{ 'small-screen': isSmallScreen }">
        <button
          *ngIf="projectForm.controls.project.value?.name !== projectService.selectedProject.value?.name"
          color="primary"
          mat-raised-button
          aria-label="open project button"
          [disabled]="!projectForm.controls.project"
          (click)="selectProjectEvent.emit(projectForm.controls.project.value)"
        >
          Switch to {{ projectForm.controls.project.value?.name || 'project' }}
        </button>
        <a
          mat-button
          *ngIf="
            projectService.selectedProject.value?.name &&
            projectForm.controls.project.value?.name === projectService.selectedProject.value?.name
          "
          color="primary"
          mat-raised-button
          aria-label="start prompting"
          [routerLink]="['/', projectService.selectedProject.value?.name, 'prompt']"
        >
          Start prompting
        </a>
      </div>
    </div>
  </form>
</div>
