import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import {
  Dataset,
  DatasetFilter,
  DatasetFilterForm,
  DatasetItem,
  DatasetTableItem,
  ModelGroup,
  PageableResponse,
  QueryResultRow,
} from '@types';
import { getSegments, Segment } from 'sql-highlight';
import { Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DatasetItemService } from '@shared/dataset-item.service';
import { DatasetService } from '@shared/dataset.service';
import { finalize } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dataset-list',
  templateUrl: './dataset-list.component.html',
  styleUrls: ['./dataset-list.component.scss', '../../../theme/table.scss'],
})
export class DatasetListComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @Input() selectedDataset?: Dataset;
  @Input() selectedRow?: DatasetItem;
  @Output() rowSelectEvent: EventEmitter<DatasetItem> = new EventEmitter<DatasetItem>();
  rows: MatTableDataSource<DatasetTableItem> = new MatTableDataSource<DatasetTableItem>([]);
  columns: Array<keyof DatasetTableItem> = ['select', 'dateTime', 'request', 'response'];
  selection = new SelectionModel<DatasetTableItem>(true, []);

  ModelGroup = ModelGroup;
  isRefreshInProgress: boolean = false;
  filterFormGroup: FormGroup<DatasetFilterForm>;

  constructor(
    private datasetItemService: DatasetItemService,
    private datasetService: DatasetService,
    private _snackBar: MatSnackBar
  ) {
    this.filterFormGroup = new FormGroup<DatasetFilterForm>({
      search: new FormControl<string | null>(null),
    });
  }

  ngOnInit() {
    this.filterFormGroup.valueChanges.subscribe((filterValues) => {
      this.rows.filter = JSON.stringify(filterValues);
    });
    this.rows.filterPredicate = (record: DatasetTableItem, filter: string) => this.filterRows(filter, record);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedDataset']?.currentValue?.SK !== changes['selectedDataset']?.previousValue?.SK) {
      this.rows.data = [];
      this.handleDatasetChange(this.selectedDataset);
    }
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.rows.paginator = this.paginator;
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.rows.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.rows.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: DatasetTableItem): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.dateTime + 1}`;
  }

  createSegments(executed_query: string): Segment[] {
    return getSegments(executed_query);
  }

  sortData(sort: Sort) {
    const data = this.rows.data.slice();
    if (!sort.active || sort.direction === '') {
      this.rows.data = data;
      return;
    }

    this.rows.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(a[sort.active], b[sort.active], isAsc);
    });
  }

  compare(a: number | string | boolean, b: number | string | boolean, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  convertToJson(request: string): QueryResultRow {
    return JSON.parse(request);
  }

  handleRowClick(row: DatasetTableItem) {
    this.rowSelectEvent.emit(row.dataset);
  }

  private getSelectedDataset(): void {
    if (this.selectedDataset?.SK) {
      this.datasetItemService
        .getPage({
          pageSize: 10000,
          PK: this.selectedDataset.SK,
        })
        .subscribe((response: PageableResponse<DatasetItem>) => {
          this.handleGetPageResponse(response);
        });
    }
  }

  private handleGetPageResponse(response: PageableResponse<DatasetItem>) {
    this.rows.data = response.items.map((dataset) => this.createTableItem(dataset));
    this.selection = new SelectionModel<DatasetTableItem>(
      true,
      this.rows.data.filter((item) => item.select)
    );
    this.selection.changed.subscribe((change) => {
      this.handleSelectionChange(change);
    });
  }

  private createTableItem(dataset: DatasetItem): DatasetTableItem {
    return {
      request: this.getTableITemRequest(dataset),
      response: this.getTableItemResponse(dataset),
      dateTime: dataset.item.dateTime,
      select: dataset.selected,
      dataset: dataset,
    };
  }

  private getTableItemResponse(dataset: DatasetItem): string {
    if (this.selectedDataset?.model === ModelGroup.TEXT_TO_SQL) {
      return dataset.item.response.executed_query;
    } else {
      return dataset.item.response.answer;
    }
  }

  private getTableITemRequest(dataset: DatasetItem): string {
    if (this.selectedDataset?.model === ModelGroup.TEXT_TO_SQL) {
      return dataset.item.request.prompt;
    } else {
      return JSON.stringify(dataset.item.response.query_result, null, 2);
    }
  }

  private handleDatasetChange(dataset: Dataset | undefined) {
    if (dataset) {
      this.getSelectedDataset();
    } else {
      this.rows.data = [];
    }
  }

  private toggleSelection(item: DatasetTableItem) {
    return this.datasetItemService.selectDatasetItem({
      PK: item.dataset.PK,
      SK: item.dataset.SK,
      selected: !item.select,
    });
  }

  private handleSelectionChange(change: SelectionChange<DatasetTableItem>) {
    change.added.forEach((item) => {
      this.toggleSelection(item).subscribe(() => {});
    });
    change.removed.forEach((item) => {
      this.toggleSelection(item).subscribe(() => {});
    });
  }

  handleRefreshListButtonClick() {
    this.isRefreshInProgress = true;
    if (this.selectedDataset) {
      this.datasetService
        .refreshDataset({
          PK: this.selectedDataset.PK,
          SK: this.selectedDataset.SK,
        })
        .pipe(finalize(() => (this.isRefreshInProgress = false)))
        .subscribe(() => this.handleRefreshResponse());
    }
  }

  private handleRefreshResponse() {
    this._snackBar.open('Dataset refreshed!', 'Close', { duration: 3000 });
    this.getSelectedDataset();
  }

  private filterRows(filter: string, record: DatasetTableItem) {
    const filters: DatasetFilter = JSON.parse(filter);
    const filterResults: boolean[] = [];
    if (filters.search?.length) {
      filterResults.push(JSON.stringify(record.dataset).search(filters.search) !== -1);
    }
    if (filterResults.length) {
      return filterResults.every((result) => result);
    }
    return true;
  }
}
