import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ProjectService } from '@shared/project.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ProjectGuard implements CanActivate {
  constructor(private projectService: ProjectService, private snackBar: MatSnackBar, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const projectName = this.projectService.getProjectFromLocalStorage()?.name;
    const actualValue = route.paramMap.get('project');

    if (actualValue === 'login-callback') {
      this.router.navigate(['/projects']);
      return false;
    }

    if (actualValue === 'logout-callback') return false;

    if (projectName === actualValue) {
      return true;
    } else {
      this.snackBar.open(
        'The currently selected project is the ' +
          projectName +
          ' project. Switch to the ' +
          actualValue +
          ' to see the related data.',
        'Close',
        { duration: 8000 }
      );
      this.router.navigate(['/projects']); // Navigate to error page if values don't match
      return false;
    }
  }
}
