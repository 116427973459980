<h2 mat-dialog-title>Delete API key: {{ data.name }}</h2>
<form [formGroup]="form" class="delete-form" (ngSubmit)="submitForm()">
  <mat-dialog-content class="mat-typography">
    <div class="warning">
      Please note that deleting your API key is a permanent action and cannot be undone. Once your key is deleted, any
      applications or services that rely on it will no longer work properly.<br /><br />
      To confirm deletion, type <b>{{ data.apiKeyId }}</b> in the field.
    </div>
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>API Key identifier</mat-label>
      <input matInput type="text" [formControl]="form.controls.apiKeyId" autocomplete="off" />
      <mat-error *ngIf="form.controls.apiKeyId.hasError('required')">Required!</mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="closeDialog()">Cancel</button>
    <button mat-button type="submit" [disabled]="isLoading">
      Delete
      <mat-icon *ngIf="isLoading" class="spin" iconPositionEnd>sync</mat-icon>
    </button>
  </mat-dialog-actions>
</form>
