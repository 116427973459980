<h2 mat-dialog-title>Create dataset</h2>
<form [formGroup]="newDatasetFormGroup" class="dataset-form" (ngSubmit)="submitForm()">
  <mat-dialog-content class="mat-typography">
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>Enter the name of the dataset</mat-label>
      <input matInput [formControl]="newDatasetFormGroup.controls.name" />
      <mat-error *ngIf="newDatasetFormGroup.controls.name.hasError('required')">Required!</mat-error>
      <mat-error *ngIf="newDatasetFormGroup.controls.name.errors?.['alreadyExists']"
        >This name is already exists!</mat-error
      >
    </mat-form-field>
    <mat-checkbox [formControl]="newDatasetFormGroup.controls.useExisting" class="form-item"
      >Use existing dataset
    </mat-checkbox>
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>Select an existing dataset</mat-label>
      <mat-select [formControl]="newDatasetFormGroup.controls.datasetToClone">
        <mat-option *ngFor="let dataset of datasetService.$items | async" [value]="dataset">{{
          dataset.name
        }}</mat-option>
      </mat-select>
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
      <mat-error *ngIf="newDatasetFormGroup.controls.datasetToClone.hasError('required')">Required!</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>Select a model to fine-tune</mat-label>
      <mat-select [formControl]="newDatasetFormGroup.controls.model">
        <mat-option [value]="ModelGroup.TEXT_TO_SQL">Text to SQL</mat-option>
        <mat-option [value]="ModelGroup.SQL_TO_TEXT">SQL result to text</mat-option>
      </mat-select>
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
      <mat-error *ngIf="newDatasetFormGroup.controls.model.hasError('required')">Required!</mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="closeDialog()">Cancel</button>
    <button mat-button type="submit" [disabled]="isDatasetCreationInProgress">
      Create
      <mat-icon *ngIf="isDatasetCreationInProgress" class="spin" iconPositionEnd>sync</mat-icon>
    </button>
  </mat-dialog-actions>
</form>
