import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProjectService } from '@shared/project.service';
import { PageableResponse, Project, ProjectFormGroup } from '@types';
import { FormControl, FormGroup } from '@angular/forms';
import { Breakpoint } from '../../../classes/breakpoint.class';
import { BreakpointService } from '@shared';

@Component({
  selector: 'app-project-selector',
  templateUrl: './project-selector.component.html',
  styleUrls: ['./project-selector.component.scss'],
})
export class ProjectSelectorComponent implements OnInit {
  @Output() selectProjectEvent: EventEmitter<Project | null> = new EventEmitter<Project | null>();

  isSmallScreen: boolean = false;
  projectForm: FormGroup<ProjectFormGroup>;

  constructor(public projectService: ProjectService, private breakpointService: BreakpointService) {
    this.projectForm = new FormGroup<ProjectFormGroup>({
      project: new FormControl<Project | null>({
        value: null,
        disabled: false,
      }),
    });
  }

  ngOnInit() {
    this.projectService
      .getPage({
        pageSize: 10000,
        PK: this.projectService.PK,
      })
      .subscribe((result) => this.handleGetPageResponse(result));
    this.projectService.selectedProject.subscribe((project) => this.handleProjectChange(project));
    this.breakpointService.breakpoint$.subscribe((breakpoint) => this.handleBreakpointChange(breakpoint));
  }

  compareFn(c1: Project, c2: Project): boolean {
    return c1 && c2 ? c1.SK === c2.SK : c1 === c2;
  }

  private handleBreakpointChange(breakpoint: Breakpoint): void {
    this.isSmallScreen = breakpoint.Small || breakpoint.XSmall;
  }

  private handleGetPageResponse(response: PageableResponse<Project>) {
    this.projectForm.controls.project.setValue(this.projectService.getProjectFromLocalStorage() || response.items[0]);
  }

  private handleProjectChange(project: Project | null) {
    this.projectForm.controls.project.setValue(project);
  }
}
