import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CreateHistoryItemRequest, CreateHistoryItemResponse, ModelGroup, ReviewItemCreateForm } from '@types';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HistoryService } from '@shared/history.service';

@Component({
  selector: 'app-create-review-item-dialog',
  templateUrl: './create-review-item-dialog.component.html',
  styleUrls: ['./create-review-item-dialog.component.scss'],
})
export class CreateReviewItemDialogComponent implements OnInit {
  form: FormGroup<ReviewItemCreateForm>;

  ModelGroup = ModelGroup;

  constructor(
    private historyService: HistoryService,
    private dialogRef: MatDialogRef<CreateReviewItemDialogComponent, CreateHistoryItemResponse | null>,
    private _snackBar: MatSnackBar
  ) {
    this.form = new FormGroup<ReviewItemCreateForm>({
      model: new FormControl<ModelGroup>(
        { value: ModelGroup.TEXT_TO_SQL, disabled: false },
        {
          validators: [Validators.required],
          nonNullable: true,
        }
      ),
      request: new FormControl<string>(
        { value: '', disabled: false },
        {
          validators: [Validators.required],
          nonNullable: true,
        }
      ),
      queryResult: new FormControl<string>(
        { value: '', disabled: true },
        {
          validators: [Validators.required],
          nonNullable: true,
        }
      ),
      response: new FormControl<string>(
        { value: '', disabled: false },
        {
          validators: [Validators.required],
          nonNullable: true,
        }
      ),
    });
  }

  ngOnInit() {
    this.form.controls.model.valueChanges.subscribe((value) => {
      if (value === ModelGroup.TEXT_TO_SQL) {
        this.form.controls.queryResult.disable({ onlySelf: true });
      } else {
        this.form.controls.queryResult.enable({ onlySelf: true });
      }
    });
  }

  submitForm() {
    if (this.form.valid) {
      if (this.form.controls.model.value === ModelGroup.TEXT_TO_SQL) {
        const newItem: CreateHistoryItemRequest = {
          model: this.form.controls.model.value,
          request: {
            prompt: this.form.controls.request.value,
          },
          response: {
            executed_query: this.form.controls.response.value,
          },
        };
        this.createItem(newItem);
      } else {
        const newItem: CreateHistoryItemRequest = {
          model: this.form.controls.model.value,
          request: {},
          response: {
            executed_query: this.form.controls.request.value,
            query_result: JSON.parse(this.form.controls.queryResult.value),
            answer: this.form.controls.response.value,
          },
        };
        this.createItem(newItem);
      }
    }
  }

  private createItem(newItem: CreateHistoryItemRequest) {
    this.historyService.createItem(newItem).subscribe({
      next: (response) => {
        this.dialogRef.close(response);
      },
      error: (error: HttpErrorResponse) => {
        this._snackBar.open('Failed to create history item!', 'Close', { duration: 3000 });
      },
    });
  }

  closeDialog() {
    this.dialogRef.close(null);
  }
}
