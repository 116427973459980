<h2 mat-dialog-title>Update template</h2>

<form [formGroup]="form" class="update-template-form" (ngSubmit)="submitForm()">
  <mat-dialog-content class="mat-typography">
    <mat-form-field appearance="fill" class="form-item mat-input-and-select">
      <mat-label>Name</mat-label>
      <input matInput type="text" [formControl]="form.controls.name" autocomplete="off" />
      <mat-error *ngIf="form.controls.name.hasError('required')">Required!</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" class="form-item">
      <mat-label>Template</mat-label>
      <textarea
        matInput
        type="text"
        [formControl]="form.controls.template"
        autocomplete="off"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="5"
        cdkAutosizeMaxRows="20"
      ></textarea>
      <mat-hint>{{ 'template.textToSqlTemplateHint' | translate }}</mat-hint>
      <mat-error *ngIf="form.controls.template.hasError('required')">Required!</mat-error>
    </mat-form-field>
    <mat-checkbox [formControl]="form.controls.isDefault" color="primary">Set this to default</mat-checkbox>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="closeDialog()">Cancel</button>
    <button mat-button type="submit" [disabled]="isLoading">
      Update
      <mat-icon *ngIf="isLoading" class="spin" iconPositionEnd>sync</mat-icon>
    </button>
  </mat-dialog-actions>
</form>
