<form>
  <mat-form-field appearance="fill" class="mat-input-and-select">
    <mat-label>API URL</mat-label>
    <input matInput type="text" readonly [value]="clientApiUrl + '/prompt'" />
  </mat-form-field>
</form>
<h3>Generated API keys</h3>
<span *ngIf="clients?.length === 0"> No API key has been created yet </span>
<div *ngFor="let client of clients" class="client-api">
  <table>
    <tbody>
      <tr>
        <td>Name</td>
        <td>{{ client.name }}</td>
      </tr>
      <tr>
        <td>Description</td>
        <td>{{ client.description }}</td>
      </tr>
      <tr>
        <td>Api key</td>
        <td>{{ client.apiKeyValue }}</td>
      </tr>
      <tr>
        <td>Open AI Model</td>
        <td>{{ client.openAiModelId }}</td>
      </tr>
      <tr>
        <td>Template</td>
        <td>{{ client.template?.name ? client.template?.name : '-' }}</td>
      </tr>
    </tbody>
  </table>
  <div class="client-api-actions">
    <button mat-raised-button color="primary" type="button" (click)="updateClient(client)">Edit</button>
    <button mat-raised-button color="warn" type="button" (click)="deleteClient(client)">Delete</button>
  </div>
</div>
