import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DeleteTemplateForm, DeleteTemplateResponse, TemplateConfig } from '@types';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators';
import { TemplateConfigService } from '@shared/template-config.service';

@Component({
  selector: 'app-delete-template-dialog',
  templateUrl: './delete-template-dialog.component.html',
  styleUrls: ['./delete-template-dialog.component.scss'],
})
export class DeleteTemplateDialogComponent {
  form: FormGroup<DeleteTemplateForm>;
  isLoading: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<DeleteTemplateDialogComponent, DeleteTemplateResponse | null>,
    private snackBar: MatSnackBar,
    private templateConfigService: TemplateConfigService,
    @Inject(MAT_DIALOG_DATA) public data: TemplateConfig
  ) {
    this.form = new FormGroup<DeleteTemplateForm>({
      name: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, Validators.pattern(new RegExp('^' + data.name + '$'))],
      }),
    });
  }

  submitForm() {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
    this.templateConfigService
      .deleteTemplateConfig({
        PK: this.data.PK,
        SK: this.data.SK,
        name: this.form.controls.name.value,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response) => this.handleDeleteTemplateResponse(response));
  }

  closeDialog() {
    this.dialogRef.close(null);
  }

  private handleDeleteTemplateResponse(response: DeleteTemplateResponse) {
    this.snackBar.open('The template has been successfully deleted.', 'Close', { duration: 3000 });
    this.dialogRef.close(response);
  }
}
