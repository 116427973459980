import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BreakpointService } from '@shared';
import { Auth } from 'aws-amplify';
import { ProjectService } from '@shared/project.service';
import { Project } from '@types';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  isSmallScreen?: boolean;
  activeProject?: Project | null;
  notOnProjectsPage: boolean | undefined;

  constructor(
    private router: Router,
    private breakpointService: BreakpointService,
    private projectService: ProjectService
  ) {
    this.breakpointService.breakpoint$.subscribe(
      (breakpoint) => (this.isSmallScreen = breakpoint.Small || breakpoint.XSmall)
    );
    this.activeProject = this.projectService.getProjectFromLocalStorage();
    this.projectService.selectedProject.next(this.activeProject);
    this.projectService.selectedProject.subscribe((project) => (this.activeProject = project));
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.notOnProjectsPage = event.url !== '/projects';
      }
    });
  }

  logout() {
    Auth.signOut();
  }
}
