import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CreateFineTunedModelWithDatasetRequest,
  CreateFineTunedModelWithDatasetResponse,
  CreateFineTuneWithDatasetForm,
  Dataset,
  ExtendedModelData,
  ModelGroup,
  Ulid,
} from '@types';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FineTuneService } from '@shared/fine-tune.service';
import { DatasetService } from '@shared/dataset.service';
import { AiService } from '@shared/ai.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-create-fine-tune-dialog',
  templateUrl: './create-fine-tune-dialog.component.html',
  styleUrls: ['./create-fine-tune-dialog.component.scss'],
})
export class CreateFineTuneDialogComponent implements OnInit {
  form: FormGroup<CreateFineTuneWithDatasetForm>;
  fineTunableModels: ExtendedModelData[] = [];
  ModelGroup = ModelGroup;
  isCreateFineTuneInProgress: boolean = false;

  constructor(
    private fineTuneService: FineTuneService,
    private aiService: AiService,
    public datasetService: DatasetService,
    private dialogRef: MatDialogRef<CreateFineTuneDialogComponent, CreateFineTunedModelWithDatasetResponse | null>,
    private _snackBar: MatSnackBar
  ) {
    this.form = new FormGroup<CreateFineTuneWithDatasetForm>({
      DatasetPK: new FormControl<`DATASET#${Ulid}` | null>(
        {
          value: null,
          disabled: false,
        },
        { validators: [Validators.required] }
      ),
      model: new FormControl<string | null>(
        {
          value: null,
          disabled: false,
        },
        { validators: [Validators.required] }
      ),
      suffix: new FormControl<string | null>(
        {
          value: null,
          disabled: false,
        },
        { validators: [Validators.maxLength(40)] }
      ),
      isHyperparametersEnabled: new FormControl<boolean>(
        {
          value: false,
          disabled: false,
        },
        { nonNullable: true }
      ),
      n_epochs: new FormControl<number>(
        {
          value: 4,
          disabled: true,
        },
        { validators: [Validators.required], nonNullable: true }
      ),
      batch_size: new FormControl<number>(
        {
          value: 4,
          disabled: true,
        },
        { validators: [Validators.required], nonNullable: true }
      ),
      learning_rate_multiplier: new FormControl<number>(
        {
          value: 0.1,
          disabled: true,
        },
        { validators: [Validators.required], nonNullable: true }
      ),
      isDataset: new FormControl<boolean | null>(
        {
          value: true,
          disabled: false,
        },
        { validators: [Validators.required] }
      ),
    });
  }

  ngOnInit() {
    this.datasetService
      .getPage({
        pageSize: 10000,
        PK: this.datasetService.PK,
      })
      .subscribe();
    this.aiService.getOpenAiModels({}).subscribe((models) => {
      this.fineTunableModels = models.filter((model) => model.permission.some((perm) => perm.allow_fine_tuning));
    });
    this.form.controls.isHyperparametersEnabled.valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls.n_epochs.enable({ onlySelf: true });
        this.form.controls.batch_size.enable({ onlySelf: true });
        this.form.controls.learning_rate_multiplier.enable({ onlySelf: true });
      } else {
        this.form.controls.n_epochs.disable({ onlySelf: true });
        this.form.controls.batch_size.disable({ onlySelf: true });
        this.form.controls.learning_rate_multiplier.disable({ onlySelf: true });
      }
    });
  }

  submitForm() {
    if (this.form.valid && this.form.controls.DatasetPK?.value) {
      let hyperparameters: Partial<CreateFineTunedModelWithDatasetRequest> = {};
      if (this.form.controls.isHyperparametersEnabled.value) {
        hyperparameters = {
          n_epochs: this.form.controls?.n_epochs?.value,
          batch_size: this.form.controls?.batch_size?.value,
          learning_rate_multiplier: this.form.controls?.learning_rate_multiplier?.value,
        };
      }
      this.startFineTune({
        DatasetPK: this.form.controls.DatasetPK.value,
        suffix: this.form.controls.suffix.value || '',
        model: this.form.controls.model.value || '',
        isDataset: true,
        ...hyperparameters,
      });
    }
  }

  identify(index: number, item: Dataset) {
    return item.SK;
  }

  private startFineTune(request: CreateFineTunedModelWithDatasetRequest) {
    this.isCreateFineTuneInProgress = true;
    this.fineTuneService
      .createFineTunedModelWithDataset(request)
      .pipe(
        finalize(() => {
          this.isCreateFineTuneInProgress = false;
        })
      )
      .subscribe({
        next: (response) => {
          this.dialogRef.close(response);
        },
        error: (error: HttpErrorResponse) => {
          this._snackBar.open('Failed to start fine-tune!', 'Close', { duration: 3000 });
        },
      });
  }

  closeDialog() {
    this.dialogRef.close(null);
  }
}
