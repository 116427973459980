import { Component, Inject } from '@angular/core';
import { CreateClientForm, CreateClientRequest, CreateClientResponse, ExtendedModelData, TemplateConfig } from '@types';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientConfigService } from '@shared/client-config.service';
import { AiService } from '@shared';
import { TemplateConfigService } from '@shared/template-config.service';

@Component({
  selector: 'app-create-client-dialog',
  templateUrl: './create-client-dialog.component.html',
  styleUrls: ['./create-client-dialog.component.scss'],
})
export class CreateClientDialogComponent {
  form: FormGroup<CreateClientForm>;
  isLoading: boolean = false;
  models: ExtendedModelData[] = [];
  templates: TemplateConfig[] = [];
  constructor(
    private dialogRef: MatDialogRef<CreateClientDialogComponent, CreateClientResponse | null>,
    private snackBar: MatSnackBar,
    private clientConfigService: ClientConfigService,
    private aiService: AiService,
    private templateService: TemplateConfigService,
    @Inject(MAT_DIALOG_DATA) data: TemplateConfig[]
  ) {
    this.form = new FormGroup<CreateClientForm>({
      openAiModelId: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
      name: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
      description: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
      template: new FormControl<TemplateConfig | null>(null),
    });
    this.aiService.getOpenAiModels({}).subscribe((models) => (this.models = models));
    this.templates = Array.isArray(data) ? data : [data];
  }

  submitForm() {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
    const createClientRequest: CreateClientRequest = {
      openAiModelId: this.form.controls.openAiModelId.value,
      name: this.form.controls.name.value,
      description: this.form.controls.description.value,
    };
    if (this.form.controls.template.value?.SK) {
      createClientRequest.templateSK = this.form.controls.template.value?.SK;
    }

    this.clientConfigService
      .createClientApiConfig(createClientRequest)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((result) => this.handleCreateClientResponse(result));
  }

  private handleCreateClientResponse(response: CreateClientResponse) {
    this.snackBar.open('The new API key has been successfully created.', 'Close', { duration: 3000 });
    this.dialogRef.close(response);
  }

  closeDialog() {
    this.dialogRef.close(null);
  }

  compareModelDataFn(c1: ExtendedModelData, c2: ExtendedModelData): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  compareTemplateFn(c1: TemplateConfig, c2: TemplateConfig): boolean {
    return c1 && c2 ? c1.SK === c2.SK : c1 === c2;
  }
}
