<form [formGroup]="form" class="project-info" *ngIf="form">
  <mat-dialog-content class="mat-typography">
    <mat-form-field appearance="fill" class="form-item">
      <mat-label>Openai API key</mat-label>
      <input matInput type="password" [formControl]="form.controls.openaiApiKey" />
    </mat-form-field>
    <mat-form-field appearance="fill" class="form-item">
      <mat-label>Database host</mat-label>
      <input matInput type="text" [formControl]="form.controls.dbUrl" />
    </mat-form-field>
    <mat-form-field appearance="fill" class="form-item">
      <mat-label>Database name</mat-label>
      <input matInput type="text" [formControl]="form.controls.dbName" />
    </mat-form-field>
    <mat-form-field appearance="fill" class="form-item">
      <mat-label>Database username</mat-label>
      <input matInput type="text" [formControl]="form.controls.dbUsername" />
    </mat-form-field>
    <mat-form-field appearance="fill" class="form-item">
      <mat-label>Database password</mat-label>
      <input matInput type="password" [formControl]="form.controls.dbPassword" />
    </mat-form-field>
  </mat-dialog-content>
</form>
