import { ProjectName } from '@types';
import { ProjectService } from '@shared/project.service';

export class BaseProjectService {
  constructor(protected projectService: ProjectService) {}

  get project(): ProjectName | null {
    return this.projectService.selectedProject.value?.name || null;
  }
}
