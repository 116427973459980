import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import {
  CreateHistoryItemResponse,
  DeleteFineTuneResponse,
  ExtendedModelData,
  FineTune,
  FineTuneFilter,
  FineTuneFilterForm,
  FineTuneStatus,
  FineTuneTableItem,
  GetFineTuneListResponse,
  HistoryItemType,
  ModelGroup,
  ReviewStatus,
} from '@types';
import { Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { BreakpointService } from '@shared';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateFineTuneDialogComponent } from '@app/fine-tune/create-fine-tune-dialog/create-fine-tune-dialog.component';
import { FineTuneService } from '@shared/fine-tune.service';
import { endOfDay, fromUnixTime, isAfter, isBefore, isWithinInterval, startOfDay } from 'date-fns';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AiService } from '@shared/ai.service';
import { forkJoin, Subject } from 'rxjs';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { Breakpoint } from '../../../classes/breakpoint.class';

@Component({
  selector: 'app-fine-tune-list',
  templateUrl: './fine-tune-list.component.html',
  styleUrls: ['./fine-tune-list.component.scss', '../../../theme/chip.scss', '../../../theme/table.scss'],
})
export class FineTuneListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild('table') table?: MatTable<FineTuneTableItem>;
  @Input() selectedRowId?: string;
  @Output() rowSelectEvent: EventEmitter<FineTune> = new EventEmitter<FineTune>();
  rows: MatTableDataSource<FineTuneTableItem> = new MatTableDataSource<FineTuneTableItem>([]);
  isSmallScreen: boolean = false;
  columns: Array<keyof FineTuneTableItem> = [
    'select',
    'createdAt',
    'model',
    'fineTunedModel',
    'status',
    'canItBeUsedForPrompting',
  ];
  selection = new SelectionModel<FineTuneTableItem>(false, []);
  isDeletionInProgress: boolean = false;
  dialogRef?: MatDialogRef<CreateFineTuneDialogComponent>;
  isCancelInProgress: boolean = false;
  modelsList: ExtendedModelData[] = [];

  filterFormGroup = new FormGroup<FineTuneFilterForm>({
    text: new FormControl<string | null>(null),
    createdAtDateRange: new FormGroup({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null),
    }),
    status: new FormControl<FineTuneStatus | null>(null),
    usableForPrompting: new FormControl<boolean | null>(null),
  });

  protected readonly ModelGroup = ModelGroup;
  protected readonly ReviewStatus = ReviewStatus;
  protected readonly HistoryItemType = HistoryItemType;
  protected readonly FineTuneStatus = FineTuneStatus;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private fineTuneService: FineTuneService,
    private breakpointService: BreakpointService,
    private _snackBar: MatSnackBar,
    private aiService: AiService
  ) {}

  ngOnInit() {
    this.getFineTuneList();
    this.filterFormGroup.valueChanges.subscribe((filterValues) => {
      this.rows.filter = JSON.stringify(filterValues);
    });
    this.breakpointService.breakpoint$.subscribe((breakpoint) => this.handleBreakpointChange(breakpoint));
    this.rows.filterPredicate = (record: FineTuneTableItem, filter: string) => this.filterRows(filter, record);
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.rows.paginator = this.paginator;
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  sortData(sort: Sort): void {
    const data: FineTuneTableItem[] = this.rows.data.slice();
    if (!sort.active || sort.direction === '') {
      this.rows.data = data;
      return;
    }

    this.rows.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(a[sort.active], b[sort.active], isAsc);
    });
  }

  private filterRows(filter: string, record: FineTuneTableItem): boolean {
    const filters: FineTuneFilter = JSON.parse(filter);
    const filterResults: boolean[] = [];
    if (filters.text?.length) {
      filterResults.push(JSON.stringify(record.data).search(filters.text) !== -1);
    }
    if (filters.status?.length) {
      filterResults.push(record.status === filters.status);
    }
    if (filters.usableForPrompting !== null && filters.usableForPrompting) {
      filterResults.push(this.modelsList.findIndex((model) => model.id === record.fineTunedModel) > -1);
    }
    if (filters.usableForPrompting !== null && !filters.usableForPrompting) {
      filterResults.push(this.modelsList.findIndex((model) => model.id === record.fineTunedModel) === -1);
    }
    if (filters.createdAtDateRange?.start && filters.createdAtDateRange?.end) {
      try {
        filterResults.push(
          isWithinInterval(new Date(record.createdAt), {
            start: startOfDay(new Date(filters.createdAtDateRange.start)),
            end: endOfDay(new Date(filters.createdAtDateRange.end)),
          })
        );
      } catch (error) {
        filterResults.push(false);
      }
    }
    if (filters.createdAtDateRange?.start && !filters.createdAtDateRange?.end) {
      try {
        filterResults.push(isAfter(new Date(record.createdAt), new Date(filters.createdAtDateRange?.start)));
      } catch (error) {
        filterResults.push(false);
      }
    }
    if (filters.createdAtDateRange?.end && !filters.createdAtDateRange?.start) {
      try {
        filterResults.push(isBefore(new Date(record.createdAt), new Date(filters.createdAtDateRange?.end)));
      } catch (error) {
        filterResults.push(false);
      }
    }
    if (filterResults.length) {
      return filterResults.every((result) => result);
    }
    return true;
  }

  compare(a: number | string | boolean, b: number | string | boolean, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  handleRowClick(row: FineTuneTableItem) {
    this.rowSelectEvent.emit(row.data);
  }

  handleRowSelectCheckboxEvent($event: MatCheckboxChange, row: FineTuneTableItem) {
    if ($event) {
      this.selection.clear();
      return $event.checked ? this.selection.select(row) : this.selection.deselect(row);
    } else {
      return null;
    }
  }

  deleteItem(items: FineTuneTableItem[]) {
    this.isDeletionInProgress = true;
    if (items.length === 1 && items[0].fineTunedModel) {
      const modelToDelete = items[0].fineTunedModel;
      this.fineTuneService
        .deleteFineTunedModel({
          name: modelToDelete,
        })
        .pipe(finalize(() => (this.isDeletionInProgress = false)))
        .subscribe((result) => this.handleFineTuneDeleteResult(result));
    }
  }

  openCreateFineTuneDialog(): void {
    this.dialogRef = this.dialog.open(CreateFineTuneDialogComponent, {
      disableClose: true,
      width: this.isSmallScreen ? '90vw' : '40vw',
    });

    this.dialogRef.afterClosed().subscribe((result: CreateHistoryItemResponse) => {
      this.handleDatasetDialogCloseEvent(result);
    });
  }

  isAnySelectedFineTunePending(selected: FineTuneTableItem[]) {
    return selected.some((item) => item.status === FineTuneStatus.PENDING || item.status === FineTuneStatus.RUNNING);
  }

  isAllSelectedFineTunePending(selected: FineTuneTableItem[]) {
    return selected.every((item) => item.status === FineTuneStatus.PENDING || item.status === FineTuneStatus.RUNNING);
  }

  cancelFineTune(items: FineTuneTableItem[]) {
    this.isCancelInProgress = true;
    if (items.length === 1 && items[0].id) {
      const fineTuneId = items[0].id;
      this.fineTuneService
        .cancelFineTuneModel({
          id: fineTuneId,
        })
        .pipe(finalize(() => (this.isCancelInProgress = false)))
        .subscribe((result) => this.handleCancelFineTuneResult(result));
    }
  }

  private getFineTuneList() {
    return forkJoin({
      fineTuneList: this.fineTuneService.getFineTuneList({}),
      modelsList: this.aiService.getOpenAiModels({}),
    }).subscribe((result) => {
      this.handleGetPageResponse(result.fineTuneList, result.modelsList);
    });
  }

  private handleGetPageResponse(fineTuneList: GetFineTuneListResponse, modelsList: ExtendedModelData[]): void {
    this.modelsList = modelsList;
    this.rows.data = _.orderBy(fineTuneList.files.data, 'created_at', 'desc').map((item) =>
      this.createTableItem(item, modelsList)
    );
    this.selection.clear();
  }

  private createTableItem(item: FineTune, modelsList: ExtendedModelData[]): FineTuneTableItem {
    return {
      select: false,
      createdAt: fromUnixTime(item.created_at).toISOString(),
      id: item.id,
      model: item.model,
      fineTunedModel: item.fine_tuned_model,
      trainingFile: JSON.stringify(item.training_files),
      status: item.status,
      canItBeUsedForPrompting: modelsList.findIndex((model) => model.id === item.fine_tuned_model) > -1,
      data: item,
    };
  }

  private handleDatasetDialogCloseEvent(result: CreateHistoryItemResponse | null) {
    if (result?.createdItem != null) {
      this._snackBar.open('The fine-tune successfully started!', 'Close', { duration: 3000 });
      this.getFineTuneList();
    }
  }

  private handleFineTuneDeleteResult(deleteResult: DeleteFineTuneResponse) {
    if (deleteResult.deleted) {
      this._snackBar.open('The fine-tune successfully deleted!', 'Close', { duration: 3000 });
      this.getFineTuneList();
    }
  }

  private handleCancelFineTuneResult(result: FineTune) {
    this._snackBar.open('The fine-tune successfully canceled!', 'Close', { duration: 3000 });
    this.getFineTuneList();
  }

  private handleBreakpointChange(breakpoint: Breakpoint): void {
    this.isSmallScreen = breakpoint.Small || breakpoint.XSmall;
  }
}
