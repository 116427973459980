import { AfterViewInit, Component, Input, NgZone, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { HistoryItem, HistoryItemType, Model, QueryResultRows } from '@types';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs';

@Component({
  selector: 'app-sql-to-text-request-info',
  templateUrl: './sql-to-text-request-info.component.html',
  styleUrls: ['./sql-to-text-request-info.component.scss'],
})
export class SqlToTextRequestInfoComponent implements OnChanges, AfterViewInit {
  @ViewChild('autosize') autosize?: CdkTextareaAutosize;
  @Input() data?: HistoryItem | undefined;

  request: string = '';
  response: string = '';
  promptExecutionModel?: Partial<Model>;
  disableAnimation: boolean = true;

  constructor(private _ngZone: NgZone) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].previousValue !== changes['data'].currentValue) {
      this.handleDataChange();
    }
  }

  private handleDataChange() {
    if (this.data?.response?.executed_query && this.data.type === HistoryItemType.MANUAL) {
      this.request =
        this.data?.response?.executed_query + '\n\n' + this.getQueryResult(this.data.response.query_result);
      this.triggerRequestTextareaResize();
    } else if (this.data?.response?.executed_query && this.data?.request?.sqlResultToTextTemplate) {
      this.request = this.data.request.sqlResultToTextTemplate
        .replace('{query}', this.data.response.executed_query)
        .replace('{result}', this.getQueryResult(this.data.response.query_result));
      this.triggerRequestTextareaResize();
    }
    if (this.data?.response.executed_query) {
      this.response = this.data.response.answer;
    }
    if (this.data?.request?.sqlResultToTextModel) {
      this.promptExecutionModel = this.data.request.sqlResultToTextModel;
    }
  }

  // TODO This is fixed? Until then, here's a workaround: @dcrouch79 https://github.com/angular/components/issues/13870
  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => (this.disableAnimation = false));
  }

  triggerRequestTextareaResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => {
      if (this.autosize) {
        this.autosize.resizeToFitContent(true);
      }
    });
  }

  private getQueryResult(query_result: QueryResultRows | string): string {
    if (typeof query_result === 'string') {
      return query_result;
    } else {
      return JSON.stringify(query_result);
    }
  }

  protected readonly HistoryItemType = HistoryItemType;
}
