import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
  ExecuteQueryRequest,
  ExtendedModelData,
  GetModelsRequest,
  GetModelsResponse,
  QueryResultRows,
  TextCompletionRequest,
  TextCompletionResponse,
} from '@types';
import * as _ from 'lodash';
import { BaseProjectService } from '@shared/base-project.service';
import { ProjectService } from '@shared/project.service';
import { OpenaiUtils } from '@shared/openai-utils';

@Injectable({
  providedIn: 'root',
})
export class AiService extends BaseProjectService {
  public textCompletionSubject = new BehaviorSubject<QueryResultRows>([]);

  constructor(private http: HttpClient, protected override projectService: ProjectService) {
    super(projectService);
  }

  getTextCompletion(request: TextCompletionRequest): Observable<TextCompletionResponse> {
    return this.http
      .post<TextCompletionResponse>(`${environment.serverUrl}/prompt`, {
        project: this.project,
        ...request,
      })
      .pipe(
        map((response: TextCompletionResponse) => {
          if (_.isString(response.query_result)) {
            response.query_result = JSON.parse(response.query_result) as QueryResultRows;
          }
          this.textCompletionSubject.next(response.query_result);
          return response;
        })
      );
  }

  getOpenAiModels(request: GetModelsRequest): Observable<ExtendedModelData[]> {
    return this.http
      .post<GetModelsResponse>(`${environment.serverUrl}/models`, {
        project: this.project,
        ...request,
      })
      .pipe(
        map((response: GetModelsResponse) => {
          return response.data.map((data) => {
            return { ...data, maxToken: OpenaiUtils.getMaxToken(data) };
          });
        })
      );
  }

  executeQuery(executeQueryRequest: ExecuteQueryRequest): Observable<TextCompletionResponse> {
    return this.http.post<TextCompletionResponse>(
      `${environment.serverUrl}/history/execute-query`,
      executeQueryRequest
    );
  }
}
