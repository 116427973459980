import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import {
  ClientConfig,
  CreateClientRequest,
  CreateClientResponse,
  DeleteClientRequest,
  DeleteClientResponse,
  UpdateClientRequest,
  UpdateClientResponse,
} from '@types';
import { Observable } from 'rxjs';
import { ProjectPaginatorService } from '@shared/project-paginator.service';
import { ProjectService } from '@shared/project.service';

@Injectable({
  providedIn: 'root',
})
export class ClientConfigService extends ProjectPaginatorService<ClientConfig> {
  constructor(protected override http: HttpClient, protected override projectService: ProjectService) {
    super(http, projectService, 10000, 'PROJECTCLIENT#${projectName}');
  }

  createClientApiConfig(req: CreateClientRequest): Observable<CreateClientResponse> {
    return this.http.put<CreateClientResponse>(`${environment.serverUrl}/client`, {
      project: this.project,
      ...req,
    });
  }

  updateClientApiConfig(req: UpdateClientRequest): Observable<UpdateClientResponse> {
    return this.http.patch<UpdateClientResponse>(`${environment.serverUrl}/client`, {
      project: this.project,
      ...req,
    });
  }

  deleteClientApiConfig(req: DeleteClientRequest): Observable<DeleteClientResponse> {
    return this.http.post<DeleteClientResponse>(`${environment.serverUrl}/client/delete`, {
      project: this.project,
      ...req,
    });
  }
}
