import { Component, Input } from '@angular/core';
import { Dataset } from '@types';

@Component({
  selector: 'app-dataset-sidebar-item',
  templateUrl: './dataset-sidebar-item.component.html',
  styleUrls: ['./dataset-sidebar-item.component.scss'],
})
export class DatasetSidebarItemComponent {
  @Input() item?: Dataset;
  @Input() active?: boolean;
}
