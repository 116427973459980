<div class="table-buttons-container" [formGroup]="filterFormGroup">
  <mat-form-field class="mat-input-and-select">
    <input matInput type="text" placeholder="Search..." [formControl]="filterFormGroup.controls.search" />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
  <button mat-flat-button color="accent" (click)="handleRefreshListButtonClick()">
    <mat-icon [ngClass]="{ spin: isRefreshInProgress }">sync</mat-icon>
    Refresh list
  </button>
</div>
<div class="mat-elevation-z4">
  <table matSort mat-table [dataSource]="rows" (matSortChange)="sortData($event)">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          color="primary"
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          color="primary"
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Request Column -->
    <ng-container matColumnDef="request">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Request</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="selectedDataset?.model === ModelGroup.TEXT_TO_SQL">
          {{ element.request }}
        </ng-container>
        <ng-container *ngIf="selectedDataset?.model === ModelGroup.SQL_TO_TEXT">
          {{ convertToJson(element.request) }}
        </ng-container>
      </td>
    </ng-container>

    <!-- Response Column when model is TEXT_TO_SQL -->
    <ng-container matColumnDef="response">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Response</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="selectedDataset?.model === ModelGroup.TEXT_TO_SQL">
          <app-sql-highlight class="sql-highlight" [segments]="createSegments(element.response)" />
        </ng-container>
        <ng-container *ngIf="selectedDataset?.model === ModelGroup.SQL_TO_TEXT">
          {{ element.response }}
        </ng-container>
      </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="dateTime">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Created</th>
      <td mat-cell *matCellDef="let element" class="date-time">{{ element.dateTime | date : 'MMM d, y, H:mm' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columns"
      (click)="handleRowClick(row)"
      [ngClass]="{ 'row-selected': selectedRow?.SK === row.dataset.SK }"
    ></tr>
    <tr class="no-data-row" *matNoDataRow>
      <td class="no-data-row" [attr.colspan]="columns.length">No data matching the filter.</td>
    </tr>
  </table>
  <mat-paginator class="paginator" [pageSizeOptions]="[10, 20, 50, 100]" aria-label="Select page of items">
  </mat-paginator>
</div>
