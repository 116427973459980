import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '@app/material.module';
import { LoaderComponent } from './loader/loader.component';
import { PromptExecutionInfoTableComponent } from '@shared/model-info-table/prompt-execution-info-table.component';
import { SqlToTextRequestInfoComponent } from '@shared/sql-to-text-request-info/sql-to-text-request-info.component';
import { TextToSqlRequestInfoComponent } from '@shared/text-to-sql-request-info/text-to-sql-request-info.component';
import { SqlHighlightComponent } from '@shared/sql-highlight/sql-highlight.component';
import { SqlResultTableComponent } from '@shared/sql-result-table/sql-result-table.component';
import { ParameterSliderComponent } from '@shared/parameter-slider/parameter-slider.component';
import { ReactiveFormsModule } from '@angular/forms';
import { JsonViewerComponent } from '@shared/json-viewer/json-viewer.component';
import { ErrorSnackBarComponent } from '@shared/error-snackbar/error-snack-bar.component';

@NgModule({
  imports: [MaterialModule, TranslateModule, CommonModule, ReactiveFormsModule],
  declarations: [
    JsonViewerComponent,
    LoaderComponent,
    ParameterSliderComponent,
    PromptExecutionInfoTableComponent,
    SqlHighlightComponent,
    SqlResultTableComponent,
    SqlToTextRequestInfoComponent,
    TextToSqlRequestInfoComponent,
    ErrorSnackBarComponent,
  ],
  exports: [
    JsonViewerComponent,
    LoaderComponent,
    ParameterSliderComponent,
    PromptExecutionInfoTableComponent,
    SqlHighlightComponent,
    SqlResultTableComponent,
    SqlToTextRequestInfoComponent,
    TextToSqlRequestInfoComponent,
    ErrorSnackBarComponent,
  ],
})
export class SharedModule {}
