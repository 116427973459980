// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  production: false,
  version: env['npm_package_version'] + '-dev',
  serverUrl: 'https://api.data-talk.int.sonrisa.hu/dev',
  clientApiUrl: 'https://ug1h5o5q6f.execute-api.eu-central-1.amazonaws.com/dev',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US'],
  aws: {
    Auth: {
      region: 'eu-central-1',
      userPoolId: 'eu-central-1_h60uDLsPH',
      userPoolWebClientId: '7bksdnimqkvntlu6ssjccb8kh4',
      oauth: {
        domain: 'data-talk-dev.auth.eu-central-1.amazoncognito.com',
        scope: ['email', 'openid'],
        redirectSignIn: 'https://data-talk-dev.int.sonrisa.hu/login-callback',
        redirectSignOut: 'https://data-talk-dev.int.sonrisa.hu/logout-callback',
        responseType: 'code',
      },
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
