import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Dataset } from '@types';
import { DatasetService } from '@shared/dataset.service';

@Component({
  selector: 'app-dataset-sidebar-list',
  templateUrl: './dataset-sidebar-list.component.html',
  styleUrls: ['./dataset-sidebar-list.component.scss'],
})
export class DatasetSidebarListComponent {
  @Output() selectEvent = new EventEmitter<Dataset>();

  @Input() selectedItem?: Dataset;
  @Input() isSmallScreen?: boolean;

  constructor(public datasetService: DatasetService) {}

  identify(index: number, item: Dataset) {
    return item.SK;
  }

  getNextPage() {
    this.datasetService.loadNextPage();
  }
}
