import { Component, Inject, NgZone, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TemplateConfig, UpdateTemplateForm, UpdateTemplateRequest, UpdateTemplateResponse } from '@types';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators';
import { TemplateConfigService } from '@shared/template-config.service';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs';

@Component({
  selector: 'app-update-template-dialog',
  templateUrl: './update-template-dialog.component.html',
  styleUrls: ['./update-template-dialog.component.scss'],
})
export class UpdateTemplateDialogComponent {
  form: FormGroup<UpdateTemplateForm>;
  isLoading: boolean = false;
  @ViewChild('autosize') autosize?: CdkTextareaAutosize;
  constructor(
    private dialogRef: MatDialogRef<UpdateTemplateDialogComponent, UpdateTemplateResponse | null>,
    private snackBar: MatSnackBar,
    private templateConfigService: TemplateConfigService,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: TemplateConfig
  ) {
    this.form = new FormGroup<UpdateTemplateForm>({
      name: new FormControl(data.name, { nonNullable: true, validators: [Validators.required] }),
      template: new FormControl(data.template, { nonNullable: true, validators: [Validators.required] }),
      isDefault: new FormControl(data.isDefault, { nonNullable: true }),
    });
    this.ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize?.resizeToFitContent(true));
  }

  submitForm() {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
    const updateTemplateRequest: UpdateTemplateRequest = {
      PK: this.data.PK,
      SK: this.data.SK,
      name: this.form.controls.name.value,
      template: this.form.controls.template.value,
      isDefault: this.form.controls.isDefault.value,
    };
    this.templateConfigService
      .updateTemplateConfig(updateTemplateRequest)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((result) => this.handleUpdateTemplateResponse(result));
  }

  private handleUpdateTemplateResponse(response: UpdateTemplateResponse) {
    this.snackBar.open('The template has been successfully updated.', 'Close', { duration: 3000 });
    this.dialogRef.close(response);
  }

  closeDialog() {
    this.dialogRef.close(null);
  }
}
