import { Injectable, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { Breakpoint } from '../../classes/breakpoint.class';
import { Display } from '@app/@types';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class BreakpointService implements OnDestroy {
  public breakpoint$ = new BehaviorSubject<Breakpoint>(new Breakpoint());

  queryMap = new Map([
    [Breakpoints.XSmall, Display.XSMALL],
    [Breakpoints.Small, Display.SMALL],
    [Breakpoints.Medium, Display.MEDIUM],
    [Breakpoints.Large, Display.LARGE],
    [Breakpoints.XLarge, Display.XLARGE],
    [Breakpoints.Handset, Display.HANDSET],
    [Breakpoints.Tablet, Display.TABLET],
    [Breakpoints.Web, Display.WEB],
    [Breakpoints.HandsetPortrait, Display.HANDSET_PORTRAIT],
    [Breakpoints.TabletPortrait, Display.TABLET_PORTRAIT],
    [Breakpoints.WebPortrait, Display.WEB_PORTRAIT],
    [Breakpoints.HandsetLandscape, Display.HANDSET_LANDSCAPE],
    [Breakpoints.TabletLandscape, Display.TABLET_LANDSCAPE],
    [Breakpoints.WebLandscape, Display.WEB_LANDSCAPE],
  ]);

  destroyed = new Subject<void>();

  constructor(private breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
        Breakpoints.Handset,
        Breakpoints.Tablet,
        Breakpoints.Web,
        Breakpoints.HandsetPortrait,
        Breakpoints.TabletPortrait,
        Breakpoints.WebPortrait,
        Breakpoints.HandsetLandscape,
        Breakpoints.TabletLandscape,
        Breakpoints.WebLandscape,
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => this.handleBreakpointChange(result));
  }

  handleBreakpointChange(breakpointState: BreakpointState) {
    const breakpoint: Breakpoint = new Breakpoint();
    _.reduce(
      breakpointState.breakpoints,
      (result, value, key) => {
        const queryName = this.queryMap.get(key);
        breakpoint.XSmall = (queryName === Display.XSMALL && value) || breakpoint.XSmall;
        breakpoint.Small = (queryName === Display.SMALL && value) || breakpoint.Small;
        breakpoint.Medium = (queryName === Display.MEDIUM && value) || breakpoint.Medium;
        breakpoint.Large = (queryName === Display.LARGE && value) || breakpoint.Large;
        breakpoint.XLarge = (queryName === Display.XLARGE && value) || breakpoint.XLarge;
        breakpoint.Handset = (queryName === Display.HANDSET && value) || breakpoint.Handset;
        breakpoint.Tablet = (queryName === Display.TABLET && value) || breakpoint.Tablet;
        breakpoint.Web = (queryName === Display.WEB && value) || breakpoint.Web;
        breakpoint.HandsetPortrait = (queryName === Display.HANDSET_PORTRAIT && value) || breakpoint.HandsetPortrait;
        breakpoint.TabletPortrait = (queryName === Display.TABLET_PORTRAIT && value) || breakpoint.TabletPortrait;
        breakpoint.WebPortrait = (queryName === Display.WEB_PORTRAIT && value) || breakpoint.WebPortrait;
        breakpoint.HandsetLandscape = (queryName === Display.HANDSET_LANDSCAPE && value) || breakpoint.HandsetLandscape;
        breakpoint.TabletLandscape = (queryName === Display.TABLET_LANDSCAPE && value) || breakpoint.TabletLandscape;
        breakpoint.WebLandscape = (queryName === Display.WEB_LANDSCAPE && value) || breakpoint.WebLandscape;
        return breakpoint;
      },
      new Breakpoint()
    );

    this.breakpoint$.next(breakpoint);
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
