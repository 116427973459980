import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GetProjectResponse, ProjectConfiguration, UpdateProjectForm } from '@types';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.scss'],
})
export class ProjectInfoComponent implements OnChanges {
  @Input() project: GetProjectResponse | undefined;

  form: FormGroup<Omit<UpdateProjectForm, 'projectName'>> = this.createForm();

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['project'].currentValue &&
      changes['project'].previousValue?.project?.name !== changes['project'].currentValue?.project?.name
    ) {
      this.form = this.createForm(changes['project'].currentValue.configuration);
    } else if (!changes['project'].currentValue) {
      this.form = this.createForm();
    } else {
      this.form = this.createForm(changes['project'].currentValue.configuration);
    }
  }

  private createForm(configuration?: ProjectConfiguration): FormGroup<Omit<UpdateProjectForm, 'projectName'>> {
    return new FormGroup<Omit<UpdateProjectForm, 'projectName'>>({
      dbPassword: new FormControl(
        {
          value: configuration?.dbPassword || '',
          disabled: true,
        },
        {
          nonNullable: true,
          validators: [Validators.required],
        }
      ),
      dbUrl: new FormControl(
        {
          value: configuration?.dbUrl || '',
          disabled: true,
        },
        {
          nonNullable: true,
          validators: [Validators.required],
        }
      ),
      dbName: new FormControl(
        {
          value: configuration?.dbName || '',
          disabled: true,
        },
        {
          nonNullable: true,
          validators: [Validators.required],
        }
      ),
      dbUsername: new FormControl(
        {
          value: configuration?.dbUsername || '',
          disabled: true,
        },
        {
          nonNullable: true,
          validators: [Validators.required],
        }
      ),
      openaiApiKey: new FormControl(
        {
          value: configuration?.openaiApiKey || '',
          disabled: true,
        },
        {
          nonNullable: true,
          validators: [Validators.required],
        }
      ),
    });
  }
}
