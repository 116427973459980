<table>
  <tbody>
    <tr>
      <td>Execution date</td>
      <td>{{ modelExecutionDate | date : 'MMM d, y, H:mm' }}</td>
    </tr>
    <tr>
      <td>model</td>
      <td>{{ model?.model?.id }}</td>
    </tr>
    <tr>
      <td>max_tokens</td>
      <td>{{ model?.max_tokens }}</td>
    </tr>
    <tr>
      <td>n</td>
      <td>{{ model?.n }}</td>
    </tr>
    <tr>
      <td>top_p</td>
      <td>{{ model?.top_p }}</td>
    </tr>
    <tr class="mat-row">
      <td>temperature</td>
      <td>{{ model?.temperature }}</td>
    </tr>
  </tbody>
</table>
