import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from '@env/environment';

@Component({
  selector: 'app-api-usage-guide-dialog',
  templateUrl: './api-usage-guide-dialog.component.html',
  styleUrls: ['./api-usage-guide-dialog.component.scss'],
})
export class ApiUsageGuideDialogComponent {
  clientApiUrl: string = environment.clientApiUrl;

  constructor(private dialogRef: MatDialogRef<ApiUsageGuideDialogComponent, null>) {}

  closeDialog() {
    this.dialogRef.close(null);
  }
}
