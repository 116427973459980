import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectsRoutingModule } from './projects-routing.module';
import { ProjectsComponent } from './projects.component';
import { SharedModule } from '@shared';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ProjectSelectorComponent } from '@app/projects/project-selector/project-selector.component';
import { CreateProjectDialogComponent } from '@app/projects/create-project-dialog/create-project-dialog.component';
import { UpdateProjectDialogComponent } from '@app/projects/update-project-dialog/update-project-dialog.component';
import { ProjectInfoComponent } from '@app/projects/project-info/project-info.component';
import { DeleteProjectDialogComponent } from '@app/projects/delete-project-dialog/delete-project-dialog.component';
import { ClientAppConfigComponent } from '@app/projects/client-config/client-app-config.component';
import { CreateClientDialogComponent } from '@app/projects/create-client-dialog/create-client-dialog.component';
import { DeleteClientDialogComponent } from '@app/projects/delete-client-dialog/delete-client-dialog.component';
import { UpdateClientDialogComponent } from '@app/projects/update-client-dialog/update-client-dialog.component';
import { ApiUsageGuideDialogComponent } from '@app/projects/api-usage-guide-dialog/api-usage-guide-dialog.component';
import { TemplateConfigComponent } from './template-config/template-config.component';
import { CreateTemplateDialogComponent } from './create-template-dialog/create-template-dialog.component';
import { UpdateTemplateDialogComponent } from './update-template-dialog/update-template-dialog.component';
import { DeleteTemplateDialogComponent } from './delete-template-dialog/delete-template-dialog.component';

@NgModule({
  imports: [CommonModule, TranslateModule, MaterialModule, ProjectsRoutingModule, SharedModule, ReactiveFormsModule],
  declarations: [
    ClientAppConfigComponent,
    CreateClientDialogComponent,
    CreateProjectDialogComponent,
    DeleteClientDialogComponent,
    DeleteProjectDialogComponent,
    ApiUsageGuideDialogComponent,
    ProjectInfoComponent,
    ProjectSelectorComponent,
    ProjectsComponent,
    UpdateClientDialogComponent,
    UpdateProjectDialogComponent,
    TemplateConfigComponent,
    CreateTemplateDialogComponent,
    UpdateTemplateDialogComponent,
    DeleteTemplateDialogComponent,
  ],
})
export class ProjectsModule {}
