import { ModelData } from '@types';
import { defaultModelMaxToken, openaiModelsAdditionalData } from '@const';

export class OpenaiUtils {
  static getMaxToken(model: ModelData): number {
    const modelId: string = model.id;
    const parentModelId: string = model.parent;
    if (modelId && modelId in openaiModelsAdditionalData) {
      return openaiModelsAdditionalData[modelId].maxToken;
    }
    if (parentModelId && parentModelId in openaiModelsAdditionalData) {
      return openaiModelsAdditionalData[parentModelId].maxToken;
    }
    return defaultModelMaxToken;
  }
}
