import { Injectable } from '@angular/core';
import { ProjectPaginatorService } from '@shared/project-paginator.service';
import {
  CreateTemplateRequest,
  CreateTemplateResponse,
  DeleteTemplateRequest,
  DeleteTemplateResponse,
  TemplateConfig,
  UpdateTemplateRequest,
  UpdateTemplateResponse,
} from '@types';
import { HttpClient } from '@angular/common/http';
import { ProjectService } from '@shared/project.service';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class TemplateConfigService extends ProjectPaginatorService<TemplateConfig> {
  constructor(protected override http: HttpClient, protected override projectService: ProjectService) {
    super(http, projectService, 10000, 'PROJECTTEMPLATE#${projectName}');
  }

  createTemplateConfig(req: CreateTemplateRequest): Observable<CreateTemplateResponse> {
    return this.http.put<CreateTemplateResponse>(`${environment.serverUrl}/template`, {
      project: this.project,
      ...req,
    });
  }

  updateTemplateConfig(req: UpdateTemplateRequest): Observable<UpdateTemplateResponse> {
    return this.http.patch<UpdateTemplateResponse>(`${environment.serverUrl}/template`, {
      project: this.project,
      ...req,
    });
  }

  deleteTemplateConfig(req: DeleteTemplateRequest): Observable<DeleteTemplateResponse> {
    return this.http.post<DeleteTemplateResponse>(`${environment.serverUrl}/template/delete`, {
      project: this.project,
      ...req,
    });
  }
}
