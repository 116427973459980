import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FineTuneRoutingModule } from './fine-tune-routing.module';
import { FineTuneComponent } from './fine-tune.component';
import { SharedModule } from '@shared';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FineTuneListComponent } from '@app/fine-tune/fine-tune-list/fine-tune-list.component';
import { CreateFineTuneDialogComponent } from '@app/fine-tune/create-fine-tune-dialog/create-fine-tune-dialog.component';

@NgModule({
  imports: [CommonModule, TranslateModule, MaterialModule, FineTuneRoutingModule, SharedModule, ReactiveFormsModule],
  declarations: [FineTuneComponent, FineTuneListComponent, CreateFineTuneDialogComponent],
})
export class FineTuneModule {}
