import { Component, OnInit } from '@angular/core';
import { ProjectService } from '@shared/project.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BreakpointService } from '@shared';
import { Breakpoint } from '../../classes/breakpoint.class';
import { CreateProjectDialogComponent } from '@app/projects/create-project-dialog/create-project-dialog.component';
import {
  ClientConfigWithTemplate,
  CreateClientResponse,
  CreateTemplateResponse,
  DeleteClientResponse,
  DeleteProjectResponse,
  DeleteTemplateResponse,
  GetProjectResponse,
  Project,
  TemplateConfig,
  UpdateClientResponse,
  UpdateProjectResponse,
  UpdateTemplateResponse,
} from '@types';
import { UpdateProjectDialogComponent } from '@app/projects/update-project-dialog/update-project-dialog.component';
import { finalize } from 'rxjs/operators';
import { DeleteProjectDialogComponent } from '@app/projects/delete-project-dialog/delete-project-dialog.component';
import * as _ from 'lodash';
import { CreateClientDialogComponent } from '@app/projects/create-client-dialog/create-client-dialog.component';
import { ClientConfigService } from '@shared/client-config.service';
import { DeleteClientDialogComponent } from '@app/projects/delete-client-dialog/delete-client-dialog.component';
import { UpdateClientDialogComponent } from '@app/projects/update-client-dialog/update-client-dialog.component';
import { ApiUsageGuideDialogComponent } from '@app/projects/api-usage-guide-dialog/api-usage-guide-dialog.component';
import { CreateTemplateDialogComponent } from '@app/projects/create-template-dialog/create-template-dialog.component';
import { DeleteTemplateDialogComponent } from '@app/projects/delete-template-dialog/delete-template-dialog.component';
import { UpdateTemplateDialogComponent } from '@app/projects/update-template-dialog/update-template-dialog.component';
import { TemplateConfigService } from '@shared/template-config.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {
  isSmallScreen: boolean = false;
  createProjectDialogRef?: MatDialogRef<CreateProjectDialogComponent>;
  updateProjectDialogRef?: MatDialogRef<UpdateProjectDialogComponent>;
  deleteProjectDialogRef?: MatDialogRef<DeleteProjectDialogComponent>;
  createClientDialogRef?: MatDialogRef<CreateClientDialogComponent>;
  updateClientDialogRef?: MatDialogRef<UpdateClientDialogComponent>;
  deleteClientDialogRef?: MatDialogRef<DeleteClientDialogComponent>;
  apiUsageGuideDialogRef?: MatDialogRef<ApiUsageGuideDialogComponent>;
  createTemplateDialogRef?: MatDialogRef<CreateTemplateDialogComponent>;
  updateTemplateDialogRef?: MatDialogRef<UpdateTemplateDialogComponent>;
  deleteTemplateDialogRef?: MatDialogRef<DeleteTemplateDialogComponent>;
  projectConfiguration?: GetProjectResponse;
  isProjectLoading = false;
  clientsWithTemplates: ClientConfigWithTemplate[] = [];
  templates: TemplateConfig[] = [];
  constructor(
    public projectService: ProjectService,
    private clientConfigService: ClientConfigService,
    private dialog: MatDialog,
    private breakpointService: BreakpointService,
    private templateConfigService: TemplateConfigService
  ) {
    const project = this.projectService.getProjectFromLocalStorage();
    if (project) {
      this.projectService.getProject(project.name).subscribe((result) => this.handleGetProjectResponse(result));
    }
  }

  ngOnInit() {
    this.projectService
      .getPage({
        pageSize: 10000,
        PK: this.projectService.PK,
      })
      .subscribe();
    this.handleClientTemplateMapping();
    this.breakpointService.breakpoint$.subscribe((breakpoint) => this.handleBreakpointChange(breakpoint));
  }

  handleClientTemplateMapping() {
    return forkJoin({
      templates: this.templateConfigService.getPage(),
      clients: this.clientConfigService.getPage(),
    }).subscribe((result) => {
      this.clientsWithTemplates = result.clients.items.map((client) => {
        const template = result.templates.items.find((template) => template.SK === client.templateSK);
        return { ..._.omit(client, ['templateSK']), template: template };
      });
      this.templates = result.templates.items;
    });
  }

  openCreateProjectDialog() {
    this.createProjectDialogRef = this.dialog.open(CreateProjectDialogComponent, {
      disableClose: true,
      width: this.isSmallScreen ? '90vw' : '60vw',
    });

    this.createProjectDialogRef.afterClosed().subscribe((result: Project) => {
      if (result?.PK) {
        this.handleCreateProjectDialogCloseEvent(result);
      }
    });
  }

  openUpdateProjectDialog(project: GetProjectResponse) {
    this.updateProjectDialogRef = this.dialog.open(UpdateProjectDialogComponent, {
      disableClose: true,
      width: this.isSmallScreen ? '90vw' : '50em',
      data: project,
    });
    this.updateProjectDialogRef.afterClosed().subscribe((result: UpdateProjectResponse) => {
      if (result?.projectName) {
        this.handleUpdateProjectDialogCloseEvent(result);
      }
    });
  }

  openDeleteProjectDialog() {
    this.deleteProjectDialogRef = this.dialog.open(DeleteProjectDialogComponent, {
      disableClose: true,
      width: this.isSmallScreen ? '90vw' : '50em',
      data: this.projectService.selectedProject.value,
    });

    this.deleteProjectDialogRef.afterClosed().subscribe((result: DeleteProjectResponse) => {
      if (result?.projectName) {
        this.handleDeleteProjectDialogCloseEvent(result);
      }
    });
  }

  editProject() {
    if (this.projectService.selectedProject.value) {
      this.isProjectLoading = true;
      this.projectService
        .getProject(this.projectService.selectedProject.value?.name)
        .pipe(
          finalize(() => {
            this.isProjectLoading = false;
          })
        )
        .subscribe((response: GetProjectResponse) => {
          this.openUpdateProjectDialog(response);
        });
    }
  }

  deleteProject() {
    if (this.projectService.selectedProject.value) {
      this.openDeleteProjectDialog();
    }
  }

  handleProjectSelectEvent(project: Project | null) {
    if (project) {
      this.projectService.getProject(project.name).subscribe((result) => this.handleGetProjectResponse(result));
    }
  }

  openCreateClientDialog() {
    this.createClientDialogRef = this.dialog.open(CreateClientDialogComponent, {
      disableClose: true,
      width: this.isSmallScreen ? '90vw' : '60vw',
      data: this.templates,
    });

    this.createClientDialogRef.afterClosed().subscribe((result: CreateClientResponse) => {
      if (result?.PK) {
        this.handleCreateClientDialogCloseEvent(result);
      }
    });
  }

  openCreateTemplateDialog() {
    this.createTemplateDialogRef = this.dialog.open(CreateTemplateDialogComponent, {
      disableClose: true,
      width: this.isSmallScreen ? '90vw' : '60vw',
      data: this.projectService.selectedProject.value,
    });

    this.createTemplateDialogRef.afterClosed().subscribe((result: CreateTemplateResponse) => {
      if (result?.PK) {
        this.handleCreateTemplateDialogCloseEvent(result);
      }
    });
  }

  openApiUsageGuideDialog() {
    this.apiUsageGuideDialogRef = this.dialog.open(ApiUsageGuideDialogComponent, {
      disableClose: false,
      width: this.isSmallScreen ? '90vw' : '50vw',
    });
  }

  openUpdateClientDialog(clientConfig: ClientConfigWithTemplate) {
    this.updateClientDialogRef = this.dialog.open(UpdateClientDialogComponent, {
      disableClose: true,
      width: this.isSmallScreen ? '90vw' : '60vw',
      data: {
        clientConfig: clientConfig,
        templates: this.templates,
      },
    });

    this.updateClientDialogRef.afterClosed().subscribe((result: UpdateClientResponse) => {
      if (result?.PK) {
        this.handleUpdateClientDialogCloseEvent(result);
      }
    });
  }

  openUpdateTemplateDialog(templateConfig: TemplateConfig) {
    this.updateTemplateDialogRef = this.dialog.open(UpdateTemplateDialogComponent, {
      disableClose: true,
      width: this.isSmallScreen ? '90vw' : '40vw',
      data: templateConfig,
    });

    this.updateTemplateDialogRef.afterClosed().subscribe((result: UpdateTemplateResponse) => {
      if (result?.PK) {
        this.handleUpdateTemplateDialogCloseEvent(result);
      }
    });
  }

  openDeleteClientDialog(clientConfig: ClientConfigWithTemplate) {
    this.deleteClientDialogRef = this.dialog.open(DeleteClientDialogComponent, {
      disableClose: true,
      width: this.isSmallScreen ? '90vw' : '50em',
      data: clientConfig,
    });

    this.deleteClientDialogRef.afterClosed().subscribe((result: DeleteClientResponse) => {
      if (result?.PK) {
        this.handleDeleteClientDialogCloseEvent(result);
      }
    });
  }

  openDeleteTemplateDialog(templateConfig: TemplateConfig) {
    this.deleteTemplateDialogRef = this.dialog.open(DeleteTemplateDialogComponent, {
      disableClose: true,
      width: this.isSmallScreen ? '90vw' : '50em',
      data: templateConfig,
    });

    this.deleteTemplateDialogRef.afterClosed().subscribe((result: DeleteTemplateResponse) => {
      if (result?.PK) {
        this.handleDeleteTemplateDialogCloseEvent(result);
      }
    });
  }

  private handleCreateProjectDialogCloseEvent(project: Project) {
    this.projectService.$items.next([...this.projectService.$items.value, project]);
  }

  private handleCreateClientDialogCloseEvent(clientConfig: CreateClientResponse) {
    this.clientsWithTemplates.push(clientConfig);
  }

  private handleCreateTemplateDialogCloseEvent(newTemplateConfig: CreateTemplateResponse) {
    if (newTemplateConfig.isDefault) {
      this.templates.forEach((templateConfig) => {
        if (templateConfig.SK !== newTemplateConfig.SK) templateConfig.isDefault = false;
      });
    }
    this.templates.push(newTemplateConfig);
  }

  private handleUpdateClientDialogCloseEvent(response: UpdateClientResponse) {
    const client = this.clientsWithTemplates.find((client) => {
      return client?.SK === response.SK;
    });
    if (client) {
      client.name = response.name;
      client.description = response.description;
      client.openAiModelId = response.openAiModelId;
      client.template = response.template;
    }
  }

  private handleUpdateTemplateDialogCloseEvent(response: UpdateTemplateResponse) {
    const template = this.templates.find((templateConfig) => templateConfig.SK === response.SK);
    if (template) {
      template.name = response.name;
      template.template = response.template;
      template.isDefault = response.isDefault;
    }
    if (response.isDefault) {
      this.templates.forEach((templateConfig) => {
        if (templateConfig.SK !== response.SK) templateConfig.isDefault = false;
      });
    }
  }

  private handleDeleteClientDialogCloseEvent(deletedClient: DeleteClientResponse) {
    this.clientsWithTemplates = _.filter(this.clientsWithTemplates, (client) => {
      return client.SK !== deletedClient.SK;
    });
  }

  private handleDeleteTemplateDialogCloseEvent(deletedTemplate: DeleteTemplateResponse) {
    this.templates = _.filter(this.templates, (template) => {
      return template.SK !== deletedTemplate.SK;
    });

    this.clientsWithTemplates = this.clientsWithTemplates.map((client) => {
      if (client.template?.SK === deletedTemplate.SK) {
        return { ...client, template: undefined };
      } else {
        return client;
      }
    });
  }

  private handleUpdateProjectDialogCloseEvent(project: UpdateProjectResponse) {
    this.projectService
      .getProject(project.projectName)
      .subscribe((response: GetProjectResponse) => this.handleGetProjectResponse(response));
  }

  private handleDeleteProjectDialogCloseEvent(project: DeleteProjectResponse) {
    const projects = _.filter(this.projectService.$items.value, (p) => {
      return p.name !== project.projectName;
    });
    this.projectService.removeSelectedProjectFromLocalStorage();
    this.projectService.$items.next(projects);
    this.projectService.selectedProject.next(null);
    this.projectConfiguration = undefined;
  }

  private handleBreakpointChange(breakpoint: Breakpoint): void {
    this.isSmallScreen = breakpoint.Small || breakpoint.XSmall;
  }

  private handleGetProjectResponse(response: GetProjectResponse) {
    this.projectConfiguration = response;
    this.projectService.setProject(response.project);
    this.handleClientTemplateMapping();
  }
}
