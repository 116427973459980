import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import {
  CreateProjectRequest,
  CreateProjectResponse,
  DeleteProjectResponse,
  GetProjectResponse,
  Project,
  ProjectName,
  UpdateProjectRequest,
  UpdateProjectResponse,
} from '@types';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from '@shared/storage.service';
import { PaginatorService } from '@shared/paginator.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService extends PaginatorService<Project> {
  public selectedProject: BehaviorSubject<Project | null> = new BehaviorSubject<Project | null>(null);
  readonly selectedProjectStorageKey: string = 'selectedProject';

  constructor(protected override http: HttpClient, private storageService: LocalStorageService<Project | null>) {
    super(http, 19, 'PROJECT');
  }

  setProject(project: Project | null) {
    this.selectedProject.next(project);
    if (project) {
      this.storageService.setItem(this.selectedProjectStorageKey, project);
    }
  }

  getProjectFromLocalStorage(): Project | null {
    return this.storageService.getItem(this.selectedProjectStorageKey);
  }

  removeSelectedProjectFromLocalStorage(): void {
    return this.storageService.removeItem(this.selectedProjectStorageKey);
  }

  createProject(req: CreateProjectRequest): Observable<CreateProjectResponse> {
    return this.http.put<CreateProjectResponse>(`${environment.serverUrl}/project`, req);
  }

  updateProject(req: UpdateProjectRequest): Observable<UpdateProjectResponse> {
    return this.http.patch<UpdateProjectResponse>(`${environment.serverUrl}/project`, req);
  }

  getProject(projectName: ProjectName): Observable<GetProjectResponse> {
    return this.http.get<GetProjectResponse>(`${environment.serverUrl}/project/${projectName}`);
  }

  deleteProject(projectName: ProjectName): Observable<DeleteProjectResponse> {
    return this.http.delete<DeleteProjectResponse>(`${environment.serverUrl}/project/${projectName}`);
  }
}
