import { Injectable } from '@angular/core';
import { DatasetItem, SelectDatasetItemRequest, SelectDatasetItemResponse } from '@types';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { ProjectPaginatorService } from '@shared/project-paginator.service';
import { ProjectService } from '@shared/project.service';

@Injectable({
  providedIn: 'root',
})
export class DatasetItemService extends ProjectPaginatorService<DatasetItem> {
  constructor(protected override http: HttpClient, protected override projectService: ProjectService) {
    super(http, projectService, 10000, 'DATASET#');
  }

  selectDatasetItem(request: SelectDatasetItemRequest) {
    return this.http.post<SelectDatasetItemResponse>(`${environment.serverUrl}/dataset/item/select`, {
      project: this.project,
      ...request,
    });
  }
}
