<ng-container *ngIf="historyService.$items | async; else noHistoryItems">
  <mat-action-list class="history-items" [ngClass]="{ 'small-history-items': isSmallScreen }">
    <ng-container *ngFor="let item of historyService.$items | async; trackBy: identify">
      <app-history-item
        *ngIf="item.type !== HistoryItemType.MANUAL"
        [active]="selectedItem?.SK === item.SK"
        [prompt]="item.request.prompt"
        (click)="selectEvent.emit(item)"
      ></app-history-item>
    </ng-container>
  </mat-action-list>
  <mat-divider class="divider"></mat-divider>
  <div class="history-actions">
    <button class="history-action-button button-with-icon-reverse" mat-button (click)="getNextPage()">
      <mat-icon class="icon">arrow_forward</mat-icon>
      <span>Load more prompts</span>
    </button>
  </div>
</ng-container>
<ng-template #noHistoryItems>
  <p class="no-history-text">Your history is currently empty!</p>
</ng-template>
