import { ModelInfo } from '@types';

export const openaiModelsAdditionalData: Record<string, ModelInfo> = {
  ada: { maxToken: 2049 },
  'text-ada-001': { maxToken: 2049 },
  babbage: { maxToken: 2049 },
  'text-babbage-001': { maxToken: 2049 },
  curie: { maxToken: 2049 },
  'text-curie-001': { maxToken: 2049 },
  davinci: { maxToken: 2049 },
  'text-davinci-001': { maxToken: 2049 },
  'text-davinci-002': { maxToken: 4097 },
  'text-davinci-003': { maxToken: 4097 },
  'gpt-3.5-turbo': { maxToken: 4096 },
  'text-embedding-ada-002': { maxToken: 8191 },
  'text-similarity-ada-001': { maxToken: 2046 },
  'text-similarity-babbage-001': { maxToken: 2046 },
  'text-similarity-curie-001': { maxToken: 2046 },
  'text-similarity-davinci-001': { maxToken: 2046 },
  'text-search-ada-doc-001': { maxToken: 2046 },
  'text-search-ada-query-001': { maxToken: 2046 },
  'text-search-babbage-doc-001': { maxToken: 2046 },
  'text-search-babbage-query-001': { maxToken: 2046 },
  'text-search-curie-doc-001': { maxToken: 2046 },
  'text-search-curie-query-001': { maxToken: 2046 },
  'text-search-davinci-doc-001': { maxToken: 2046 },
  'text-search-davinci-query-001': { maxToken: 2046 },
  'code-search-ada-code-001': { maxToken: 2046 },
  'code-search-ada-text-001': { maxToken: 2046 },
  'code-search-babbage-code-001': { maxToken: 2046 },
  'code-search-babbage-text-001': { maxToken: 2046 },
};
