<form [formGroup]="filterFormGroup" class="table-actions-container" [ngClass]="{ 'small-screen': isSmallScreen }">
  <div
    class=""
    [ngClass]="{ 'small-table-actions-container-row': isSmallScreen, 'table-actions-container-row': !isSmallScreen }"
  >
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <input matInput [formControl]="filterFormGroup.controls.text" placeholder="Search..." />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <div class="buttons-container" [ngClass]="{ 'small-buttons-container': isSmallScreen }">
      <button
        (click)="deleteItem(selection.selected)"
        [disabled]="selection.selected.length === 0 || isAnySelectedFineTunePending(selection.selected)"
        mat-raised-button
        color="warn"
        type="button"
      >
        Delete model
        <mat-icon *ngIf="!isDeletionInProgress">delete_outline</mat-icon>
        <mat-icon *ngIf="isDeletionInProgress" class="spin">sync</mat-icon>
      </button>
      <button
        (click)="cancelFineTune(selection.selected)"
        [disabled]="selection.selected.length === 0 || !isAllSelectedFineTunePending(selection.selected)"
        mat-raised-button
        color="warn"
        type="button"
      >
        Cancel Fine-tune
        <mat-icon *ngIf="!isCancelInProgress">close</mat-icon>
        <mat-icon *ngIf="isCancelInProgress" class="spin">sync</mat-icon>
      </button>
      <button (click)="openCreateFineTuneDialog()" mat-raised-button color="primary" type="button">
        <mat-icon>play_arrow</mat-icon>
        Start Fine-tune
      </button>
    </div>
  </div>
  <div
    class="selects-row"
    [ngClass]="{ 'small-selects-rows': isSmallScreen, 'table-actions-container-row': !isSmallScreen }"
  >
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>Created at date range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input
          [formControl]="filterFormGroup.controls.createdAtDateRange.controls.start"
          matStartDate
          placeholder="Start date"
        />
        <input
          [formControl]="filterFormGroup.controls.createdAtDateRange.controls.end"
          matEndDate
          placeholder="End date"
        />
      </mat-date-range-input>
      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>Status</mat-label>
      <mat-select [formControl]="filterFormGroup.controls.status">
        <mat-option>Clear</mat-option>
        <mat-option [value]="FineTuneStatus.FAILED">Failed</mat-option>
        <mat-option [value]="FineTuneStatus.SUCCEEDED">Succeeded</mat-option>
        <mat-option [value]="FineTuneStatus.CANCELLED">Cancelled</mat-option>
        <mat-option [value]="FineTuneStatus.PENDING">Pending</mat-option>
        <mat-option [value]="FineTuneStatus.RUNNING">Running</mat-option>
      </mat-select>
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="fill" class="mat-input-and-select">
      <mat-label>Usable for prompting?</mat-label>
      <mat-select [formControl]="filterFormGroup.controls.usableForPrompting">
        <mat-option [value]="null">Clear</mat-option>
        <mat-option [value]="true">Yes</mat-option>
        <mat-option [value]="false">No</mat-option>
      </mat-select>
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
    </mat-form-field>
  </div>
</form>
<div class="mat-elevation-z4 table-body-container">
  <table
    #table
    matSort
    mat-table
    [dataSource]="rows"
    (matSortChange)="sortData($event)"
    matSortActive="createdAt"
    matSortDirection="desc"
  >
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          *ngIf="
            row?.fineTunedModel || row?.status === FineTuneStatus.PENDING || row?.status === FineTuneStatus.RUNNING
          "
          color="primary"
          (click)="$event.stopPropagation()"
          (change)="handleRowSelectCheckboxEvent($event, row)"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Created At</th>
      <td mat-cell *matCellDef="let element" class="date-time">
        {{ element.createdAt | date : 'MMM d, y, H:mm' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="model">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Model</th>
      <td mat-cell *matCellDef="let element">
        {{ element.model }}
      </td>
    </ng-container>

    <ng-container matColumnDef="fineTunedModel">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Fine Tuned Model</th>
      <td mat-cell *matCellDef="let element">
        {{ element.fineTunedModel }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">
        <div class="chip green-border" *ngIf="element.status === FineTuneStatus.SUCCEEDED">
          <mat-icon class="approved-and-succeeded-color chip-icon">check</mat-icon>
          {{ element.status | titlecase }}
        </div>
        <div class="chip red-border" *ngIf="element.status === FineTuneStatus.FAILED">
          <mat-icon class="rejected-and-failed-color chip-icon">close</mat-icon>
          {{ element.status | titlecase }}
        </div>
        <div class="chip yellow-border" *ngIf="element.status === FineTuneStatus.PENDING">
          <mat-icon class="pending-yellow-color chip-icon">access_time</mat-icon>
          {{ element.status | titlecase }}
        </div>
        <div class="chip blue-border" *ngIf="element.status === FineTuneStatus.RUNNING">
          <mat-icon
            fontSet="material-icons-outlined"
            class="material-symbols-outlined running-color chip-icon send-icon"
            >send
          </mat-icon>
          {{ element.status | titlecase }}
        </div>
        <div class="chip dark-border" *ngIf="element.status === FineTuneStatus.CANCELLED">
          <mat-icon class="cancelled-color chip-icon">cancel_outline</mat-icon>
          {{ element.status | titlecase }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="canItBeUsedForPrompting">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Promptability</th>
      <td mat-cell *matCellDef="let element">
        <div class="chip dark-border" *ngIf="!element.canItBeUsedForPrompting">No</div>
        <div class="chip green-border" *ngIf="element.canItBeUsedForPrompting">Yes</div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; let i = index; columns: columns"
      (click)="handleRowClick(row)"
      [ngClass]="{ 'row-selected': row.data.id === this.selectedRowId }"
    ></tr>
    <tr class="no-data-row" *matNoDataRow>
      <td class="no-data-row" [attr.colspan]="columns.length">No data matching the filter.</td>
    </tr>
  </table>
  <mat-paginator
    class="paginator"
    [pageSizeOptions]="[20, 50, 100, 200]"
    aria-label="Select page of items"
  ></mat-paginator>
</div>
