import { Component, Input } from '@angular/core';
import { Segment } from 'sql-highlight';

@Component({
  selector: 'app-sql-highlight',
  templateUrl: './sql-highlight.component.html',
  styleUrls: ['./sql-highlight.component.scss'],
})
export class SqlHighlightComponent {
  @Input() segments: Segment[] = [];
  @Input() background: Segment[] = [];
}
