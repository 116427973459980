import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CloneDatasetRequest,
  CloneDatasetResponse,
  CreateDatasetRequest,
  CreateDatasetResponse,
  Dataset,
  RefreshDatasetRequest,
  RefreshDatasetResponse,
} from '@types';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { ProjectPaginatorService } from '@shared/project-paginator.service';
import { ProjectService } from '@shared/project.service';

@Injectable({
  providedIn: 'root',
})
export class DatasetService extends ProjectPaginatorService<Dataset> {
  constructor(protected override http: HttpClient, protected override projectService: ProjectService) {
    super(http, projectService, 19, 'PROJECTDATASET#${projectName}');
  }

  saveDataset(request: CreateDatasetRequest): Observable<CreateDatasetResponse> {
    return this.http.post<CreateDatasetResponse>(`${environment.serverUrl}/dataset/save`, {
      project: this.project,
      ...request,
    });
  }

  cloneDataset(request?: CloneDatasetRequest): Observable<CloneDatasetResponse> {
    return this.http.post<CreateDatasetResponse>(`${environment.serverUrl}/dataset/clone`, {
      project: this.project,
      ...request,
    });
  }

  refreshDataset(request?: RefreshDatasetRequest): Observable<RefreshDatasetResponse> {
    return this.http.post<RefreshDatasetResponse>(`${environment.serverUrl}/dataset/refresh`, {
      project: this.project,
      ...request,
    });
  }
}
