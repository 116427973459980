import { Component } from '@angular/core';
import { CreateProjectForm, CreateProjectRequest, CreateProjectResponse } from '@types';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectService } from '@shared/project.service';
import { finalize } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomValidator } from '@shared';

@Component({
  selector: 'app-create-project-dialog',
  templateUrl: './create-project-dialog.component.html',
  styleUrls: ['./create-project-dialog.component.scss'],
})
export class CreateProjectDialogComponent {
  form: FormGroup<CreateProjectForm>;
  isLoading: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<CreateProjectDialogComponent, CreateProjectResponse | null>,
    private snackBar: MatSnackBar,
    private projectService: ProjectService
  ) {
    this.form = new FormGroup<CreateProjectForm>({
      dbPassword: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
      dbUrl: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
      dbName: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
      dbUsername: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
      openaiApiKey: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
      project: new FormControl('', {
        nonNullable: true,
        validators: [
          Validators.required,
          CustomValidator.projectNamePatternValidator(),
          Validators.maxLength(30),
          Validators.minLength(3),
        ],
      }),
    });
  }

  submitForm() {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
    const projectCreateRequest: CreateProjectRequest = {
      dbPassword: this.form.controls.dbPassword.value,
      dbUrl: this.form.controls.dbUrl.value,
      dbName: this.form.controls.dbName.value,
      dbUsername: this.form.controls.dbUsername.value,
      openaiApiKey: this.form.controls.openaiApiKey.value,
      project: this.form.controls.project.value,
    };
    this.projectService
      .createProject(projectCreateRequest)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((result) => this.handleCreateProjectResponse(result));
  }

  private handleCreateProjectResponse(response: CreateProjectResponse) {
    this.snackBar.open('The project has been successfully created.', 'Close', { duration: 3000 });
    this.dialogRef.close(response);
  }

  closeDialog() {
    this.dialogRef.close(null);
  }
}
