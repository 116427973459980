<form class="table-actions-container" [ngClass]="{ 'small-screen': isSmallScreen }" [formGroup]="filterFormGroup">
  <div
    class=""
    [ngClass]="{ 'small-table-actions-container-row': isSmallScreen, 'table-actions-container-row': !isSmallScreen }"
  >
    <mat-form-field class="mat-input-and-select">
      <input matInput [formControl]="filterFormGroup.controls.text" placeholder="Search..." />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <div class="buttons-container" [ngClass]="{ 'small-screen': isSmallScreen }">
      <div>
        <span *ngIf="selection.selected.length > 0">{{ selection.selected.length + ' item(s) selected' }}</span>
      </div>
      <button
        (click)="deleteItems(selection.selected)"
        [disabled]="selection.selected.length === 0"
        mat-raised-button
        type="button"
        color="warn"
      >
        Delete row(s)
        <mat-icon *ngIf="!isDeletionInProgress">delete_outline</mat-icon>
        <mat-icon *ngIf="isDeletionInProgress" class="spin">sync</mat-icon>
      </button>
      <button type="button" (click)="openNewReviewItemDialog()" mat-raised-button color="accent">
        <mat-icon>add</mat-icon>
        New item
      </button>
    </div>
  </div>
  <div
    class=""
    [ngClass]="{ 'small-table-actions-container-row': isSmallScreen, 'table-actions-container-row': !isSmallScreen }"
  >
    <mat-form-field class="mat-input-and-select filter" [ngClass]="{ 'small-filter': isSmallScreen }" appearance="fill">
      <mat-label>Prompt created date range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input
          [formControl]="filterFormGroup.controls.promptCreatedDateRange.controls.start"
          matStartDate
          placeholder="Start date"
        />
        <input
          [formControl]="filterFormGroup.controls.promptCreatedDateRange.controls.end"
          matEndDate
          placeholder="End date"
        />
      </mat-date-range-input>
      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <mat-form-field class="mat-input-and-select filter" [ngClass]="{ 'small-filter': isSmallScreen }" appearance="fill">
      <mat-label>SQL Status</mat-label>
      <mat-select [formControl]="filterFormGroup.controls.generatedSqlStatus">
        <mat-option>Clear</mat-option>
        <mat-option [value]="ReviewStatus.MARKED_FOR_FINE_TUNE">Approved</mat-option>
        <mat-option [value]="ReviewStatus.REJECTED">Rejected</mat-option>
        <mat-option [value]="ReviewStatus.PENDING">Pending</mat-option>
      </mat-select>
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
    </mat-form-field>
    <mat-form-field class="mat-input-and-select filter" [ngClass]="{ 'small-filter': isSmallScreen }" appearance="fill">
      <mat-label>Answer Status</mat-label>
      <mat-select [formControl]="filterFormGroup.controls.generatedAnswerStatus">
        <mat-option>Clear</mat-option>
        <mat-option [value]="ReviewStatus.MARKED_FOR_FINE_TUNE">Approved</mat-option>
        <mat-option [value]="ReviewStatus.REJECTED">Rejected</mat-option>
        <mat-option [value]="ReviewStatus.PENDING">Pending</mat-option>
      </mat-select>
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
    </mat-form-field>
    <mat-form-field class="mat-input-and-select filter" [ngClass]="{ 'small-filter': isSmallScreen }" appearance="fill">
      <mat-label>Source</mat-label>
      <mat-select [formControl]="filterFormGroup.controls.type">
        <mat-option>Clear</mat-option>
        <mat-option [value]="HistoryItemType.MANUAL">Manual</mat-option>
        <mat-option [value]="HistoryItemType.PROMPT">Prompt</mat-option>
      </mat-select>
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
    </mat-form-field>
    <mat-form-field class="mat-input-and-select filter" [ngClass]="{ 'small-filter': isSmallScreen }" appearance="fill">
      <mat-label>Can SQL be executed?</mat-label>
      <mat-select [formControl]="filterFormGroup.controls.executableSql">
        <mat-option>Clear</mat-option>
        <mat-option [value]="true">Yes</mat-option>
        <mat-option [value]="false">No</mat-option>
      </mat-select>
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
    </mat-form-field>
  </div>
</form>

<div class="mat-elevation-z4 table-body-container">
  <table matSort mat-table [dataSource]="rows" (matSortChange)="sortData($event)">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          color="primary"
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          color="primary"
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Request Column -->
    <ng-container matColumnDef="request">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Request</th>
      <td mat-cell *matCellDef="let element">
        {{ element.request }}
      </td>
    </ng-container>

    <!-- Response Column when model is TEXT_TO_SQL -->
    <ng-container matColumnDef="response">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Response</th>
      <td mat-cell *matCellDef="let element">
        {{ element.response }}
      </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="requestDateTime">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Created</th>
      <td mat-cell *matCellDef="let element" class="date-time">
        {{ element.requestDateTime | date : 'MMM d, y, H:mm' }}
      </td>
    </ng-container>

    <!-- Generated SQL review status Column -->
    <ng-container matColumnDef="generatedSqlStatus">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>SQL Review Status</th>
      <td mat-cell *matCellDef="let element">
        <div class="chip" *ngIf="element.generatedSqlStatus === ReviewStatus.MARKED_FOR_FINE_TUNE">
          <mat-icon class="approved-and-succeeded-color chip-icon">check</mat-icon>
          Approved
        </div>
        <div class="chip" *ngIf="element.generatedSqlStatus === ReviewStatus.REJECTED">
          <mat-icon class="rejected-and-failed-color chip-icon">close</mat-icon>
          Rejected
        </div>
        <div
          class="chip grey-border"
          *ngIf="element.generatedSqlStatus === ReviewStatus.PENDING || element.generatedSqlStatus === ''"
        >
          <mat-icon class="pending-grey-color chip-icon">access_time</mat-icon>
          Pending
        </div>
      </td>
    </ng-container>

    <!-- Generated Answer review status Column -->
    <ng-container matColumnDef="generatedAnswerStatus">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Answer Review Status</th>
      <td mat-cell *matCellDef="let element">
        <div class="chip" *ngIf="element.generatedAnswerStatus === ReviewStatus.MARKED_FOR_FINE_TUNE">
          <mat-icon class="approved-and-succeeded-color chip-icon">check</mat-icon>
          Approved
        </div>
        <div class="chip" *ngIf="element.generatedAnswerStatus === ReviewStatus.REJECTED">
          <mat-icon class="rejected-and-failed-color chip-icon">close</mat-icon>
          Rejected
        </div>
        <div
          class="chip grey-border"
          *ngIf="element.generatedAnswerStatus === ReviewStatus.PENDING || element.generatedSqlStatus === ''"
        >
          <mat-icon class="pending-grey-color chip-icon">access_time</mat-icon>
          Pending
        </div>
      </td>
    </ng-container>

    <!-- Comment Column -->
    <ng-container matColumnDef="comment">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Comment</th>
      <td mat-cell *matCellDef="let element">{{ element.comment }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columns"
      (click)="handleRowClick(row)"
      [ngClass]="{
        'light-green-background': row.executableSql,
        'row-selected': activeRow?.SK === row.data.SK && !row.executableSql,
        'green-row-selected': activeRow?.SK === row.data.SK && row.executableSql
      }"
    ></tr>
    <tr class="no-data-row" *matNoDataRow>
      <td class="no-data-row" [attr.colspan]="columns.length">No data matching the filter.</td>
    </tr>
  </table>
  <mat-paginator
    class="paginator"
    [pageSizeOptions]="[20, 50, 100, 200]"
    aria-label="Select page of items"
  ></mat-paginator>
</div>
