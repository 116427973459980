import { Model } from '@types';
import { openaiModelsAdditionalData } from '@const/openai-models-additional-data.const';
import { defaultModelMaxToken } from '@const/default-model-max.token';

const defaultModelId: string = 'gpt-3.5-turbo';

export const defaultModel: Model = {
  model: {
    id: defaultModelId,
    maxToken: getMaxToken(),
  },
  max_tokens: 1000,
  n: 1,
  top_p: 0,
  temperature: 0.1,
};

function getMaxToken(): number | undefined {
  if (defaultModelId && defaultModelId in openaiModelsAdditionalData) {
    return openaiModelsAdditionalData[defaultModelId].maxToken;
  }
  return defaultModelMaxToken;
}
