<div>
  <div class="label-wrapper">
    <div class="left-side">
      <label class="label">{{ name }}</label>
      <sup>
        <mat-icon
          class="info-icon"
          [matTooltip]="'model.tooltip.' + name | translate"
          matTooltipPosition="above"
          matTooltipHideDelay="200"
        >
          info_outline
        </mat-icon>
      </sup>
    </div>
    <label class="label">{{ control.value }}</label>
  </div>
  <mat-slider color="accent" class="slider" [min]="min" [max]="max" [step]="step" showTickMarks="false">
    <input matSliderThumb [formControl]="control" />
  </mat-slider>
</div>
