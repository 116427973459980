import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { LastEvaluatedKey, PageableRequest, PageableResponse, PK } from '@types';
import { environment } from '@env/environment';
import * as _ from 'lodash';

export class PaginatorService<T extends PK> {
  public pageSize: number;
  public readonly PK: T['PK'];
  public $items: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  public lastEvaluatedKey?: LastEvaluatedKey;

  constructor(protected http: HttpClient, pageSize: number, PK: T['PK']) {
    this.pageSize = pageSize;
    this.PK = PK;
  }

  /**
   * Get page of items
   * @param request PageableRequest
   * @returns Observable<PageableResponse<T>> Page of items
   */
  getPage(request?: PageableRequest<T>): Observable<PageableResponse<T>> {
    return this.http
      .post<PageableResponse<T>>(`${environment.serverUrl}/page`, {
        pageSize: this.pageSize,
        PK: this.PK,
        ...request,
      })
      .pipe(
        tap((page: PageableResponse<T>): void => {
          const items: T[] = _.unionBy<T>(this.$items.getValue(), page.items, 'SK');
          this.$items.next(_.orderBy(items, 'SK', 'desc'));
          this.lastEvaluatedKey = page.lastEvaluatedKey;
        })
      );
  }

  /**
   * Get next page of items
   * @returns void
   */
  loadNextPage(): void {
    if (this.lastEvaluatedKey) {
      this.getPage({
        pageSize: this.pageSize,
        PK: this.PK,
        lastEvaluatedKey: this.lastEvaluatedKey,
      }).subscribe(() => {});
    }
  }
}
