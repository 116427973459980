import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidator {
  static noneOf(array: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      const valid = !array.some((item) => item === value);

      return !valid ? { alreadyExists: true } : null;
    };
  }

  static projectNamePatternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: { message: string } } | null => {
      if (!new RegExp('^(?!xn--)').test(control.value)) {
        return {
          patternError: {
            message: `Cannot start with 'xn--'!`,
          },
        };
      }

      if (control.value.endsWith('-s3alias')) {
        return {
          patternError: {
            message: `Cannot end with '-s3alias'!`,
          },
        };
      }

      if (!new RegExp('^[a-z]').test(control.value)) {
        return {
          patternError: {
            message: `Needs to start with a lowercase letter!`,
          },
        };
      }

      if (!new RegExp('[a-z]$').test(control.value)) {
        return {
          patternError: {
            message: `Needs to end with a lowercase letter!`,
          },
        };
      }

      if (!new RegExp('^[a-z0-9-]{1}[a-z0-9-]*[a-z0-9-]{1}$').test(control.value)) {
        return {
          patternError: {
            message: `Only can contain lowercase letters, numbers and hyphens`,
          },
        };
      }

      return null;
    };
  }
}
