<h2 mat-dialog-title>Client API Usage Guide</h2>
<mat-card>
  <mat-card-content class="content">
    <table>
      <tbody>
        <tr>
          <td>API Endpoint</td>
          <td>{{ clientApiUrl }}/prompt</td>
        </tr>
        <tr>
          <td>HTTP Method</td>
          <td>POST</td>
        </tr>
        <tr>
          <td>API Key</td>
          <td>The API key should be provided in the "x-api-key" header</td>
        </tr>
        <tr>
          <td>Request Payload</td>
          <td>
            <app-json-viewer [data]="{ prompt: 'Example prompt' }"></app-json-viewer>
          </td>
        </tr>
        <tr>
          <td>Response Format</td>
          <td>
            <app-json-viewer [data]="{ answer: 'Example answer' }"></app-json-viewer>
          </td>
        </tr>
      </tbody>
    </table>

    <p>Step-by-step guide on how to use the Client API:</p>

    <ol>
      <li>Obtain the API endpoint URL provided above.</li>
      <li>
        Make sure you have the API key required to access the API. Include the API key in the request headers using the
        "x-api-key" key.
      </li>
      <li>Construct an HTTP POST request to the API endpoint.</li>
      <li>
        Set the request payload to a JSON object with a "prompt" field. Replace "string" in the JSON format with your
        desired prompt for the API.
      </li>
      <li>Send the HTTP POST request to the API endpoint with the request payload.</li>
      <li>The API will process your request and generate a response.</li>
      <li>Extract the "answer" field from the response to obtain the generated answer.</li>
    </ol>
    <h4>Example CURL Command:</h4>
    <pre>
curl -X POST \
  -H "x-api-key: YOUR_API_KEY" \
  -H "Content-Type: application/json" \
  -d '{{ '{' }}
      "prompt": "Your prompt goes here"
      {{ '}' }}' \
      {{ clientApiUrl }}/prompt
    </pre>
  </mat-card-content>
</mat-card>
