import { Component, NgZone, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CreateTemplateForm, CreateTemplateRequest, CreateTemplateResponse } from '@types';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators';
import { TemplateConfigService } from '@shared/template-config.service';
import { take } from 'rxjs';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

@Component({
  selector: 'app-create-template-dialog',
  templateUrl: './create-template-dialog.component.html',
  styleUrls: ['./create-template-dialog.component.scss'],
})
export class CreateTemplateDialogComponent {
  form: FormGroup<CreateTemplateForm>;
  isLoading: boolean = false;
  @ViewChild('autosize') autosize?: CdkTextareaAutosize;
  constructor(
    private dialogRef: MatDialogRef<CreateTemplateDialogComponent, CreateTemplateResponse | null>,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private templateConfigService: TemplateConfigService
  ) {
    this.form = new FormGroup<CreateTemplateForm>({
      name: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
      template: new FormControl('{question}', { nonNullable: true, validators: [Validators.required] }),
      isDefault: new FormControl<boolean>(false, { nonNullable: true }),
    });
    this.ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize?.resizeToFitContent(true));
  }

  submitForm() {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
    const createTemplateRequest: CreateTemplateRequest = {
      name: this.form.controls.name.value,
      template: this.form.controls.template.value,
      isDefault: this.form.controls.isDefault.value,
    };
    this.templateConfigService
      .createTemplateConfig(createTemplateRequest)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((result) => this.handleCreateTemplateResponse(result));
  }

  private handleCreateTemplateResponse(response: CreateTemplateResponse) {
    this.snackBar.open('The new template has been successfully created.', 'Close', { duration: 3000 });
    this.dialogRef.close(response);
  }

  closeDialog() {
    this.dialogRef.close(null);
  }
}
