<mat-drawer-container autosize [hasBackdrop]="isSmallScreen">
  <mat-drawer
    #drawerRight
    [ngClass]="{ sidenav: true, smallScreen: isSmallScreen, 'dataset-item-sidenav': true }"
    [mode]="'over'"
    position="end"
    autoFocus="false"
  >
    <div class="right-sidenav-title-container">
      <button
        mat-icon-button
        title="right sidenav close button"
        (click)="closeRightSidenav()"
        type="button"
        class="right-side-toggle-btn"
        color="accent"
      >
        <mat-icon class="material-symbols-outlined" fontSet="material-icons-outlined"> arrow_circle_right</mat-icon>
      </button>
      <h3 class="right-sidenav-title">Request / Response Information</h3>
    </div>
    <div class="right-sidenav-body-container">
      <div *ngIf="!!selectedRow?.request?.prompt?.length" class="right-sidenav-request-container">
        <div class="status-select-and-title">
          <div class="title">
            <h2>Text to SQL</h2>
            <p class="title-description">Converts a free text question into an executable SQL query</p>
          </div>
          <mat-form-field appearance="fill" class="mat-input-and-select">
            <mat-label>Set SQL status</mat-label>
            <mat-select [formControl]="textToSQLReviewStatus">
              <mat-option [value]="ReviewStatus.PENDING">Pending</mat-option>
              <mat-option [value]="ReviewStatus.MARKED_FOR_FINE_TUNE">Approve SQL</mat-option>
              <mat-option [value]="ReviewStatus.REJECTED">Reject SQL</mat-option>
            </mat-select>
            <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          </mat-form-field>
        </div>
        <app-text-to-sql-request-info [data]="selectedRow" />
        <div class="sql-result-table">
          <app-sql-result-table
            [sk]="selectedRow?.SK"
            [data]="selectedRow?.response?.query_result"
            *ngIf="selectedRow?.response?.query_result?.length"
          />
        </div>
      </div>
      <div *ngIf="selectedRow?.response?.answer?.length">
        <div class="status-select-and-title">
          <div class="title">
            <h2>SQL Result to text</h2>
            <p class="title-description">Summarizes the result of SQL textually.</p>
          </div>
          <mat-form-field appearance="fill" class="mat-input-and-select status-select">
            <mat-label>Set answer status</mat-label>
            <mat-select [formControl]="answerReviewStatus">
              <mat-option [value]="ReviewStatus.PENDING">Pending</mat-option>
              <mat-option [value]="ReviewStatus.MARKED_FOR_FINE_TUNE">Approve answer</mat-option>
              <mat-option [value]="ReviewStatus.REJECTED">Reject answer</mat-option>
            </mat-select>
            <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          </mat-form-field>
        </div>
        <app-sql-to-text-request-info [data]="selectedRow" />
      </div>
    </div>
  </mat-drawer>
  <div class="container" [ngClass]="{ 'small-container': isSmallScreen }">
    <app-review-list #reviewList [activeRow]="selectedRow" (rowSelectEvent)="handleTableRowSelect($event)" />
  </div>
</mat-drawer-container>
