import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { AuthGuard } from '@shared/guard/auth.guard';
import { ProjectGuard } from '@shared/guard/project.guard';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'projects',
      loadChildren: () => import('./projects/projects.module').then((m) => m.ProjectsModule),
      canActivate: [AuthGuard],
    },
    {
      path: ':project/prompt',
      loadChildren: () => import('./prompt/prompt.module').then((m) => m.PromptModule),
      canActivate: [AuthGuard, ProjectGuard],
    },
    {
      path: ':project/review',
      loadChildren: () => import('./review/review.module').then((m) => m.ReviewModule),
      canActivate: [AuthGuard, ProjectGuard],
    },
    {
      path: ':project/datasets',
      loadChildren: () => import('./datasets/datasets.module').then((m) => m.DatasetsModule),
      canActivate: [AuthGuard, ProjectGuard],
    },
    {
      path: ':project/fine-tune',
      loadChildren: () => import('./fine-tune/fine-tune.module').then((m) => m.FineTuneModule),
      canActivate: [AuthGuard, ProjectGuard],
    },
    {
      path: ':project',
      redirectTo: ':project/prompt',
      pathMatch: 'full',
    },
  ]),
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
