<mat-toolbar class="toolbar">
  <mat-toolbar-row class="first-toolbar">
    <div *ngIf="isSmallScreen; then smallScreen; else bigScreen"></div>
  </mat-toolbar-row>
  <mat-toolbar-row class="divider-toolbar">
    <div class="divider-toolbar-div"></div>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="notOnProjectsPage && !isSmallScreen" class="second-toolbar">
    <div class="menu-items" *ngIf="this.activeProject">
      <a [routerLink]="[this.activeProject.name, 'prompt']" routerLinkActive="active"><span>Prompt</span> </a>
      <a [routerLink]="[this.activeProject.name, 'review']" routerLinkActive="active"><span>Review</span></a>
      <a [routerLink]="[this.activeProject.name, 'datasets']" routerLinkActive="active"><span>Datasets</span></a>
      <a [routerLink]="[this.activeProject.name, 'fine-tune']" routerLinkActive="active"><span>Fine-Tune</span></a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<ng-template #navigationItemsSmallScreen>
  <mat-nav-list class="small-menu-items">
    <div *ngIf="this.activeProject">
      <a [routerLink]="[this.activeProject.name, 'prompt']" routerLinkActive="active" mat-list-item>
        <span matListItemTitle>Prompt</span>
      </a>
      <a [routerLink]="[this.activeProject.name, 'review']" routerLinkActive="active" mat-list-item>
        <span matListItemTitle>Review</span>
      </a>

      <a [routerLink]="[this.activeProject.name, 'datasets']" routerLinkActive="active" mat-list-item>
        <span matListItemTitle>Datasets</span>
      </a>
      <a [routerLink]="[this.activeProject.name, 'fine-tune']" routerLinkActive="active" mat-list-item>
        <span matListItemTitle>Fine-tune</span>
      </a>
    </div>
    <a routerLink="/projects" routerLinkActive="active" mat-list-item>
      <span matListItemTitle>Projects</span>
    </a>
    <a (click)="logout()" mat-list-item>
      <span matListItemTitle>Logout</span>
    </a>
  </mat-nav-list>
</ng-template>
<ng-template #navigationItemsBigScreen>
  <div class="account">
    <a mat-button routerLink="/projects" routerLinkActive="active">Projects</a>
    <a *ngIf="this.activeProject?.name" mat-button [routerLink]="[this.activeProject?.name]" routerLinkActive="active">
      Prompt
    </a>
    <a mat-button (click)="logout()">Logout</a>
  </div>
</ng-template>

<ng-template #smallScreen>
  <button mat-fab color="primary" [matMenuTriggerFor]="beforeMenu" aria-label="menu icon" class="menu-button">
    <mat-icon>menu</mat-icon>
  </button>
  <div class="title">
    dataTalk<span *ngIf="this.activeProject">::{{ this.activeProject.name }}</span>
  </div>
  <mat-menu #beforeMenu="matMenu" xPosition="before" class="menu">
    <ng-container *ngTemplateOutlet="navigationItemsSmallScreen"></ng-container>
  </mat-menu>
</ng-template>

<ng-template #bigScreen>
  <div class="title">
    dataTalk<span *ngIf="this.activeProject">::{{ this.activeProject.name }}</span>
  </div>
  <ng-container *ngTemplateOutlet="navigationItemsBigScreen"></ng-container>
</ng-template>
