<h2 mat-dialog-title class="title">{{ data.title }}</h2>
<form [formGroup]="form" (ngSubmit)="submitForm()">
  <mat-dialog-content class="dialog-content">
    <mat-form-field class="form-item mat-input-and-select">
      <mat-label>Template</mat-label>
      <mat-select [compareWith]="compareFn" [formControl]="templateSelector">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let templateConfig of templateConfigService.$items | async" [value]="templateConfig">
          {{ templateConfig.name }} {{ templateConfig.isDefault ? '(default)' : '' }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
    </mat-form-field>
    <textarea
      matInput
      class="template-textarea"
      cdkTextareaAutosize
      [formControl]="form.controls.template"
      cdkAutosizeMinRows="5"
      cdkAutosizeMaxRows="20"
    >
    </textarea>
    <mat-hint>{{ 'template.textToSqlTemplateHint' | translate }}</mat-hint>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="action">
    <button mat-raised-button [mat-dialog-close]="null" (click)="cancel()">
      <mat-icon>close</mat-icon>
      Cancel
    </button>
    <button mat-raised-button [mat-dialog-close]="null" color="primary" type="submit">
      <mat-icon fontSet="material-icons-outlined">send</mat-icon>
      Update
    </button>
  </mat-dialog-actions>
</form>
