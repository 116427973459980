import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatasetService } from '@shared/dataset.service';
import {
  CloneDatasetRequest,
  CreateDatasetDialogCloseEvent,
  CreateDatasetRequest,
  Dataset,
  DatasetCreateForm,
  ModelGroup,
} from '@types';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators';
import { CustomValidator } from '@shared';

@Component({
  selector: 'app-create-dataset-dialog',
  templateUrl: './create-dataset-dialog.component.html',
  styleUrls: ['./create-dataset-dialog.component.scss'],
})
export class CreateDatasetDialogComponent implements OnInit {
  newDatasetFormGroup: FormGroup<DatasetCreateForm>;
  ModelGroup = ModelGroup;
  isDatasetCreationInProgress: boolean = false;

  constructor(
    public datasetService: DatasetService,
    private dialogRef: MatDialogRef<CreateDatasetDialogComponent, CreateDatasetDialogCloseEvent>,
    private _snackBar: MatSnackBar
  ) {
    this.newDatasetFormGroup = new FormGroup<DatasetCreateForm>({
      name: new FormControl<string>('', {
        nonNullable: true,
        validators: [
          Validators.required,
          CustomValidator.noneOf(
            this.datasetService.$items.value.map((item) => {
              return item.name;
            })
          ),
        ],
      }),
      useExisting: new FormControl<boolean>(false, { nonNullable: true }),
      datasetToClone: new FormControl<Dataset | null>(
        {
          value: null,
          disabled: true,
        },
        { validators: [Validators.required] }
      ),
      model: new FormControl<ModelGroup | null>(
        { value: null, disabled: false },
        {
          validators: [Validators.required],
        }
      ),
    });
  }

  ngOnInit() {
    this.newDatasetFormGroup.controls.useExisting.valueChanges.subscribe((value) => {
      this.enableOrDisableFormControls(value);
    });
  }

  private enableOrDisableFormControls(value: boolean) {
    if (value) {
      this.newDatasetFormGroup.controls.model.disable();
      this.newDatasetFormGroup.controls.datasetToClone.enable();
    } else {
      this.newDatasetFormGroup.controls.datasetToClone.disable();
      this.newDatasetFormGroup.controls.model.enable();
    }
  }

  submitForm() {
    if (this.newDatasetFormGroup.valid) {
      if (
        this.newDatasetFormGroup.controls.useExisting.value &&
        this.newDatasetFormGroup.controls.datasetToClone.value?.SK
      ) {
        const clonedDataset: CloneDatasetRequest = {
          name: this.newDatasetFormGroup.controls.name.value,
          clonedDatasetSK: this.newDatasetFormGroup.controls.datasetToClone.value?.SK,
          model: this.newDatasetFormGroup.controls.datasetToClone.value?.model,
        };
        this.cloneDataset(clonedDataset);
      } else if (this.newDatasetFormGroup.controls.model.value) {
        const newDataset: CreateDatasetRequest = {
          name: this.newDatasetFormGroup.controls.name.value,
          model: this.newDatasetFormGroup.controls.model.value,
        };
        this.createDataset(newDataset);
      }
    }
  }

  private createDataset(newDataset: CreateDatasetRequest) {
    this.isDatasetCreationInProgress = true;
    this.datasetService
      .saveDataset(newDataset)
      .pipe(
        finalize(() => {
          this.isDatasetCreationInProgress = false;
        })
      )
      .subscribe(() => {
        this.dialogRef.close({
          datasetName: this.newDatasetFormGroup.controls.name.value,
        });
      });
  }

  private cloneDataset(newDataset: CloneDatasetRequest) {
    this.isDatasetCreationInProgress = true;
    this.datasetService
      .cloneDataset(newDataset)
      .pipe(
        finalize(() => {
          this.isDatasetCreationInProgress = false;
        })
      )
      .subscribe(() => {
        this.dialogRef.close({
          datasetName: this.newDatasetFormGroup.controls.name.value,
        });
      });
  }

  closeDialog() {
    this.dialogRef.close(undefined);
  }
}
