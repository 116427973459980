import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDrawer } from '@angular/material/sidenav';
import { AiService } from '@shared/ai.service';
import {
  ExtendedModelData,
  HistoryItem,
  Model,
  ModelFormGroup,
  PromptForm,
  ReviewStatus,
  TemplateConfig,
  TextCompletionRequest,
  TextCompletionResponse,
} from '@types';
import { Observable, switchMap } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BreakpointService } from '@shared';
import { Breakpoint } from '../../classes/breakpoint.class';
import { defaultModel, sqlResultToTextTemplate } from '@const';
import { HistoryService } from '@shared/history.service';
import { getSegments, Segment } from 'sql-highlight';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TemplatesDialogComponent } from '@app/prompt/templates-dialog/templates-dialog.component';
import { ExecutedQueryDialogComponent } from '@app/prompt/executed-query-dialog/executed-query-dialog.component';
import { isValid } from 'date-fns';
import { TemplateConfigService } from '@shared/template-config.service';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss', '../../theme/sidenav.scss'],
})
export class PromptComponent implements OnInit {
  @ViewChild('drawerRight') public rightSidenav: MatDrawer | undefined;
  @ViewChild('drawerLeft') public leftSidenav: MatDrawer | undefined;
  isLoading: boolean = false;
  result: TextCompletionResponse = this.createEmptyTextCompletionResponse();
  models: ExtendedModelData[] = [];
  textToSqlFormGroup: FormGroup<ModelFormGroup> = this.createModelFormGroup(defaultModel);
  sqlResultToTextFormGroup: FormGroup<ModelFormGroup> = this.createModelFormGroup(defaultModel);
  templatesDialogRef?: MatDialogRef<TemplatesDialogComponent>;
  promptForm: FormGroup<PromptForm> = new FormGroup<PromptForm>({
    prompt: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    textToSqlTemplate: new FormControl<string>(this.getDefaultTemplate(), { nonNullable: true }),
    sqlResultToTextTemplate: new FormControl<string>(sqlResultToTextTemplate, { nonNullable: true }),
    textToSqlModel: this.textToSqlFormGroup,
    sqlResultToTextModel: this.sqlResultToTextFormGroup,
    executeQuery: new FormControl<boolean>(false, { nonNullable: true }),
  });
  isSmallScreen?: boolean;
  executedQuerySqlSegments: Segment[] = [];
  selectedHistoryItem?: HistoryItem;
  isQueryResultGenerating: boolean = false;
  templates: TemplateConfig[] = [];

  constructor(
    private aiService: AiService,
    private breakpointService: BreakpointService,
    private historyService: HistoryService,
    private templateConfigService: TemplateConfigService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.breakpointService.breakpoint$.subscribe((breakpoint) => this.handleBreakpointChange(breakpoint));
    this.aiService.getOpenAiModels({}).subscribe((models) => {
      this.models = models;
    });
    this.historyService.getPage().subscribe();
    this.templateConfigService.getPage().subscribe();
  }

  getAnswer() {
    this.validateForm();
    if (!this.promptForm.invalid) {
      const textCompletion: TextCompletionRequest = {
        prompt: this.promptForm.value.prompt || '',
        textToSqlModel: this.promptForm.value.textToSqlModel || defaultModel,
        sqlResultToTextModel: this.promptForm.value.sqlResultToTextModel || defaultModel,
        textToSqlTemplate: this.promptForm.value.textToSqlTemplate || '',
        sqlResultToTextTemplate: this.promptForm.value.sqlResultToTextTemplate,
        executeQuery: !this.promptForm.controls.executeQuery.value,
      };

      this.getTextCompletion(textCompletion)
        .pipe(
          switchMap((result: TextCompletionResponse) => {
            this.result = result;
            this.executedQuerySqlSegments = getSegments(this.result.executed_query);
            return this.historyService.getPage();
          }),
          finalize(() => {
            const itemToUpdateIndex = this.historyService.$items.value.findIndex((item) => item.SK === this.result.SK);
            if (itemToUpdateIndex > -1) {
              this.handleHistoryItemSelect(this.historyService.$items.value[itemToUpdateIndex]);
            }
          })
        )
        .subscribe(() => {
          this.handleExecuteQueryCheckBoxDisabledState();
        });
    }
  }

  toggleRightSidenav() {
    this.rightSidenav?.toggle();
  }

  toggleLeftSidenav() {
    this.leftSidenav?.toggle();
  }

  handleHistoryItemSelect(historyItem: HistoryItem) {
    this.selectedHistoryItem = historyItem;
    this.setRequestValue(historyItem);
    this.aiService.textCompletionSubject.next(historyItem.response.query_result);
    this.result = {
      ...historyItem.response,
      PK: historyItem.PK,
      SK: historyItem.SK,
      dateTime: historyItem.dateTime,
      textToSqlStatus: historyItem.textToSqlStatus,
      sqlToTextStatus: historyItem.sqlToTextStatus,
      answer: historyItem.response.answer.replace(/\\n/g, '\n'),
    };
    this.handleExecuteQueryCheckBoxDisabledState();
    this.executedQuerySqlSegments = getSegments(historyItem.response.executed_query);
  }

  private handleExecuteQueryCheckBoxDisabledState() {
    if (isValid(new Date(this.result.dateTime))) {
      this.promptForm.controls.executeQuery.disable();
    }
  }

  private setRequestValue(historyItem: HistoryItem) {
    this.promptForm.patchValue(historyItem.request, { emitEvent: false });
    if (historyItem.request.executeQuery === undefined) {
      this.promptForm.controls.executeQuery.patchValue(false);
    } else {
      const checkBoxValue: boolean = !historyItem.request.executeQuery;
      this.promptForm.controls.executeQuery.patchValue(checkBoxValue);
    }
  }

  createNewPrompt(): void {
    this.selectedHistoryItem = undefined;
    this.promptForm.setValue({
      prompt: '',
      textToSqlTemplate: this.getDefaultTemplate(),
      sqlResultToTextTemplate: sqlResultToTextTemplate,
      textToSqlModel: defaultModel,
      sqlResultToTextModel: defaultModel,
      executeQuery: false,
    });
    this.promptForm.controls.executeQuery.enable();
    this.result = this.createEmptyTextCompletionResponse();
    this.executedQuerySqlSegments = [];
  }

  private getDefaultTemplate(): string {
    const defaultTemplate = this.templateConfigService.$items.value.find((template) => template.isDefault);
    if (defaultTemplate) {
      return defaultTemplate.template;
    } else if (this.templateConfigService.$items.value[0]) {
      return this.templateConfigService.$items.value[0].template;
    } else {
      return '{question}';
    }
  }

  private validateForm(): void {
    if (this.promptForm.invalid) {
      for (const control of Object.keys(this.promptForm.controls)) {
        this.promptForm.controls[control].markAsTouched();
      }
      return;
    }
  }

  private createModelFormGroup(defaultModel: Model): FormGroup<ModelFormGroup> {
    return new FormGroup<ModelFormGroup>({
      max_tokens: new FormControl<number>(defaultModel.max_tokens, { nonNullable: true }),
      model: new FormControl<Partial<ExtendedModelData>>(defaultModel.model, { nonNullable: true }),
      n: new FormControl<number>(defaultModel.n, { nonNullable: true }),
      temperature: new FormControl<number>(defaultModel.temperature, { nonNullable: true }),
      top_p: new FormControl<number>(defaultModel.top_p, { nonNullable: true }),
    });
  }

  private getTextCompletion(req: TextCompletionRequest): Observable<TextCompletionResponse> {
    this.isLoading = true;
    return this.aiService.getTextCompletion(req).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    );
  }

  private createEmptyTextCompletionResponse(): TextCompletionResponse {
    return {
      PK: 'PROJECTHISTORY#',
      SK: 'HISTORY#',
      answer: '',
      query_result: [],
      executed_query: '',
      error: '',
      dateTime: '',
      textToSqlStatus: ReviewStatus.PENDING,
      sqlToTextStatus: ReviewStatus.PENDING,
    };
  }

  private handleBreakpointChange(breakpoint: Breakpoint) {
    this.isSmallScreen = breakpoint.Small || breakpoint.XSmall;
    if (this.isSmallScreen) {
      this.rightSidenav?.close();
      this.leftSidenav?.close();
    }
  }

  openTextToSQLDialog() {
    this.templatesDialogRef = this.dialog.open(TemplatesDialogComponent, {
      disableClose: true,
      width: this.isSmallScreen ? '90vw' : '60vw',
      data: {
        title: 'Text to SQL template',
        template: this.promptForm.controls.textToSqlTemplate.value,
      },
    });

    this.templatesDialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        this.promptForm.controls.textToSqlTemplate.patchValue(result);
      }
    });
  }

  openExecutedQueryDialog() {
    this.dialog.open(ExecutedQueryDialogComponent, {
      disableClose: true,
      width: this.isSmallScreen ? '90vw' : '60vw',
      data: {
        result: this.result,
        segments: this.executedQuerySqlSegments,
      },
    });
  }

  executeQuery() {
    this.isQueryResultGenerating = true;
    if (this.selectedHistoryItem) {
      this.aiService
        .executeQuery({
          query: this.result.executed_query,
          request: {
            PK: this.selectedHistoryItem.PK,
            SK: this.selectedHistoryItem.SK,
            ...this.selectedHistoryItem?.request,
          },
        })
        .pipe(
          finalize(() => {
            this.isQueryResultGenerating = false;
          })
        )
        .subscribe((result) => {
          this.result = result;
          this.promptForm.controls.executeQuery.patchValue(false);
          const itemToUpdateIndex = this.historyService.$items.value.findIndex((item) => item.SK === this.result.SK);
          if (itemToUpdateIndex > -1) {
            this.selectedHistoryItem = this.historyService.$items.value[itemToUpdateIndex];
            this.historyService.$items.value[itemToUpdateIndex].response.answer = this.result.answer;
            this.historyService.$items.value[itemToUpdateIndex].response.query_result = this.result.query_result;
            this.historyService.$items.value[itemToUpdateIndex].request.executeQuery = true;
            this.historyService.$items.next(this.historyService.$items.value);
          }
        });
    }
  }
}
