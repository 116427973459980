import { Component, Inject } from '@angular/core';
import { DeleteProjectForm, DeleteProjectResponse, Project } from '@types';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectService } from '@shared/project.service';
import { finalize } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-project-dialog',
  templateUrl: './delete-project-dialog.component.html',
  styleUrls: ['./delete-project-dialog.component.scss'],
})
export class DeleteProjectDialogComponent {
  form: FormGroup<DeleteProjectForm>;
  isLoading: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<DeleteProjectDialogComponent, DeleteProjectResponse | null>,
    private snackBar: MatSnackBar,
    private projectService: ProjectService,
    @Inject(MAT_DIALOG_DATA) public data: Project
  ) {
    this.form = new FormGroup<DeleteProjectForm>({
      projectName: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, Validators.pattern(new RegExp('^' + data.name + '$'))],
      }),
    });
  }

  submitForm() {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
    this.projectService
      .deleteProject(this.form.controls.projectName.value)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response) => this.handleDeleteProjectResponse(response));
  }

  closeDialog() {
    this.dialogRef.close(null);
  }

  private handleDeleteProjectResponse(response: DeleteProjectResponse) {
    this.snackBar.open('The project has been successfully deleted.', 'Close', { duration: 3000 });
    this.dialogRef.close(response);
  }
}
