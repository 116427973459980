<div class="container" [ngClass]="{ 'small-screen': isSmallScreen }">
  <div class="card project-page-actions">
    <button color="primary" mat-raised-button aria-label="create project button" (click)="openCreateProjectDialog()">
      <mat-icon>add</mat-icon>
      New Project
    </button>
  </div>
  <mat-card class="card" [ngClass]="{ 'small-screen': isSmallScreen }">
    <mat-card-header class="card-header">
      <mat-card-title>Project selector</mat-card-title>
    </mat-card-header>
    <mat-card-content class="project-selector">
      <app-project-selector (selectProjectEvent)="handleProjectSelectEvent($event)" />
    </mat-card-content>
  </mat-card>
  <div *ngIf="this.projectService.selectedProject.value?.name">
    <h2>Project settings</h2>
    <mat-card class="card" [ngClass]="{ 'small-screen': isSmallScreen }">
      <mat-card-header class="card-header">
        <mat-card-title>{{ this.projectService.selectedProject.value?.name }}</mat-card-title>
        <div>
          <button
            color="primary"
            mat-raised-button
            aria-label="edit project button"
            [disabled]="!this.projectService.selectedProject.value?.name"
            (click)="editProject()"
          >
            Edit Project
            <mat-icon *ngIf="isProjectLoading" class="spin" iconPositionEnd>sync</mat-icon>
          </button>
          <button
            color="warn"
            mat-raised-button
            aria-label="delete project button"
            [disabled]="!this.projectService.selectedProject.value?.name"
            (click)="deleteProject()"
          >
            Delete Project
          </button>
        </div>
      </mat-card-header>
      <mat-card-content>
        <app-project-info [project]="projectConfiguration"></app-project-info>
      </mat-card-content>
    </mat-card>
    <mat-card class="card" [ngClass]="{ 'small-screen': isSmallScreen }">
      <mat-card-header class="card-header">
        <mat-card-title>Templates</mat-card-title>
        <div>
          <button
            color="primary"
            mat-raised-button
            aria-label="create template button"
            [disabled]="!this.projectService.selectedProject.value?.name"
            (click)="openCreateTemplateDialog()"
          >
            New template
            <mat-icon *ngIf="isProjectLoading" class="spin" iconPositionEnd>sync</mat-icon>
          </button>
        </div>
      </mat-card-header>
      <mat-card-content>
        <app-template-config
          [templates]="templates"
          (deleteTemplateEvent)="openDeleteTemplateDialog($event)"
          (updateTemplateEvent)="openUpdateTemplateDialog($event)"
        >
        </app-template-config>
      </mat-card-content>
    </mat-card>
    <mat-card class="card" [ngClass]="{ 'small-screen': isSmallScreen }">
      <mat-card-header class="card-header">
        <mat-card-title>Client API configuration</mat-card-title>
        <div>
          <button
            color="primary"
            mat-raised-button
            aria-label="edit project button"
            [disabled]="!this.projectService.selectedProject.value?.name"
            (click)="openCreateClientDialog()"
          >
            New API Key
            <mat-icon *ngIf="isProjectLoading" class="spin" iconPositionEnd>sync</mat-icon>
          </button>
          <button
            mat-raised-button
            aria-label="edit project button"
            [disabled]="!this.projectService.selectedProject.value?.name"
            (click)="openApiUsageGuideDialog()"
          >
            API Usage Guide
            <mat-icon *ngIf="isProjectLoading" class="spin" iconPositionEnd>sync</mat-icon>
          </button>
        </div>
      </mat-card-header>
      <mat-card-content>
        <app-client-config
          [clients]="clientsWithTemplates"
          (deleteApiConfigEvent)="openDeleteClientDialog($event)"
          (updateApiConfigEvent)="openUpdateClientDialog($event)"
        >
        </app-client-config>
      </mat-card-content>
    </mat-card>
  </div>
</div>
