<div class="table">
  <table matSort mat-table [dataSource]="rows.data" class="mat-elevation-z8" (matSortChange)="sortData($event)">
    <ng-container [matColumnDef]="column" *ngFor="let column of columns">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>{{ column }}</th>
      <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
  </table>
</div>
