import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplateConfig, TemplatesDialogData } from '@types';
import { TemplateConfigService } from '@shared/template-config.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

type PromptTemplateForm = {
  template: FormControl<string>;
};

@Component({
  selector: 'app-templates-dialog',
  templateUrl: './templates-dialog.component.html',
  styleUrls: ['./templates-dialog.component.scss'],
})
export class TemplatesDialogComponent implements OnInit {
  data: TemplatesDialogData;
  templateSelector: FormControl<TemplateConfig | null>;
  form: FormGroup<PromptTemplateForm>;

  constructor(
    private dialogRef: MatDialogRef<TemplatesDialogComponent>,
    private snackBar: MatSnackBar,
    protected templateConfigService: TemplateConfigService,
    @Inject(MAT_DIALOG_DATA) data: TemplatesDialogData
  ) {
    this.data = data;
    this.form = new FormGroup<PromptTemplateForm>({
      template: new FormControl(data.template, { nonNullable: true, validators: [Validators.required] }),
    });
    this.templateSelector = new FormControl<TemplateConfig | null>(this.getDefaultSelectedTemplate());
  }

  ngOnInit() {
    this.templateConfigService.getPage().subscribe();
    this.templateSelector.valueChanges.subscribe((selectedTemplateConfig) => {
      if (selectedTemplateConfig) {
        this.form.setValue({ template: selectedTemplateConfig?.template });
      } else {
        this.form.setValue({ template: this.data.template });
      }
    });
  }

  getDefaultSelectedTemplate(): TemplateConfig | null {
    const currentlySelectedTemplate = this.templateConfigService.$items.value.find(
      (templateConfig) => templateConfig.template === this.data.template
    );
    if (currentlySelectedTemplate) {
      return currentlySelectedTemplate;
    } else {
      return null;
    }
  }

  cancel() {
    this.dialogRef.close(this.data.template);
  }

  submitForm() {
    if (this.form.valid) {
      this.snackBar.open('The template has been updated.', 'Close', { duration: 3000 });
      this.dialogRef.close(this.form.controls.template.value);
    }
  }

  compareFn(c1: TemplateConfig, c2: TemplateConfig): boolean {
    return c1 && c2 ? c1.SK === c2.SK : c1 === c2;
  }
}
