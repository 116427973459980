<div>
  <div [@.disabled]="disableAnimation" *ngIf="data?.type !== HistoryItemType.MANUAL">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Model Parameters</mat-panel-title>
      </mat-expansion-panel-header>
      <app-prompt-execution-info-table
        *ngIf="promptExecutionModel"
        [modelExecutionDate]="data?.dateTime"
        [model]="promptExecutionModel"
      />
    </mat-expansion-panel>
  </div>
  <div>
    <h2>Request</h2>
    <textarea
      #autosize="cdkTextareaAutosize"
      class="request-textarea custom-textarea"
      [cdkAutosizeMinRows]="20"
      matInput
      cdkTextareaAutosize
      disabled
      [value]="request"
    ></textarea>
  </div>
  <div>
    <div class="card-title">
      <h2>Response</h2>
      <button
        *ngIf="!isUpdateExecutedQuery"
        mat-icon-button
        (click)="switchToUpdateExecutedQuery()"
        [disabled]="data?.textToSqlStatus !== ReviewStatus.PENDING"
      >
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    <div>
      <ng-container *ngIf="isUpdateExecutedQuery; then UpdateExecutedQuery; else executedQuery"></ng-container>
      <ng-template #UpdateExecutedQuery>
        <form [formGroup]="updateExecutedQueryForm" (ngSubmit)="saveUpdatedExecutedQuery()" class="sql-edit-form">
          <textarea
            class="custom-textarea"
            [formControl]="updateExecutedQueryForm.controls.query"
            placeholder="New response..."
          ></textarea>
          <mat-error *ngIf="updateExecutedQueryForm.controls.query.hasError('required')" class="error-message">
            Required!
          </mat-error>
          <div class="sql-edit-form-action">
            <button mat-flat-button type="button" (click)="cancelUpdatingExecutedQuery()">Cancel</button>
            <button mat-raised-button color="primary" type="submit" [disabled]="isUpdateExecutedQueryInProgress">
              Save
              <mat-icon *ngIf="isUpdateExecutedQueryInProgress" class="spin" iconPositionEnd>sync</mat-icon>
            </button>
          </div>
        </form>
      </ng-template>
      <ng-template #executedQuery>
        <div class="query-play-wrapper">
          <app-sql-highlight class="sql-highlight" [segments]="response" />
          <button
            mat-icon-button
            color="accent"
            (click)="executeQuery()"
            [ngClass]="{ spin: isQueryResultGenerating }"
            [disabled]="isQueryResultGenerating"
          >
            <mat-icon>{{ isQueryResultGenerating ? 'sync' : 'play_arrow' }}</mat-icon>
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</div>
