import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared';
import { MaterialModule } from '@app/material.module';
import { PromptRoutingModule } from './prompt-routing.module';
import { PromptComponent } from './prompt.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModelSelectorComponent } from './model-selector/model-selector.component';
import { HistoryItemComponent } from './history-sidebar/history-item/history-item.component';
import { HistoryListComponent } from '@app/prompt/history-sidebar/history-list.component';
import { TemplatesDialogComponent } from './templates-dialog/templates-dialog.component';
import { ExecutedQueryDialogComponent } from './executed-query-dialog/executed-query-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    MaterialModule,
    PromptRoutingModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: [
    PromptComponent,
    ModelSelectorComponent,
    HistoryListComponent,
    HistoryItemComponent,
    TemplatesDialogComponent,
    ExecutedQueryDialogComponent,
  ],
})
export class PromptModule {}
