<ng-container [formGroup]="promptForm">
  <mat-drawer-container class="example-container" autosize [hasBackdrop]="isSmallScreen">
    <mat-drawer
      [opened]="!isSmallScreen"
      #drawerLeft
      [ngClass]="{ sidenav: true, smallScreen: isSmallScreen, 'history-sidenav': true }"
      [mode]="isSmallScreen ? 'over' : 'side'"
    >
      <div class="left-sidenav dark-theme">
        <div class="left-sidenav-title-container mat-typography">
          <button class="left-sidenav-title" mat-button type="button" (click)="createNewPrompt()">
            <mat-icon class="icon">add</mat-icon>
            New prompt
          </button>
        </div>
        <mat-divider class="divider"></mat-divider>
        <app-history-list
          (selectEvent)="handleHistoryItemSelect($event)"
          [selectedItem]="selectedHistoryItem"
          [isSmallScreen]="isSmallScreen"
        />
      </div>
    </mat-drawer>
    <mat-drawer
      #drawerRight
      [ngClass]="{ sidenav: true, smallScreen: isSmallScreen, 'settings-sidenav': true }"
      [mode]="isSmallScreen ? 'over' : 'side'"
      position="end"
    >
      <div class="right-sidenav-title-container mat-typography">
        <button mat-icon-button (click)="toggleRightSidenav()" type="button" color="accent">
          <mat-icon class="material-symbols-outlined" fontSet="material-icons-outlined"> arrow_circle_right </mat-icon>
        </button>
        <h3 class="right-sidenav-title">Model settings</h3>
      </div>
      <app-model-selector [formGroup]="textToSqlFormGroup" [models]="models" name="textToSqlModel">
      </app-model-selector>
      <app-model-selector [formGroup]="sqlResultToTextFormGroup" [models]="models" name="sqlResultToTextModel">
      </app-model-selector>
    </mat-drawer>
    <div class="container" [ngClass]="{ 'small-container': isSmallScreen }">
      <div *ngIf="!rightSidenav?.opened" class="right-side-toggle-btn">
        <mat-icon fontSet="material-icons-outlined" class="icon" (click)="toggleRightSidenav()">settings</mat-icon>
      </div>
      <div *ngIf="isSmallScreen" class="left-side-toggle-btn">
        <mat-icon class="icon" (click)="toggleLeftSidenav()">list</mat-icon>
      </div>
      <div class="custom-prompt-wrapper">
        <div class="prompt">
          <textarea
            class="custom-prompt-area"
            label="Prompt"
            formControlName="prompt"
            placeholder="Your prompt text..."
            cdkTextareaAutosize
          >
          </textarea>
          <button
            mat-icon-button
            (click)="getAnswer()"
            type="button"
            [disabled]="promptForm.controls.prompt.hasError('required') || isLoading"
            color="primary"
            class="prompt-send-button"
          >
            <mat-icon
              fontSet="material-icons-outlined"
              class="material-symbols-outlined send-icon"
              [ngClass]="{ spin: isLoading }"
            >
              {{ isLoading ? 'sync' : 'send' }}
            </mat-icon>
          </button>
        </div>
        <div class="dry-run">
          <mat-checkbox [formControl]="promptForm.controls.executeQuery" color="primary"
            >Don't execute query</mat-checkbox
          >
        </div>
        <mat-icon class="bubble-icon rotate-y">chat_bubble_outline</mat-icon>
      </div>
      <div *ngIf="result.answer" class="custom-answer-wrapper">
        <p class="answer-title">Answer</p>
        <p>{{ result.answer }}</p>
        <mat-icon class="bubble-icon" color="primary">chat_bubble_outline</mat-icon>
      </div>
      <div *ngIf="result.executed_query && promptForm.controls.executeQuery.value" class="custom-answer-wrapper">
        <p class="answer-title">Generated query</p>
        <div class="query-play-wrapper">
          <app-sql-highlight [segments]="executedQuerySqlSegments"></app-sql-highlight>
          <button
            mat-icon-button
            color="accent"
            (click)="executeQuery()"
            [ngClass]="{ spin: isQueryResultGenerating }"
            [disabled]="result.answer.length !== 0 || isQueryResultGenerating"
          >
            <mat-icon>{{ isQueryResultGenerating ? 'sync' : 'play_arrow' }}</mat-icon>
          </button>
        </div>
        <mat-icon class="bubble-icon" color="primary">code</mat-icon>
      </div>
      <div class="templates-and-executed-query">
        <button class="templates-and-executed-query-buttons" type="button" (click)="openTextToSQLDialog()">
          Text to SQL template
          <mat-icon class="info-icon">info_outline</mat-icon>
        </button>
        <button
          *ngIf="!promptForm.controls.executeQuery.value"
          class="templates-and-executed-query-buttons"
          type="button"
          (click)="openExecutedQueryDialog()"
        >
          Executed query
          <mat-icon class="info-icon">info_outline</mat-icon>
        </button>
      </div>
    </div>
  </mat-drawer-container>
</ng-container>
