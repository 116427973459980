<mat-drawer-container autosize [hasBackdrop]="isSmallScreen">
  <mat-drawer
    #drawerRight
    [ngClass]="{ sidenav: true, smallScreen: isSmallScreen, 'fine-tune-item-sidenav': true }"
    [mode]="'over'"
    position="end"
  >
    <div class="right-sidenav-title-container">
      <button mat-icon-button (click)="closeRightSidenav()" type="button" class="right-side-toggle-btn" color="accent">
        <mat-icon class="material-symbols-outlined" fontSet="material-icons-outlined"> arrow_circle_right</mat-icon>
      </button>
      <h3 class="right-sidenav-title">Fine-Tune Model Information</h3>
    </div>
    <div class="right-sidenav-loading" *ngIf="!selectedRow">
      <h3>Loading...</h3>
    </div>
    <div class="right-sidenav-body-container">
      <div *ngIf="selectedRow">
        <app-json-viewer [data]="selectedRow"></app-json-viewer>
      </div>
    </div>
  </mat-drawer>
  <div class="container" [ngClass]="{ 'small-container': isSmallScreen }">
    <app-fine-tune-list (rowSelectEvent)="handleTableRowSelect($event)" [selectedRowId]="selectedRowId" />
  </div>
</mat-drawer-container>
