import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HistoryItem, HistoryItemType } from '@types';
import { HistoryService } from '@shared/history.service';

@Component({
  selector: 'app-history-list',
  templateUrl: './history-list.component.html',
  styleUrls: ['./history-list.component.scss'],
})
export class HistoryListComponent {
  @Output() selectEvent = new EventEmitter<HistoryItem>();
  @Input() selectedItem: HistoryItem | undefined;
  @Input() isSmallScreen: boolean | undefined;

  constructor(public historyService: HistoryService) {}

  identify(index: number, item: HistoryItem) {
    return item.dateTime;
  }

  getNextPage() {
    this.historyService.loadNextPage();
  }

  protected readonly HistoryItemType = HistoryItemType;
}
